import React, { useState, useEffect } from 'react';
import Popup from '@atlaskit/popup';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Notifications } from '@atlaskit/atlassian-navigation';
import { B200, N0 } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import Badge from '@atlaskit/badge';
import i18next from 'i18next';
import firebase from '../../utils/firebase';
import NotificationProps from './types';
import NotificationItem from './NotificationItem';
import useAddFlagForEbayBadTokenError from './useAddFlagForEbayBadTokenError';

const env = process.env.REACT_APP_NODE_ENV || 'development';

const SettingsContainer = styled.div`
  min-width: 450px;
  min-heigth: 600px;
  marigin-bottom: 60px;
  padding-bottom:20px;
  font-size:0.825rem;
  font-weight:normal;
  line-height:20px;
`;
const HeaderContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  marigin: 20px 20px;
  border-top: 2px solid rgb(220,220,220);
  z-index: 999;
`;

const TextContainer = styled.div`
  text-align: center;
  font-size: 24px;
  padding: 50px;
`;

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  useAddFlagForEbayBadTokenError(notifications);
  const showNotifications = notifications.slice(0, 10);

  const onClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const teamId = window.localStorage.getItem('currentTeam');
  const ref = firebase.firestore()
    .collection(`notifications_${env}`)
    .where('teamId', '==', teamId)
    .where('status', '==', 'UNREAD')
    .orderBy('updatedAt', 'desc');
  useEffect(() => {
    ref.onSnapshot((querySnapshot) => {
      const items: NotificationProps[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), docId: doc.id });
      });
      setNotifications(items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const NotificationsBadge = () => {
    const result = notifications.length
      ? (
        <Badge style={{ backgroundColor: B200, color: N0 }} max={10}>
          {notifications.length}
        </Badge>
      )
      // (
      //   <Container backgroundColor={B200} textColor={N0}>
      //     <Format max={10}>{notifications.length}</Format>
      //   </Container>
      // )
      : null;
    return result;
  };

  const markAllAsRead = async () => {
    const querySnapshot = await ref.get();
    querySnapshot.forEach(async (doc) => {
      await doc.ref.update({
        status: 'READ',
      });
    });
  };

  const NotificationsContent = () => (
    <SettingsContainer>
      <HeaderContainer>
        <h3>{i18next.t('nav.nc') as string}</h3>
        <Button appearance="link" onClick={() => markAllAsRead()}>
          {i18next.t('nav.maar') as string}
        </Button>
      </HeaderContainer>
      {
        showNotifications.length
          ? (
            <ContentContainer>
              <DropdownItemGroup>
                {showNotifications.map((item: NotificationProps) => (
                  <DropdownItem key={item.id}>
                    <NotificationItem data={item} />
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </ContentContainer>
          )
          : <TextContainer>{i18next.t('nav.nnrn') as string}</TextContainer>
      }
    </SettingsContainer>
  );

  return (
    <Popup
      placement="bottom-end"
      content={NotificationsContent}
      isOpen={isOpen}
      onClose={onClose}
      testId="settings-popup"
      trigger={(triggerProps) => (
        <Notifications
          badge={NotificationsBadge}
          testId="settings-button"
          isSelected={isOpen}
          onClick={onClick}
          tooltip="Product and Order Message"
          {...triggerProps}
        />
      )}
    />
  );
};

import React, { SyntheticEvent, useState } from 'react';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import Pagination from '@atlaskit/pagination';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Link, useHistory } from 'react-router-dom';
import _, { min, get } from 'lodash';
import gitFixedSizeString from './utils';

import {
  Ticket,
  ColumnProps,
  TicketQueryInputProps,
} from './types';
import {
  TicketTableContainer,
  TicketPagination,
  MoreSpan,
} from './tickets.styles';
import {
  TicketsSummaryContainer,
  TicketsStatisticsContainer,
  TicketsSearchBoxContainer,
  StatusContainer,
} from './TicketsContainer.styles';
import DropdownMenuColumns from './DropdownMenuColumns';
import formattedDate from '../../utils/formattedDate';
import Wrapper from '../ListView/Wrapper';
import { useTicketsByTeamQuery } from '../../graphql/types';
import GlobalSearch from './GlobalSearch';
import { useTicket } from './TicketsContainer';
import StatusFilter from './StatusFilter';
import TicketsStatistics from './TicketsStatistics';
import DEFAULT_STAT from './config';

const PAGE_SIZE = 20;

const TicketsListing = (props: { teamID: string }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState('created_at');
  const [inputSortOrder, setInputSortOrder] = useState('DESC');
  const history = useHistory();
  const { teamID } = props;
  const { state, dispatch } = useTicket();
  const savedColumns = window.localStorage.getItem('ticketColumns') || '{}';
  let columnsVal = JSON.parse(savedColumns);
  if (_.isEmpty(columnsVal)) {
    columnsVal = [
      {
        key: 'img', label: '', isSortable: false, isChecked: true, width: 1,
      },
      {
        key: 'id', label: 'ID', isSortable: false, isChecked: true, width: 1,
      },
      {
        key: 'itemId', label: 'SKU', isSortable: false, isChecked: true, width: 1,
      },
      {
        key: 'orderId', label: 'Order No', isSortable: false, isChecked: false, width: 1,
      },
      {
        key: 'status', label: 'Status', isSortable: true, isChecked: true, width: 1,
      },
      {
        key: 'subject', label: 'Subject', isSortable: false, isChecked: true, width: 3,
      },
      {
        key: 'requester', label: 'Requester', isSortable: false, isChecked: true, width: 1,
      },
      {
        key: 'created_at', label: 'Created', isSortable: true, isChecked: true, width: 1,
      },
      {
        key: 'updated_at', label: 'Updated', isSortable: true, isChecked: true, width: 1,
      },
      {
        key: 'priority', label: 'Priority', isSortable: true, isChecked: true, width: 1,
      },
      {
        key: 'channel', label: 'Channel', isSortable: false, isChecked: true, width: 1,
      },
    ];
  }
  const [columns, setColumns] = useState<ColumnProps[]>(columnsVal);
  const variables: TicketQueryInputProps = {
    teamID,
    status: state.status,
    searchTxt: state.searchKey,
    sortKey,
    sortOrder: inputSortOrder,
    pageNum: currentPage,
    pageSize: PAGE_SIZE,
  };
  const {
    data, error, loading, refetch,
  } = useTicketsByTeamQuery({ variables });
  if (error) {
    const errorMsg = get(error, 'graphQLErrors[0].message', 'Something went wrong!');
    if (errorMsg === 'missing zendesk configuration.') {
      history.push(`/connect/zendesk/${teamID}`);
    }
    return <p>{errorMsg}</p>;
  }
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    refetch();
  };
  const handleSortArgChange = (val: { key: string, sortOrder: string }) => {
    const { key, sortOrder } = val;
    setSortKey(key);
    setInputSortOrder(sortOrder);
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const tickets: any[] = data?.TicketsByTeam?.tickets
    || [];
  const total = data?.TicketsByTeam?.total || 0;
  const pages = Math.ceil(total / PAGE_SIZE);
  const totalPages = [];
  for (let i = 1; i <= pages; i += 1) {
    totalPages.push(i);
  }
  const onPageChange = (event: SyntheticEvent, newPage: number) => {
    handlePageChange(newPage);
  };
  const sortChange = (sortData: { key: string, sortOrder: 'ASC' | 'DESC' }) => {
    handleSortArgChange(sortData);
  };
  const selectedColumnsChange = (colData: ColumnProps) => {
    const updatedColumns = columns.map((ele: ColumnProps) => (
      ele.key === colData.key ? colData : ele
    ));
    setColumns(updatedColumns);
    window.localStorage.setItem('ticketColumns', JSON.stringify(updatedColumns));
  };
  const headCells = columns.filter((col: ColumnProps) => (
    col.isChecked
  )).map((col: ColumnProps) => ({
    key: col.key,
    content: col.label,
    isSortable: col.isSortable,
    width: col.width,
  }));
  headCells.push({
    key: 'action',
    content: 'Action',
    isSortable: false,
    width: 1,
  });
  const head = { cells: headCells };
  const rows = tickets.map(
    (tc: Ticket, idx) => {
      const {
        image,
        id,
        status,
        subject,
        created_at: createdAt,
        updated_at: updatedAt,
      } = tc;
      const statusItem = DEFAULT_STAT.find((stat) => stat.value === status);
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const cells: any = columns.filter((col: ColumnProps) => (
        col.isChecked
      )).map((col: ColumnProps) => {
        if (col.key === 'img') {
          return image !== '-'
            ? {
              key: 'img',
              content: <img src={image} alt="sku img" height="36px" width="36px" />,
            }
            : { key: 'img', content: '-' };
        }
        if (col.key === 'status') {
          return {
            key: 'status',
            content: <StatusContainer color={statusItem?.color}>{statusItem?.abbr}</StatusContainer>,
          };
        }
        if (col.key === 'subject') {
          return {
            key: 'subject',
            content: gitFixedSizeString(subject, 80),
          };
        }
        if (col.key === 'created_at') {
          return {
            key: 'updated_at',
            content: formattedDate(createdAt),
          };
        }
        if (col.key === 'updated_at') {
          return {
            key: 'updated_at',
            content: formattedDate(updatedAt),
          };
        }
        return {
          key: col.key,
          content: tc[col.key],
        };
      });
      cells.push({
        content: (
          <DropdownMenu
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, no-shadow
            trigger={({ triggerRef, ...props }) => (
              <MoreSpan>
                <MoreIcon label="more" />
              </MoreSpan>
            )}
            shouldRenderToParent
          >
            <DropdownItemGroup>
              <DropdownItem>
                <Link to={`/tickets/${id}?`}>View Detail</Link>
              </DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        ),
      });
      return {
        key: `row-${idx}-ticket`,
        cells,
        onClick: () => dispatch({ type: 'setTicket', payload: tc }),
      };
    },
  );
  const cursorFrom = ((currentPage - 1) * PAGE_SIZE) + 1;
  const cursorTo = min([currentPage * PAGE_SIZE, total]) || PAGE_SIZE;
  const handleRefetch = async () => {
    await refetch();
  };
  return (
    <Wrapper>
      <div className="heading">
        <h2>Tickets</h2>
      </div>
      <TicketsSummaryContainer>
        <TicketsStatisticsContainer>
          <StatusFilter />
          <TicketsStatistics
            cursorFrom={cursorFrom}
            cursorTo={cursorTo}
            ticketsTotal={total}
            handleRefetch={handleRefetch}
          />
        </TicketsStatisticsContainer>
        <TicketsSearchBoxContainer>
          <div className="search-filter-wrapper">
            <>
              <div className="filters" />
              <div className="search-box">
                <GlobalSearch />
              </div>
            </>
          </div>
          <div className="table-controls">
            <div className="pagination" />
            <div className="ticket-column-options">
              <DropdownMenuColumns
                columns={columns}
                selectedColumnsChange={selectedColumnsChange}
              />
            </div>
          </div>
        </TicketsSearchBoxContainer>
      </TicketsSummaryContainer>
      <TicketTableContainer>
        <DynamicTableStateless
          head={head}
          rows={rows}
          loadingSpinnerSize="large"
          isLoading={loading}
          defaultPage={1}
          defaultSortKey="created_at"
          defaultSortOrder="DESC"
          sortKey={sortKey}
          onSort={sortChange}
        />
      </TicketTableContainer>
      <TicketPagination>
        {rows.length > 0
          && (
            <Pagination
              pages={totalPages}
              onChange={onPageChange}
              selectedIndex={currentPage}
            />
          )}
      </TicketPagination>
    </Wrapper>
  );
};

export default TicketsListing;

import React, { useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { isUndefined } from 'lodash';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';
import Toggle from '@atlaskit/toggle';
import styled from 'styled-components';

import Button from '@atlaskit/button/new';
import TextField from '@atlaskit/textfield';
import { useGetTeamCourierQuery, useSetTeamCourierMutation } from '../../graphql/types';
import aramex from '../../assets/png/aramex.png';
import Instructions from './Instructions';
import ErrorSectionMessage from './ErrorMessage';
import SucessMessage from './SucessMessage';

const FastwayFormContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width:100%;
  padding-top:20px;
  img {
    max-width:195px;
    display: block;
    height: auto;
    margin:auto;
    margin-bottom:10px;
  }  
  ol{
    margin:0;
    padding:0;
  }
`;

interface Props {
  teamId:string,
  courierId:string,
}

interface FormData {
  clientId:string,
  clientSecret:string,
  enabled:boolean,
}

const FastwayIntergration = ({ teamId, courierId }: Props) => {
  const { data, loading } = useGetTeamCourierQuery({
    variables: {
      teamId,
      courierId,
    },
  });

  const [isDirty, setIsDirty] = useState(false);

  const teamCourier = data?.GetTeamCourier.teamCourier;

  const {
    clientId = '',
    clientSecret = '',
  } = JSON.parse((teamCourier?.configuration || '{}'));

  const [
    setTeamCourierMutation,
    {
      error: mutationError,
      loading: mutaionLoading,
      data: mutationData,
    },
  ] = useSetTeamCourierMutation();

  const validate = (
    value:string|undefined,
  ) => {
    if (!value) {
      return 'This is a required field.';
    }
    return undefined;
  };

  const handleSubmit = async (
    formData:FormData,
  ) => {
    const errors = {
      clientId: formData.clientId ? undefined : 'Client ID is required',
      clientSecret: formData.clientSecret ? undefined : 'Client Secret is required',
    };
    const hasError = Object.values(errors).some((e) => !isUndefined(e));
    if (hasError) return errors;

    setIsDirty(true);
    await setTeamCourierMutation({
      variables: {
        teamId,
        courierId,
        active: formData.enabled ?? false,
        configuration: JSON.stringify({
          clientId: formData.clientId,
          clientSecret: formData.clientSecret,
        }),
      },
    });

    return undefined;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <FastwayFormContainer>
      <div style={{ width: 350 }}>
        <Form<FormData>
          onSubmit={handleSubmit}
          isDisabled={mutaionLoading}
        >
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <img src={aramex} alt="fastway" />
              {(!data?.GetTeamCourier.teamCourier && !isDirty)
               && <Instructions />}
              {
              mutationData?.SetTeamCourier.teamCourier.active
              && <SucessMessage />
              }
              {mutationError && <ErrorSectionMessage error={mutationError} />}
              <Field
                name="clientId"
                label="Client ID"
                isRequired
                defaultValue={clientId}
                validate={validate}
                isDisabled={submitting}
              >
                {({ fieldProps, error: err }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      spellCheck={false}
                    />
                    {err && <ErrorMessage>{err}</ErrorMessage>}
                  </>
                )}
              </Field>
              <Field
                name="clientSecret"
                label="Client Secret"
                isRequired
                defaultValue={clientSecret}
                validate={validate}
                isDisabled={submitting}
              >
                {({ fieldProps, error: err }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      spellCheck={false}
                    />
                    {err && <ErrorMessage>{err}</ErrorMessage>}
                  </>
                )}
              </Field>
              <Field
                name="enabled"
                label="Enable/Disable"
                isRequired
                isDisabled={submitting}
              >
                {({ fieldProps }) => (
                  <Toggle
                    {...fieldProps}
                    defaultChecked={teamCourier?.active ?? false}
                    size="large"
                  />
                )}
              </Field>
              <FormFooter>
                <Button type="submit" appearance="primary" isLoading={submitting}>
                  Save & Connect
                </Button>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </FastwayFormContainer>
  );
};

export default FastwayIntergration;

import React from 'react';
import {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import Popup from '@atlaskit/popup';
import MoreIcon from '@atlaskit/icon/glyph/more';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import ActionContainer from '../styles/ActionContainer';
import { useProducts } from '../ProductsListing';
import { Product } from '../types';
import type { TeamTaxClasses } from '../../OrderItemEditor/types';
import DeleteProductButton, { DeleteButtonResponse } from '../../DeleteProductButton';
import addNotification from '../../../utils/addNotification';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import ViewEbayListingBtn from './ViewEbayListingBtn';
import StockInModal from '../../StockInModal';
import { useSubscription } from '../../../utils/useAuth';

const ActionButton = (
  { product, taxClasses }: { product: Product, taxClasses: TeamTaxClasses },
) => {
  const { t } = useTranslation();
  const { dispatch } = useProducts();
  const freeExpired = useSubscription();

  const [
    deleteResponse,
    setDeleteResponse,
  ] = React.useState<DeleteButtonResponse>();

  const { id } = product;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isStockInOpen, setIsStockInOpen] = React.useState(false);

  const onClick = ((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  });

  const onClose = () => {
    setIsOpen(false);
    setIsStockInOpen(false);
  };

  const onStockInOpen = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    setIsStockInOpen(true);
    e.stopPropagation();
  };

  const onStockInClose = () => {
    setIsStockInOpen(false);
    setIsOpen(!isOpen);
  };

  const onClickViewDetails = () => {
    dispatch({
      type: 'setCurrentProductID',
      payload: id,
    });
    setIsOpen(!isOpen);
  };

  const getResponse = (res: DeleteButtonResponse) => {
    setDeleteResponse(res);
  };

  // action notification
  React.useEffect(() => {
    if (deleteResponse) {
      const fetchedData = deleteResponse?.data;
      const error = deleteResponse?.error;
      let msg = 'Product Delete Sucessful';
      if (error) {
        msg = graphQLErrorsReader(error);
      }
      addNotification(
        msg,
        fetchedData ? 'success' : 'danger',
      );
    }
  }, [deleteResponse]);

  // tell the listing table about the delete
  React.useEffect(() => {
    if (deleteResponse?.data?.DeleteProduct.deletedId) {
      dispatch({
        type: 'setLastDeletedId',
        payload: deleteResponse.data.DeleteProduct.deletedId,
      });
    }
  }, [deleteResponse, dispatch]);

  // const onDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
  //   e.stopPropagation();
  //   setIsOpen(!isOpen);
  // };

  const onDelete = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // const onOpenEbayListing = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
  //   e.stopPropagation();
  //   setIsOpen(!isOpen);
  // };

  const onOpenEbayListing = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Filter ebay channel product based on external listing id
  const ebayChannelProducts = product.teamChannelProducts
    .filter((tcP) => tcP.externalListingId.trim() !== '');

  const Content = () => (
    <DropdownItemGroup>
      <ActionContainer>
        <DropdownItem onClick={onClickViewDetails} testId="dropdown-view-details">
          <Button appearance="subtle" spacing="none">
            {t('products.button.vd')}
          </Button>
        </DropdownItem>
        <DropdownItem onClick={freeExpired === false ? onStockInOpen : undefined} testId="dropdown-stock-in">
          <Button appearance="subtle" spacing="none" isDisabled={freeExpired}>
            {t('products.button.si')}
          </Button>
        </DropdownItem>

        <DropdownItem onClick={freeExpired === false ? onDelete : undefined} testId="dropdown-delete">
          <DeleteProductButton
            id={id}
            sku={product.sku}
            getResponse={getResponse}
            isCompactSize
          >
            {t('products.button.de')}
          </DeleteProductButton>
        </DropdownItem>
        {
          ebayChannelProducts
            .filter((tcp) => tcp.teamChannel.channel.name === 'eBay')
            .map((tcP) => (
              <DropdownItem onClick={onOpenEbayListing} key={tcP.id}>
                <ViewEbayListingBtn
                  isCompactSize
                  ebayItemId={tcP.externalListingId}
                  isDisabled={freeExpired}
                >
                  View eBay Listing
                </ViewEbayListingBtn>
              </DropdownItem>
            ))
        }
      </ActionContainer>
    </DropdownItemGroup>
  );

  return (
    <>
      <StockInModal
        product={product}
        taxClasses={taxClasses}
        isOpen={isStockInOpen}
        onClose={onStockInClose}
      />
      <Popup
        placement="bottom-start"
        content={Content}
        isOpen={isOpen}
        onClose={onClose}
        testId="settings-popup"
        trigger={(triggerProps) => (
          <Button
            appearance="subtle"
            onClick={onClick}
            {...triggerProps}
          >
            <MoreIcon label="more" size="small" />
          </Button>
        )}
      />
    </>
  );
};

export default ActionButton;

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Form, {
  Field, FormHeader, FormSection, FormFooter,
} from '@atlaskit/form';
import { useHistory } from 'react-router-dom';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import { Grid, GridColumn } from '@atlaskit/page';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import i18next from 'i18next';
import PlanUpgrade from '../Billing/PlanUpgrade';
import { useSubscription } from '../../utils/useAuth';
import CheckPlan from '../Billing/CheckPlan';

const Card = styled.div`
  border: 0;
  flex-grow: 1;
  box-shadow: 0 1px 1px 0 rgba(60,75,100,.14), 0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2);

  color: #000!important;

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 3inch;
  margin-right: 0.6rem;
  border-radius: .25rem;
  text-align: center;
  background: #ffd700!important;
  background: ${(props) => props.theme.bgGradient}
  border-color: ${(props) => props.theme.bgColor}
`;

const CardBody = styled.div`
  padding: 0.5rem;
`;

const CustomButton = styled(Button)`
  background-color: black;
  height: 2.3rem;
  color: white !important;
  border-radius: 5px;
  color: white;
`;

export interface TeamProfileFormData {
  teamName: string,
  abn: string,
  description: string,
  company: string,
  email: string,
  phone: string,
  fax: string,
  mobile: string,
  street: string,
  suburb: string,
  state: string,
  country: string,
  postcode: string,
  defaultProductTaxClassId: string | undefined,
  defaultShippingTaxClassId: string | undefined,
  createdTime: number,
  free: number,
}
interface TeamProfileFormPropsValues extends TeamProfileFormData {
  fileInput: React.RefObject<HTMLInputElement>,
  uploadProgress: number,
  logo: string,
  isSaved: boolean,
}

interface TeamTaxClassType {
  id: string,
  name: string,
  isForShipping: boolean
}
interface TeamProfileOptions {
  teamTaxClasses: TeamTaxClassType[]
}

export interface TeamProfileFormProps {
  values: TeamProfileFormPropsValues,
  actions: {
    handleFiles: () => void,
    handleSubmit: (data: TeamProfileFormData) => Promise<void>,
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>,
  },
  options: TeamProfileOptions
}
export default (props: TeamProfileFormProps) => {
  const { values, actions, options } = props;
  const plan = CheckPlan();
  const [
    defaultProductTaxClassId, setDefaultProductTaxClassId,
  ] = useState(values.defaultProductTaxClassId);
  const [
    defaultShippingTaxClassId, setDefaultShippingTaxClassId,
  ] = useState(values.defaultShippingTaxClassId);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setIsOpen(false);
  };
  const productTaxClassOptions = options
    ? options.teamTaxClasses.filter((c) => c.isForShipping === false && c.name !== 'GST Excluded Goods')
      .map((c) => ({ label: c.name, value: c.id })) : [];
  const shippingTaxClassOptions = options
    ? options.teamTaxClasses.filter((c) => c.isForShipping === true)
      .map((c) => ({ label: c.name, value: c.id })) : [];

  const filteredProductTaxClassArr = productTaxClassOptions
    .filter((t) => t.value === values.defaultProductTaxClassId);
  const defaultProductTaxClass = filteredProductTaxClassArr
    && Array.isArray(filteredProductTaxClassArr)
    && filteredProductTaxClassArr.length > 0
    ? filteredProductTaxClassArr[0] : undefined;

  const filteredShippingTaxClassArr = shippingTaxClassOptions
    .filter((t) => t.value === values.defaultShippingTaxClassId);
  const defaultShippingTaxClass = filteredShippingTaxClassArr
    && Array.isArray(filteredShippingTaxClassArr)
    && filteredShippingTaxClassArr.length > 0
    ? filteredShippingTaxClassArr[0] : undefined;

  const history = useHistory();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const free = () => {
    const current = new Date(Date.now()).getTime();
    const create = values.createdTime;
    const diff = current - create;
    const day = Math.ceil(30 - diff / (1000 * 3600 * 24));
    return day;
  };

  const isDisabled = useSubscription();
  const onSubmit = (data: TeamProfileFormData) => {
    const mergedData = {
      ...data,
      defaultProductTaxClassId: defaultProductTaxClassId !== '' ? defaultProductTaxClassId
        : (defaultProductTaxClass ? defaultProductTaxClass.value : undefined),
      defaultShippingTaxClassId: defaultShippingTaxClassId !== '' ? defaultShippingTaxClassId
        : (defaultShippingTaxClass ? defaultShippingTaxClass.value : undefined),
    };
    if (buttonRef.current) {
      /**
       * to avoid when form submited but forcus
       * still remind on one of testfields (will have vitual discomfort)
       */
      buttonRef.current.focus();
    }
    actions.handleSubmit(mergedData);
  };

  const renderLogoUploader = () => (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={values.logo} alt="logo" style={{ width: 80, margin: 10 }} />
        <input
          type="file"
          id="fileElem"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={actions.handleFiles}
          ref={values.fileInput}
          data-testid="fileUploader"
        />
        {isDisabled === false && (
          <Button
            onClick={() => values.fileInput!.current!.click()}
            isDisabled={values.isSaved}
            spacing="compact"
          >
            {i18next.t('settings.company.ul') as string}
          </Button>
        )}

      </div>
      {
        values.uploadProgress !== 0
        && <SuccessProgressBar value={values.uploadProgress} />
      }
    </>
  );

  const renderFree = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>

      <Card theme={{
        bgColor: '#f6960b',
        bgGradient: '#f6960b',
      }}
      >
        <CardBody>
          <div>
            <div>
              {`Free Trial: ${free()} days left.`}
            </div>
          </div>
        </CardBody>
      </Card>
      <CustomButton
        onClick={() => setIsOpen(true)}
        spacing="compact"
        testId="delete-payment-btn"
      >
        {i18next.t('settings.company.pu') as string}
      </CustomButton>
      <PlanUpgrade isOpen={isOpen} close={closeUpgrade} />
    </div>
  );

  const renderFormFooter = (submitting: boolean) => (
    <FormFooter>
      {!values.isSaved && isDisabled === false && (
        <ButtonGroup>
          <Button
            appearance="default"
            isLoading={submitting}
            onClick={() => history.push('/')}
          >
            {i18next.t('settings.company.cancel') as string}
          </Button>
          <Button
            type="submit"
            appearance="primary"
            isLoading={submitting}
            ref={buttonRef}
          >
            {i18next.t('settings.company.save') as string}
          </Button>
        </ButtonGroup>
      )}
      {values.isSaved && isDisabled === false && (
        <Button
          type="button"
          appearance="primary"
          onClick={() => actions.setIsSaved(false)}
          testId="company-profile-edit-button"
        >
          {i18next.t('settings.company.edit') as string}
        </Button>
      )}
    </FormFooter>
  );

  return (
    <>
      <Form<TeamProfileFormData>
        onSubmit={onSubmit}
        isDisabled={values.isSaved}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Grid>
              <GridColumn medium={6}>
                <FormHeader title={i18next.t('settings.company.cp') as string} />
              </GridColumn>
              <GridColumn medium={6}>
                {plan.title === 'Free Trial' && renderFree()}
              </GridColumn>
              <GridColumn medium={6}>
                {renderLogoUploader()}
              </GridColumn>
              <GridColumn medium={9} />
              <GridColumn medium={6}>
                <FormSection title={i18next.t('settings.company.bi') as string}>
                  <Field
                    name="teamName"
                    label={i18next.t('settings.company.tn') as string}
                    isRequired
                    defaultValue={values.teamName}
                    isDisabled={isDisabled}
                  >
                    {({ fieldProps }) => <TextField {...fieldProps} testId="teamNameInput" />}
                  </Field>
                  <Field
                    name="abn"
                    label="ABN"
                    isRequired
                    defaultValue={values.abn}
                    isDisabled={isDisabled}
                  >
                    {({ fieldProps }) => <TextField {...fieldProps} testId="abnInput" />}
                  </Field>
                  <Field<string, HTMLTextAreaElement>
                    name="description"
                    label={i18next.t('settings.company.desc') as string}
                    defaultValue={values.description}
                    isDisabled={isDisabled}
                  >
                    {({ fieldProps }) => <TextArea onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} minimumRows={5} {...fieldProps} testId="descriptionInput" />}
                  </Field>

                </FormSection>
                <FormSection title={i18next.t('settings.company.ts') as string}>
                  <Field
                    name="defaultProductTaxClassId"
                    label={i18next.t('settings.company.ptc') as string}
                  >
                    {() => (
                      defaultProductTaxClass && (
                        <Select
                          defaultValue={defaultProductTaxClass}
                          placeholder="Choose a tax class"
                          options={productTaxClassOptions}
                          isDisabled={isDisabled}
                          onChange={(valObj) => {
                            if (valObj) {
                              // @ts-ignore
                              setDefaultProductTaxClassId(valObj.value);
                            }
                          }}
                        />
                      )
                    )}
                  </Field>
                  <Field
                    name="defaultShippingTaxClassId"
                    label={i18next.t('settings.company.stc') as string}
                  >
                    {() => (
                      defaultShippingTaxClass && (
                        <Select
                          defaultValue={defaultShippingTaxClass}
                          placeholder="Choose a tax class"
                          options={shippingTaxClassOptions}
                          isDisabled={isDisabled}
                          onChange={(valObj) => {
                            if (valObj) {
                              // @ts-ignore
                              setDefaultShippingTaxClassId(valObj.value);
                            }
                          }}
                        />
                      )
                    )}
                  </Field>
                </FormSection>
              </GridColumn>
              <GridColumn medium={6}>
                <FormSection title={i18next.t('settings.company.cd') as string}>
                  <Field name="company" label={i18next.t('settings.company.company') as string} defaultValue={values.company} isDisabled={isDisabled}>
                    {({ fieldProps }) => <TextField {...fieldProps} testId="companyInput" />}
                  </Field>
                  <Grid>
                    <GridColumn medium={3}>
                      <Field name="email" label={i18next.t('settings.company.email') as string} defaultValue={values.email} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField type="email" {...fieldProps} testId="emailInput" />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={3}>
                      <Field name="phone" label={i18next.t('settings.company.phone') as string} defaultValue={values.phone} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="phoneInput" />}
                      </Field>
                    </GridColumn>
                  </Grid>
                  <Grid>
                    <GridColumn medium={3}>
                      <Field name="fax" label="Fax" defaultValue={values.fax} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="faxInput" />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={3}>
                      <Field name="mobile" label={i18next.t('settings.company.mobile') as string} defaultValue={values.mobile} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="mobileInput" />}
                      </Field>
                    </GridColumn>
                  </Grid>
                  <Grid>
                    <GridColumn medium={3}>
                      <Field name="street" label={i18next.t('settings.company.street') as string} defaultValue={values.street} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="streetInput" />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={3}>
                      <Field name="suburb" label={i18next.t('settings.company.suburb') as string} defaultValue={values.suburb} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="suburbInput" />}
                      </Field>
                    </GridColumn>
                  </Grid>
                  <Grid>
                    <GridColumn medium={3}>
                      <Field name="state" label="State" defaultValue={values.state} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="stateInput" />}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={3}>
                      <Field name="country" label={i18next.t('settings.company.country') as string} defaultValue={values.country} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="countryInput" />}
                      </Field>
                    </GridColumn>
                  </Grid>
                  <Grid>
                    <GridColumn medium={3}>
                      <Field name="postcode" label={i18next.t('settings.company.postcode') as string} defaultValue={values.postcode} isDisabled={isDisabled}>
                        {({ fieldProps }) => <TextField {...fieldProps} testId="postcodeInput" />}
                      </Field>
                    </GridColumn>
                  </Grid>
                </FormSection>
              </GridColumn>
              <GridColumn>
                {renderFormFooter(submitting)}
              </GridColumn>
            </Grid>
          </form>
        )}
      </Form>

    </>
  );
};

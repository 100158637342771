import React from 'react';
import { render } from 'react-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './index.css';
import '@atlaskit/css-reset';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { FlagsProvider } from '@atlaskit/flag';
import i18next from 'i18next';
import CreateProduct from './pages/CreateProduct/CreateProduct';
import client from './graphql/client';
import './i18n';
import {
  Home, Ping, SignUp, SignIn, Me, Settings, LanguageSettings, TeamProfileSettings,
  TeamSMTPSettings,
  NotFound, ConnectEbay, ConfigEbay,
  ImportEbay, Products, Orders,
  TeamMembers, Profile,
  UserActivation, ForgotPassword,
  ResetPassword,
  UserInvitation,
  ResendSignupActivation,
  Report, Marketplace, MarketplaceViewer,
  Order, Auspost, ConnectionError,
  Fastway, ConnectAmazon, ZendeskConnector,
  ConnectWooCommerce, ConnectShopify,
} from './pages';
import { ContentWrapper } from './components';
import { AuthProvider, Subscription } from './utils/useAuth';
import * as serviceWorker from './utils/serviceWorker';
import ProductDetailsPage from './pages/ProductDetail/ProductDetails';
import TeamCustomersListingPage from './pages/TeamCustomersListingPage';
import TeamProfile from './pages/Team';
import ActivateInvitedUser from './pages/ActivateInvitedUser';
import TeamCustomerDetails from './pages/TeamCustomerDetail/TeamCustomerDetail';
import TicketsListingPage from './pages/Tickets';
import TicketDetailPage from './pages/TicketDetail/TicketDetail';
import Receiving from './pages/Receiving';
import SMTPDetail from './pages/SMTPDetail';
import xerologin from './components/Xero/xeroLogin';
import Billing from './pages/Billing';
import TeamCharge from './pages/TeamCharge';
// import Language from './pages/Language';

const Root = () => {
  if (localStorage.getItem('lng')) {
    // console.log(`lng: ${localStorage.getItem('lng')}`);
    i18next.changeLanguage(localStorage.getItem('lng')!);
  }
  // localStorage.setItem('lng', lng);

  return (
    <ApolloProvider client={client}>
      <ReactNotification />
      <FlagsProvider>
        <Router>
          <AuthProvider>
            <Subscription>
              <ContentWrapper>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/config/ebay/:teamChannelID" component={ConfigEbay} />
                  <Route exact path="/connect/ebay/:teamChannelID" component={ConnectEbay} />
                  <Route exact path="/connect/amazon/:teamChannelID" component={ConnectAmazon} />
                  <Route exact path="/connect/wooCommerce/:teamChannelID" component={ConnectWooCommerce} />
                  <Route exact path="/connect/shopify/:teamChannelID" component={ConnectShopify} />
                  <Route exact path="/create-product" component={CreateProduct} />
                  <Route exact path="/receiving" component={Receiving} />
                  <Route exact path="/customers" component={TeamCustomersListingPage} />
                  <Route exact path="/customers/:customerId" component={TeamCustomerDetails} />
                  <Route exact path="/import/ebay/:teamChannelID" component={ImportEbay} />
                  <Route exact path="/marketplace" component={Marketplace} />
                  <Route exact path="/billing" component={Billing} />
                  <Route exact path="/marketplace/:teamChannelId" component={MarketplaceViewer} />
                  <Route exact path="/notification/connection/error/:teamChannelId" component={ConnectionError} />
                  <Route exact path="/me" component={Me} />
                  <Route exact path="/order/:orderId" component={Order} />
                  <Route exact path="/orders" component={Orders} />
                  <Route exact path="/ping" component={Ping} />
                  <Route exact path="/tickets" component={TicketsListingPage} />
                  <Route exact path="/tickets/:ticketId" component={TicketDetailPage} />
                  <Route exact path="/product/:productId" component={ProductDetailsPage} />
                  <Route exact path="/products" component={Products} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/settings" component={Settings} />
                  <Route exact path="/settings/language" component={LanguageSettings} />
                  <Route exact path="/settings/teamProfile" component={TeamProfileSettings} />
                  <Route exact path="/settings/teamSMTP" component={TeamSMTPSettings} />
                  <Route exact path="/report" component={Report} />
                  <Route exact path="/signin" component={SignIn} />
                  <Route exact path="/signin/forgot/" component={ForgotPassword} />
                  <Route exact path="/signin/reset/:userId/:token" component={ResetPassword} />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/signup/:email/resend-activation" component={ResendSignupActivation} />
                  <Route exact path="/signup/activate/:userId/:token" component={UserActivation} />
                  <Route exact path="/xerologin" component={xerologin} />
                  <Route exact path="/team/profile" component={TeamProfile} />
                  <Route exact path="/team/SMTP" component={SMTPDetail} />
                  <Route exact path="/users" component={TeamMembers} />
                  <Route exact path="/users/user-invite" component={UserInvitation} />
                  <Route exact path="/invitation/:userId/:token" component={ActivateInvitedUser} />
                  <Route exact path="/integration/auspost" component={Auspost} />
                  <Route exact path="/integration/fastway/:courierId" component={Fastway} />
                  <Route exact path="/connect/zendesk/:teamID" component={ZendeskConnector} />
                  <Route exact path="/teamCharge" component={TeamCharge} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </ContentWrapper>
            </Subscription>
          </AuthProvider>
        </Router>
      </FlagsProvider>
    </ApolloProvider>
  );
};

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

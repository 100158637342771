import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          signIn: {
            login: 'Login',
            forgotPwd: 'Forgot password?',
            signUp: 'Signup',
          },
          orders: {
            left: {
              orders: 'Orders',
              button: {
                exportOrder: 'Export Order',
                syncMultipleInvoices: 'Sync Multiple Invoices',
                reset: 'Reset',
              },
              input: {
                allDate: {
                  allDate: 'All Date',
                  last90Days: 'Last 90 days',
                  sinceToday: 'Since today',
                  sinceYesterday: 'Since yesterday',
                  sinceThisWeek: 'Since this week',
                  sinceLastWeek: 'Since last week',
                  sinceThisMonth: 'Since this month',
                  sinceLastMonth: 'Since last month',
                },
                allChannels: {
                  placeholder: 'All Channels',
                },
                xero: {
                  placeholder: 'Xero Sync Status',
                  xeroSynced: 'Xero Synced',
                  xeroNoS: 'Not synced to Xero',
                },
                order: {
                  customerName: 'Customer name',
                  customerEmail: 'Email',
                  username: 'Username',
                  orderNumber: 'Order number',
                  productName: 'Item title',
                  sku: 'Sku',
                },
                serch: {
                  placeholder: 'Keywords',
                },
              },
              newOrder: 'New Order',
              advanced: 'Advanced',
              search: 'Search',
              config: {
                all: 'All',
                unpaid: 'Unpaid',
                paid: 'Paid',
                packed: 'Packed',
                shipped: 'Shipped',
                refunded: 'Refunded',
                cancelled: 'Cancelled',
              },
              columns: {
                button: {
                  columns: 'Columns',
                },
                order: {
                  order: 'Order',
                },
                channel: {
                  channel: 'Channel',
                },
                customer: {
                  customer: 'Customer',
                },
                describe: 'Show/Hide Columns ',
              },
              pin: 'Pin overdue',
              records: 'Records:',
              totalAmt: 'Total Amt:',
              totalDue: 'Total Due:',
              table: {
                head: {
                  orderId: 'Order ID',
                  store: 'Store',
                  shipBy: 'Ship By',
                  channel: 'Channel',
                  created: 'Created',
                  updated: 'Updated',
                  customer: 'Customer',
                  username: 'Username',
                  email: 'Email',
                  fax: 'Fax',
                  phone: 'Phone',
                  mobile: 'Mobile',
                  paid: 'Paid',
                  totalInc: 'Total (Inc.)',
                  transactionFee: 'Transaction fee',
                  gst: 'GST',
                  shippingInc: 'Shipping (Inc.)',
                  discount: 'Discount',
                  status: 'Status',
                  payment: 'Payment',
                  xeroSyncStatus: 'Xero Sync Status',
                  action: 'Action',
                  vd: 'View Details',
                },
              },
            },
          },
          nav: {
            home: 'home',
            billings: 'billings',
            products: 'products',
            orders: 'orders',
            customers: 'customers',
            tickets: 'tickets',
            report: 'report',
            marketplace: 'marketplace',
            users: 'users',
            settings: {
              companyProfile: 'company profile',
              dashboard: 'Dashboard',
              smtp: 'SMTP',
              docs: 'Docs',
            },
            profile: {
              button: {
                language: 'Langeuage Change',
                profile: 'Profile',
                billing: 'Billing',
                signOut: 'Sign Out',
              },
              language: {
                field: {
                  value: 'Langeuage Change',
                },
                select: {
                  chinese: 'Chinese-Simplified',
                  english: 'English-AU',
                },
              },
            },
            stt: 'Switch to team...',
            nc: 'Notifications',
            maar: 'Mark all as read',
            nnrn: 'No notifications right now',
          },
          orderDetails: {
            co: 'Cancel Order',
            orderStatus: {
              UNPAID: 'UNPAID',
              REFUNDED: 'REFUNDED',
              SHIPPED: 'SHIPPED',
              PAID: 'PAID',
              CANCELLED: 'CANCELLED',
            },
            toolBar: {
              ship: 'Ship',
              shipped: 'Shipped',
              print: 'Print',
              email: 'Email',
              pay: 'Pay',
              connectToEbay: 'Open on eBay',
              clone: 'Clone',
            },
            orderInfo: {
              totalInc: 'Total Inc',
              orderedAt: 'Ordered At',
              updatedAt: 'Updated At',
              preferredCourier: 'Preferred courier',
              preferredPaymentMethod: 'Preferred Payment Method',
              postageDue: 'Postage due',
              store: 'Store',
              shipTo: 'Ship to',
              billTo: 'Bill to',
              product: {
                products: 'Products',
                tableHead: {
                  product: 'Product',
                  unitPriceInc: 'Unit Price (Inc.)',
                  qty: 'Qty',
                  taxRate: 'Tax Rate',
                  totalInc: 'Total (Inc.)',
                },
                tableCell: {
                  shippingInc: 'Shipping (Inc.)',
                  includeGST: 'Include GST',
                  discount: 'Discount',
                  totalInc: 'Total (Inc.)',
                  transactionFee: 'Transaction Fee',
                },
              },
            },
            activity: {
              activity: 'Activity',
              show: 'Show:',
              comments: 'Comments',
              history: 'History',
              addAComment: 'Add a comment...',
              save: 'Save',
              cancel: 'Cancel',
            },
            payment: {
              payments: 'Payments',
              date: 'Date',
              pb: 'Paid By',
              pm: 'Payment Method',
              amount: 'Amount',
              ac: 'Action',
              ad: 'Amount Due:',
              op: 'Order Payment',
              pam: 'Payment Method *',
              sb: 'Submit',
              cc: 'Cancel',
              pipa: 'Please input paid amount',
              fpmf: 'Fetching payment methods failed',
              pmir: 'payment method is required',
              pair: 'paid amount is required',
              asblt: 'amount should be larger than 0',
              asblto: 'amount should be less than order total amount',
            },
            pm: {
              am: 'Amazon',
              bdd: 'Bank Direct Deposit',
              cash: 'Cash',
              ebay: 'eBay',
              eft: 'EFTPOS',
              mag: 'Magento',
              sho: 'Shopify',
              woo: 'Woocommerce',
            },
          },
          quickStart: {
            quickStart: 'Quickstart',
            cancelQuickstart: 'Cancel Quickstart',
            connectToMarketPlace: {
              connectToMarketPlace: 'Connect to MarketPlace',
              describe: 'By connecting to your first store, you can leverage a range of our powerful features to manage your business with ease.',
              connect: 'Connect',
            },
            configureStore: {
              csp: 'Configure store policies',
              describe: 'Set default store policies for more efficient product listing.',
              cs: 'Configure store',
            },
            configureCompany: {
              ccp: 'Configure company profile',
              describe: 'Make sure your company&apos;s ABN and tax information are entered correctly.',
              cp: 'Company profile',
            },
            importProducts: {
              ip: 'Import products',
              describe: 'Import products directly from your eBay store to save the hassle of data entry.',
            },
            importOrders: {
              io: 'Import orders',
              describe: 'Gain insights into your business by visualising your eBay orders.',
            },
          },
          home: {
            dashboard: 'Dashboard',
            totalSales: 'Total Sales',
            last31DaysSales: 'Last 31 Days Sales',
            awaitingPostageOrders: 'Awaiting Postage Orders',
            chooseTheDisplayValue: 'Choose the display value',
            tss: {
              tss: 'Top SKU Sales',
            },
            tcs: {
              tcs: 'Top Customer Sales',
            },
            report: 'Reports',
          },
          products: {
            all: 'All',
            products: 'Products',
            button: {
              addProduct: 'Add Product',
              bulkUpdate: 'Bulk Update',
              receiving: 'Receiving',
              exportProductsData: 'Export Products Data',
              advanced: 'Advanced',
              vd: 'View Details',
              si: 'Stock In',
              de: 'Delete',
            },
            searchBox: {
              search: 'Search',
              searchItemNameAndSku: 'Search item name and sku',
            },
            left: {
              columns: {
                columns: 'Columns',
                describe: 'Show/Hide Columns ',
                product: {
                  product: 'Product',
                  title: 'Title',
                  brand: 'Brand',
                  stockOnHand: 'Stock on hand',
                  totalStockValue: 'Total stock value',
                  created: 'Created',
                  updated: 'Updated',
                  upeg: 'RRP (Ex.)',
                  uni: 'RRP (Inc.)',
                },
                category: {
                  category: 'Category',
                },
              },
            },
            product: {
              productUpdate: 'Product Update',
              viewEBayListing: 'View eBay Listing',
              viewMagentoListing: 'View Magento Listing',
              sep: 'Swap/Replace eBay Products',
              delete: 'Delete',
              openOnEBay: 'Open on eBay',
              productCatagories: 'Product Catagories',
              title: 'Title',
              titleDesc: 'Use words people would search for when looking for your item.',
              sku: 'SKU',
              pcm: {
                search: 'Search',
                close: 'Close',
                placeholder: 'Please enter a keyword to start',
                sac: 'Suggest a category',
              },
              edit: {
                required: 'Required',
                recommended: 'Recommended',
                additional: 'Additional',
                addPhotos: 'Add photos',
                apd: 'Improve your buyer&apos;s confidence by including as many as possible',
                description: 'Description',
                dd: 'Tell buyers about unique features, flaws, or why are you selling it!',
                inventory: 'Inventory',
                supplierInventory: 'Supplier Inventory',
                taxSettings: 'Tax Settings',
                soh: 'Stock on hand',
                tic: 'Total inventory cost (AUD Excl.)',
                uc: 'Unit cost (AUD Excl.)',
                ptc: 'Product Tax Class',
                gig: 'GST Included Goods',
                gfg: 'GST Free Goods',
                mp: 'Multiple Pricing',
                fs: 'For Sale',
                rrp: 'RRP (AUD Inc.)',
                revise: 'Revise',
                cancel: 'Cancel',
                li: 'List it',
                activity: 'Activity',
              },
              supply: {
                wn: 'Warehouse Name',
                stock: 'Stock',
                icr: 'Is Customer Region',
                eta: 'ETA',
              },
            },
          },
          customers: {
            search: 'Search',
            customers: 'Customers',
            advanced: 'Advanced',
            filter: {
              placeholder: 'choose a store',
              select: {
                anyTime: 'Any time',
                otaw: 'Older than a week',
                otam: 'Older than a month',
                ot6m: 'Older than 6 months',
                otay: 'Older than a year',
              },
            },
            columns: {
              columns: 'Columns',
              select: {
                store: 'Store',
                name: 'Name',
                email: 'Email',
                cn: 'Contact No',
                purchases: 'Purchases',
                te: 'Total Expense',
                ca: 'Created At',
              },
              action: 'Action',
            },
            cd: {
              bi: 'Basic Info',
              ea: 'Email Address',
              cp: 'Contact Phone',
              cm: 'Contact Mobile',
              sa: 'Shipping Address',
              ba: 'Billing Address',
              cn: 'Company Name',
              saddr: 'Street Address',
              state: 'State',
              country: 'Country',
              cname: 'Contact Name',
              city: 'City',
              pc: 'Postcode',
            },
          },
          channels: {
            card: {
              or: 'Orders',
              re: 'Revenue',
              pr: 'Products',
            },
            channels: 'Channels',
            shopifyConnect: 'Shopify Connect',
            accounting: 'Accounting',
            cs: 'Customer Service',
            ci: 'Courier Integration',
            supplier: 'Supplier',
            mc: {
              ctans: 'Connect to a new store',
              psast: 'Please select a store type:',
              imos: 'Install MERP on Shopify?',
              next: 'Next',
              cancel: 'Cancel',
              eysn: 'Enter your store name',
              emu: 'Enter Magento URL',
              emat: 'Enter Magento Access Token',
              ewu: 'Enter WooCommerce URL',
            },
            ai: {
              ai: 'App Inquiry',
              desc: 'Which new App would you like to integrate to MERP?',
              submit: 'Submit',
              cancel: 'Cancel',
            },
            sc: {
              ctans: 'Connect to a new supplier?',
              psast: 'Please select a store type:',
              eysn: 'Enter your Supplier Name',
              eyck: 'Enter your Consumer Key',
              eycs: 'Enter your Consumer Secret',
              eysi: 'Enter your Source Identifier',
            },
          },
          users: {
            users: 'Users',
            iu: 'Invite User',
            table: {
              fn: 'Full Name',
              email: 'Email',
              activated: 'Activated',
              cd: 'Created Date',
              ud: 'Updated Date',
              action: 'Action',
            },
            ui: {
              ui: 'User invitation',
              iue: '*Invitation User Email',
              team: '*Team',
              submit: 'Submit',
              uieis: 'User invitation email is successfully sent to',
              placeholder: 'please input invitation user email',
              message: 'Entered value does not match email format',
            },
          },
          settings: {
            company: {
              cp: 'Company Profile',
              bi: 'Basic information',
              ts: 'Tax Settings',
              cd: 'Contact details',
              cancel: 'Cancel',
              save: 'Save',
              edit: 'Edit',
              pu: 'Plan Upgrade',
              ul: 'Upload logo',
              tn: 'Team Name',
              desc: 'Description',
              ptc: 'Product Tax Class',
              stc: 'Shipping Tax Class',
              company: 'Company',
              email: 'Email',
              phone: 'Phone',
              mobile: 'Mobile',
              street: 'Street',
              suburb: 'Suburb',
              country: 'Country',
              postcode: 'Postcode',
            },
            smtp: {
              cancel: 'Cancel',
              save: 'Save',
              sco: 'SMTP Configuration Option',
              host: 'Host',
              shnaia: 'Support Host Name and IP Address',
              port: 'Port',
              dp: 'Default ports: 25, 465 or 587. Port 465(SSL Required), Port 587(TLS Required)',
              username: 'Username',
              ti: 'This is your email account which sending email to customer.',
              pwd: 'Password',
              tiy: 'This is your email password which sending email to customer.',
              eye: 'Enter your Email and Click',
              tn: 'Test Now',
              bt: 'button to test the configuration.',
              st: 'Send To',
              ste: 'send Test Email',
            },
            profile: {
              fn: 'First Name',
              ln: 'Last Name',
              ene: 'Email (not editable)',
              security: 'Security',
              cp: 'Change Password?',
              o: 'Onboarding',
              req: 'Re-enable Quickstart',
            },
          },
          billing: {
            cp: 'Current Plan',
            dft: '30-DAY FREE TRIAL',
            up: 'UPGRADE',
            cr: 'Cancel Renewal',
            rs: 'Renew Subscription',
            // ynpi: 'Your next payment is',
            bi: 'Billing Information',
            mbi: 'Modify Billling Information',
            bh: 'Billing History',
            nci: 'No Charge Invoice',
            paid: 'PAID',
            unpaid: 'UNPAID',
            date: 'Date',
            in: 'Invoice Number',
            status: 'Status',
          },
          recv: {
            sr: 'Stock Receiving',
            cty: 'Changes that you made may not be saved.',
            srr: 'Stock Receiving (result)',
            print: 'Print',
            nsr: 'New Stock Receiving',
            epnos: 'Enter product name or sku',
            npf: 'No product found',
            head: {
              name: 'Name',
              qty: 'Qty',
              uc: 'Unit Cost (AUD Excl.)',
              tc: 'Total Cost (AUD Excl.)',
            },
            reset: 'Reset',
            submit: 'Submit',
            sub: 'All charges are billed in USD. Recurring and usage-based charges are billed every 30 days.',
          },
        },
      },
      zh: {
        translation: {
          signIn: {
            login: '登录',
            forgotPwd: '忘记密码?',
            signUp: '注册',
          },
          orders: {
            left: {
              orders: '订单',
              button: {
                exportOrder: '导出订单',
                syncMultipleInvoices: 'Invoices批量同步',
                reset: '重置',
              },
              input: {
                allDate: {
                  allDate: '所有日期',
                  last90Days: '过去90天',
                  sinceToday: '今天起',
                  sinceYesterday: '昨天起',
                  sinceThisWeek: '本周起',
                  sinceLastWeek: '上周起',
                  sinceThisMonth: '本月起',
                  sinceLastMonth: '上月起',
                },
                allChannels: {
                  placeholder: '所有店铺',
                },
                xero: {
                  placeholder: 'Xero 同步状态',
                  xeroSynced: 'Xero 已同步',
                  xeroNoS: 'Xero 未同步',
                },
                order: {
                  customerName: '客户名称',
                  customerEmail: '邮箱',
                  username: '用户名',
                  orderNumber: '订单号',
                  productName: '商品名称',
                  sku: 'Sku',
                },
                search: {
                  placeholder: '关键词',
                },
              },
              newOrder: '创建订单',
              advanced: '高级搜索',
              search: '搜索',
              config: {
                all: '全部',
                unpaid: '未付款',
                paid: '已付款',
                packed: '已打包',
                shipped: '已发货',
                refunded: '已退款',
                cancelled: '已取消',
              },
              columns: {
                button: {
                  columns: '自定义列',
                },
                order: {
                  order: '订单',
                },
                channel: {
                  channel: '平台',
                },
                customer: {
                  customer: '客户',
                },
                describe: '显示/隐藏 属性 ',
              },
              pin: 'Pin 截止日期',
              records: '总订单数:',
              totalAmt: '订单总额：',
              totalDue: '逾期总额：',
              table: {
                head: {
                  orderId: '订单号',
                  store: '店铺',
                  shipBy: '物流公司',
                  channel: '平台',
                  created: '创建时间',
                  updated: '更新时间',
                  customer: '客户',
                  username: '用户名',
                  email: '邮箱',
                  fax: 'Fax',
                  phone: '电话',
                  mobile: '手机号',
                  paid: '已支付',
                  totalInc: '含税总额',
                  transactionFee: '手续费',
                  gst: '税率',
                  shippingInc: '邮费含税',
                  discount: '折扣',
                  status: '状态',
                  payment: '支付方式',
                  xeroSyncStatus: 'Xero同步状态',
                  action: '操作',
                  vd: '订单详情',
                },
              },
            },
          },
          nav: {
            home: '首页',
            billings: '账单',
            products: '商品',
            orders: '订单',
            customers: '客户',
            tickets: '票据',
            report: '报表',
            marketplace: '店铺',
            users: '用户',
            settings: {
              companyProfile: '公司信息',
              dashboard: '报表面板',
              smtp: 'SMTP',
              docs: '文档',
            },
            profile: {
              button: {
                language: '语言切换',
                profile: '个人信息',
                billing: '账单',
                signOut: '退出登录',
              },
              language: {
                field: {
                  value: '语言切换',
                },
                select: {
                  chinese: '中文简体',
                  english: '英文-澳大利亚',
                },
              },
            },
            stt: '切换团队...',
            nc: '消息',
            maar: '全部标记已读',
            nnrn: '当前没有消息',
          },
          orderDetails: {
            co: '取消订单',
            orderStatus: {
              UNPAID: '未支付',
              REFUNDED: '已退款',
              SHIPPED: '已发货',
              PAID: '已支付',
              CANCELLED: '已取消',
            },
            toolBar: {
              ship: '邮寄',
              shipped: '已发货',
              print: '打印发票',
              email: '发邮件',
              pay: '支付',
              connectToEbay: '链接 eBay',
              clone: '克隆订单',
            },
            orderInfo: {
              totalInc: '总价（含税）',
              orderedAt: '创建时间',
              updatedAt: '更新时间',
              preferredCourier: '首选运输方式',
              preferredPaymentMethod: '首选支付方式',
              postageDue: '发货截至日期',
              store: '店铺',
              shipTo: '收货地址',
              billTo: '账单地址',
              product: {
                products: '商品',
                tableHead: {
                  product: '商品',
                  unitPriceInc: '单价（含税）',
                  qty: '数量',
                  taxRate: '税率',
                  totalInc: '总价（含税）',
                },
                tableCell: {
                  shippingInc: '运费（含税）',
                  includeGST: '税款',
                  discount: '折扣',
                  totalInc: '总价（含税）',
                  transactionFee: '手续费',
                },
              },
            },
            activity: {
              activity: '留言',
              show: '展示：',
              comments: '留言记录',
              history: '历史记录',
              addAComment: '新增留言...',
              save: '保存',
              cancel: '取消',
            },
            payment: {
              payments: '付款',
              date: '日期',
              pb: '支付人',
              pm: '支付方式',
              amount: '金额',
              ac: '操作',
              ad: '未支付金额：',
              op: '付款',
              pam: '支付方式 *',
              sb: '提交',
              cc: '取消',
              pipa: '请输入付款金额',
              fpmf: '获取支付方式失败',
              pmir: '支付方式必填',
              pair: '付款金额不能为空',
              asblt: '付款金额必须大于0',
              asblto: '付款金额必须小于订单总金额',
            },
            pm: {
              am: '亚马逊支付',
              bdd: '银行转账',
              cash: '现金',
              ebay: 'eBay支付',
              eft: 'EFTPOS支付',
              mag: 'Magento支付',
              sho: 'Shopify支付',
              woo: 'Woocommerce支付',
            },
          },
          quickStart: {
            quickStart: '快速开始',
            cancelQuickstart: '取消快速开始',
            connectToMarketPlace: {
              connectToMarketPlace: '快速链接店铺',
              describe: '通过链接您的第一个店铺，您可以按照教程轻松管理您的商务。',
              connect: '链接',
            },
            configureStore: {
              csp: '配置店铺协议',
              describe: '设置配置店铺协议更加高效管理商品.',
              cs: '配置店铺信息',
            },
            configureCompany: {
              ccp: '配置公司信息',
              describe: '确保填写正确的公司ABN和税率信息。',
              cp: '公司信息',
            },
            importProducts: {
              ip: '同步商品',
              describe: '直接从您的店铺同步商品信息到 Merp SaaS 本地。',
            },
            importOrders: {
              io: '同步订单',
              describe: '直接从您的店铺同步订单信息到 Merp SaaS 本地',
            },
          },
          home: {
            dashboard: '统计面板',
            totalSales: '总销售额',
            last31DaysSales: '过去31天销售额',
            awaitingPostageOrders: '待处理订单',
            chooseTheDisplayValue: '选择展示内容',
            tss: {
              tss: '销售额（按产品SKU）',
            },
            tcs: {
              tcs: '销售额（按客户）',
            },
            report: '分析',
          },
          products: {
            all: '全部',
            products: '商品',
            button: {
              addProduct: '添加商品',
              bulkUpdate: '批量更新',
              receiving: '商品入库',
              exportProductsData: '导出商品数据',
              advanced: '高级搜索',
              vd: '查看详情',
              si: '产品入库',
              de: '详情',
            },
            searchBox: {
              search: '搜索',
              searchItemNameAndSku: '搜索商品名称和SKU',
            },
            left: {
              columns: {
                columns: '自定义列',
                describe: '显示/隐藏 属性 ',
                product: {
                  product: '商品',
                  title: '名称',
                  brand: '品牌',
                  stockOnHand: '库存',
                  totalStockValue: '库存总额',
                  created: '创建时间',
                  updated: '更新时间',
                  upeg: '单价不含税',
                  uni: '单价含税',
                },
                category: {
                  category: '类别',
                },
              },
            },
            product: {
              productUpdate: '商品详情',
              viewEBayListing: '查看eBay列表',
              viewMagentoListing: '查看Magento列表',
              sep: '交换或者替换eBay 商品',
              delete: '删除',
              openOnEBay: '在eBay中打开',
              productCatagories: '商品类别',
              title: '标题',
              titleDesc: '在创建商品标题时尽量使用热门产品关键词，以增加用户搜索到您商品的机会。',
              sku: 'SKU（货号）',
              pcm: {
                search: '搜索',
                close: '取消',
                placeholder: '请输入关键词开始',
                sac: '建议分类',
              },
              edit: {
                required: '有 * 的项为必填项',
                recommended: '建议',
                additional: '可选',
                addPhotos: '商品图片',
                apd: '上传清晰高质量的产品图片，会让客户更加直观的了解产品',
                description: '产品详情',
                dd: '填写产品规则，特性，用途和优势等信息，会让客户更了解产品。',
                inventory: '库存',
                supplierInventory: '供应商库存',
                taxSettings: '税率设置',
                soh: '有货',
                tic: '总库存成本（不含税）',
                uc: '单位成本（不含税）',
                ptc: '产品税类',
                gig: '含税商品',
                gfg: '不含税商品',
                mp: '多平台定价',
                fs: '是否销售',
                rrp: '建议零售价（含税）',
                revise: '更新',
                cancel: '取消',
                li: '发布',
                activity: '操作记录与留言',
              },
              supply: {
                wn: '仓库名称',
                stock: '库存',
                icr: '库存地图',
                eta: '预计到货时间',
              },
            },
          },
          customers: {
            search: '搜索',
            customers: '客户',
            advanced: '高级搜索',
            filter: {
              placeholder: '选择店铺',
              select: {
                anyTime: '任意时间',
                otaw: '一周前',
                otam: '一月前',
                ot6m: '六月前',
                otay: '一年前',
              },
            },
            columns: {
              columns: '自定义列',
              select: {
                store: '店铺',
                name: '客户姓名',
                email: '邮件地址',
                cn: '联系电话',
                purchases: '订单量',
                te: '消费金额',
                ca: '创建时间',
              },
              action: '更多操作',
            },
            cd: {
              bi: '基本信息',
              ea: '邮件地址',
              cp: '联系电话',
              cm: '联系手机',
              sa: '收货地址',
              ba: '账单地址',
              cn: '公司',
              saddr: '收货地址',
              state: '州',
              country: '国家',
              cname: '收货人',
              city: '城市',
              pc: '邮政编码',
            },
          },
          channels: {
            card: {
              or: '订单',
              re: '销售额',
              pr: '产品',
            },
            channels: '店铺',
            shopifyConnect: '链接 Shopify',
            accounting: '财务',
            cs: '客户服务',
            ci: '物流集成',
            supplier: '供应商',
            mc: {
              ctans: '链接新店铺',
              psast: '选择平台：',
              imos: '安装 MERP 在Shopify?',
              next: '继续',
              cancel: '取消',
              eysn: '输入店铺名',
              emu: '输入 Magento 链接',
              emat: '输入 Magento Access Token',
              ewu: '输入 WooCommerce 链接',
            },
            ai: {
              ai: '添加物流公司',
              desc: '你想 MERP 加入哪个物流 APP 支持呢？',
              submit: '提交',
              cancel: '取消',
            },
            sc: {
              ctans: '链接新的供应商？',
              psast: '选择供应商类型:',
              eysn: '输入你的供应商名',
              eyck: '输入你的 Consumer Key',
              eycs: '输入你的 Consumer Secret',
              eysi: '输入你的 Source Identifier',
            },
          },
          users: {
            users: '用户',
            iu: '邀请用户',
            table: {
              fn: '全名',
              email: '邮箱',
              activated: '是否激活',
              cd: '创建时间',
              ud: '更新时间',
              action: '操作',
            },
            ui: {
              ui: '用户邀请',
              iue: '*邀请用户邮箱',
              team: '*团队',
              submit: '提交',
              uieis: '用户邀请邮件已经成功发送到',
              placeholder: '请填写邀请用户邮箱',
              message: '请输入正确的邮箱格式',
            },
          },
          settings: {
            company: {
              cp: '公司信息',
              bi: '基础信息',
              ts: '税率设置',
              cd: '联系详情',
              cancel: '取消',
              save: '保存',
              edit: '编辑',
              pu: '订阅升级',
              ul: '上传 logo',
              tn: '团队名称',
              desc: '公司简述',
              ptc: '商品税率',
              stc: '邮费税率',
              company: '公司名称',
              email: '邮箱',
              phone: '联系方式',
              mobile: '移动电话',
              street: '街道、乡镇',
              suburb: '省市区域',
              country: '国家',
              postcode: '邮编',
            },
            smtp: {
              cancel: '取消',
              save: '保存',
              sco: 'SMTP 信息设置',
              host: '地址',
              shnaia: '支持域名或 IP 地址',
              port: '端口',
              dp: '默认端口: 25, 465 or 587. 端口 465(SSL 必选), 端口 587(TLS 必选)',
              username: '名称',
              ti: '发送邮件给客户的邮件名。',
              pwd: '密码',
              tiy: '发送邮件给客户的邮箱密码。',
              eye: '输入邮箱后点击',
              tn: '立即测试',
              bt: '按钮 测试配置是否正确。',
              st: '发送至',
              ste: '发送测试邮件',
            },
            profile: {
              fn: '姓',
              ln: '名字',
              ene: '邮箱（不可编辑）',
              security: '安全设置',
              cp: '修改密码？',
              o: '展示',
              req: '重新启用 快速设置',
            },
          },
          billing: {
            cp: '当前订阅',
            dft: '30天免费试用',
            up: '升级',
            cr: '取消订阅',
            rs: '重新订阅',
            // ynpi: 'Your next payment is',
            bi: '账单信息',
            mbi: '编辑账单信息',
            bh: '账单历史',
            nci: 'Invoice信息为空',
            paid: '已支付',
            unpaid: '未支付',
            date: '日期',
            in: 'Invoice 编号',
            status: '状态',
            sub: '所有费用以美元计费。每30天计算一次。',
          },
          recv: {
            sr: '库存接收',
            cty: '修改未保存.',
            srr: '库存接收（结果）',
            print: '打印',
            nsr: '新库存接收中',
            epnos: '输入商品名称 或 sku',
            npf: '未查询到商品',
            head: {
              name: '名称',
              qty: '库存',
              uc: '商品单价（不含税.）',
              tc: '商品总价（不含税.）',
            },
            reset: '重置',
            submit: '提交',
          },
        },
      },
    },
  });

export default i18next;

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Order } from '../../OrdersListing/types';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import MerpDateTime from '../../MerpDateTime/MerpDateTime';
import { isPostageNearDue } from '../helpers';

const OrderInfoContainer = styled.div`
    box-sizing:border-box;
    width:33%;
    padding-right:5px;
    .label{
        color:rgb(107, 119, 140);
        font-size:11px;
        line-height:16px;
        padding-bottom:5px;
    }
    ul {
        list-style:none;
        padding:0;
        margin:0;
      }
    .red {
      color:red;
      font-weight:500;
    }  
`;

interface Props {
  order:Order,
}

export default ({ order }:Props) => {
  const { t } = useTranslation();
  const {
    totalAmtInclTax,
    currency,
    createdAt,
    lastModifiedDate,
    shippingCarrierCode,
    shippingServiceCode,
    preferPaymentMethod,
  } = order;

  const renderPostageDue = () => {
    if (isNull(order.shipByDate)) return <></>;
    if (!['PAID', 'PACKED'].includes(order.status.code)) {
      return <></>;
    }

    const red = isPostageNearDue(order) ? 'red' : '';

    return (
      <li>
        <div className="label">{t('orderDetails.orderInfo.postageDue')}</div>
        <div className={`value ${red}`}>{moment(order.shipByDate).local().fromNow()}</div>
      </li>
    );
  };

  const renderStoreName = () => (
    <li>
      <div className="label">{t('orderDetails.orderInfo.store')}</div>
      <div className="value">{order.teamChannel.name}</div>
    </li>
  );

  return (
    <OrderInfoContainer>
      <ul>
        <li>
          <div className="label">{t('orderDetails.orderInfo.totalInc')}</div>
          <div className="value order-total">
            {formatMoneyWithPrefix(totalAmtInclTax, currency)}
          </div>
        </li>
        <li>
          <div className="label">{t('orderDetails.orderInfo.orderedAt')}</div>
          <div className="value"><MerpDateTime>{createdAt}</MerpDateTime></div>
        </li>
        <li>
          <div className="label">{t('orderDetails.orderInfo.updatedAt')}</div>
          <div className="value"><MerpDateTime>{lastModifiedDate}</MerpDateTime></div>
        </li>
        <li>
          <div className="label">{t('orderDetails.orderInfo.preferredCourier')}</div>
          <div className="value">
            {shippingCarrierCode && `${shippingCarrierCode} -`}
            {shippingServiceCode && ` ${shippingServiceCode}`}
          </div>
        </li>
        <li>
          <div className="label">{t('orderDetails.orderInfo.preferredPaymentMethod')}</div>
          <div className="value">
            {preferPaymentMethod || 'N/A'}
          </div>
        </li>
        {renderStoreName()}
        {renderPostageDue()}
      </ul>
    </OrderInfoContainer>
  );
};

import React, { useEffect, useCallback } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { ErrorMessage } from '@atlaskit/form';
import Button from '@atlaskit/button';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useGetUsersByTeamQuery, useUnlinkTeamMutation } from '../../graphql/types';
import head from './head';
import getRows from './rows';
import Wrapper from '../ListView/Wrapper';

import addNotification from '../../utils/addNotification';
import { useSubscription } from '../../utils/useAuth';

interface TeamMembersListingProps {
  teamId:string,
}
export default (props:TeamMembersListingProps) => {
  const { t } = useTranslation();
  const { teamId } = props;
  const history = useHistory();
  const {
    data, loading, error, refetch,
  } = useGetUsersByTeamQuery({
    variables: {
      teamId,
    },
    fetchPolicy: 'network-only',
  });
  const [rows, setRows] = React.useState<Array<RowType>>([]);
  const [unlinkTeam, unlinkTeamState] = useUnlinkTeamMutation();
  const freeExpired = useSubscription();

  const handleUnlinkTeam = useCallback(
    (userIdToBeUnlink: string) => {
      unlinkTeam({
        variables: {
          userId: userIdToBeUnlink,
          teamId,
        },
      })
        .then(() => {
          refetch();
          // send a notifacation on sucess
          addNotification('Unlink Sucessful', 'success');
        })
        .catch((err: ApolloError) => addNotification(graphQLErrorsReader(err), 'danger'));
    }, [teamId, refetch, unlinkTeam],
  );

  useEffect(() => {
    setRows(getRows(data, handleUnlinkTeam));
  }, [data, handleUnlinkTeam]);

  return (
    <>
      <Wrapper>
        <div className="heading">
          <h2>{t('users.users')}</h2>
          {freeExpired === false && (
          <Button
            testId="user-invite"
            appearance="primary"
            onClick={() => history.push('/users/user-invite')}
          >
            {t('users.iu')}
          </Button>
          )}

        </div>
        <DynamicTable
          head={head}
          rows={rows}
          loadingSpinnerSize="large"
          rowsPerPage={20}
          defaultPage={1}
          isLoading={loading || unlinkTeamState.loading}
          isFixedSize
          testId="team-users-listing-table"
        />
        {error
          && (
          <ErrorMessage>
              {graphQLErrorsReader(error)}
          </ErrorMessage>
          )}
      </Wrapper>
    </>
  );
};

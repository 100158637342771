import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `Decimal` scalar type to represent currency values */
  Decimal: any;
  JSON: any;
};


export type ActivateInvitedUserResponse = BaseResponse & {
  __typename?: 'ActivateInvitedUserResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  name: Scalars['String'];
  company: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  fax: Scalars['String'];
  mobile: Scalars['String'];
  street: Scalars['String'];
  suburb: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  postcode: Scalars['String'];
  comments: Scalars['String'];
  lat: Scalars['String'];
  long: Scalars['String'];
};

export type AddressInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  fax: Scalars['String'];
  mobile: Scalars['String'];
  street: Scalars['String'];
  suburb: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  postcode: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export type AddressParams = {
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export type AdjustInventoryResponse = BaseResponse & {
  __typename?: 'AdjustInventoryResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
  sohBeforeMutation: Scalars['Int'];
  totalInventoryValueBeforeMutation: Scalars['Float'];
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['String'];
  file_name: Scalars['String'];
  content_url: Scalars['String'];
};

export type AttributeSet = {
  __typename?: 'AttributeSet';
  id: Scalars['ID'];
  name: Scalars['String'];
  channelAttributeSetId: Scalars['String'];
};

export type AuspostCreateLabelsResponse = BaseResponse & {
  __typename?: 'AuspostCreateLabelsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response: Scalars['JSON'];
};

export type AuspostCreateShipmentResponse = BaseResponse & {
  __typename?: 'AuspostCreateShipmentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response: Scalars['JSON'];
};

export type AuspostGetAccountsResponse = BaseResponse & {
  __typename?: 'AuspostGetAccountsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type AuspostGetQuoteResponse = BaseResponse & {
  __typename?: 'AuspostGetQuoteResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type BaseResponse = {
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type CancelOrderInput = {
  orderId: Scalars['String'];
};

export type CancelOrderResponse = BaseResponse & {
  __typename?: 'CancelOrderResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type CancelTeamChargeResponse = BaseResponse & {
  __typename?: 'CancelTeamChargeResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type CategoryEbay = {
  __typename?: 'CategoryEBAY';
  categoryID?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
};

export type ChangePasswordResponse = BaseResponse & {
  __typename?: 'ChangePasswordResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CleanTeamChannelByIdResponse = BaseResponse & {
  __typename?: 'CleanTeamChannelByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['String'];
  author: Scalars['String'];
  htmlBody: Scalars['String'];
  created_at: Scalars['String'];
  attachments: Array<Attachment>;
};

export type Courier = {
  __typename?: 'Courier';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedById: Scalars['String'];
};

export type CreateBulkProductTemplateResponse = BaseResponse & {
  __typename?: 'CreateBulkProductTemplateResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['String']>;
};

export type CreateBulkProductUpdateReportResponse = BaseResponse & {
  __typename?: 'CreateBulkProductUpdateReportResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['String']>;
};

export type CreateCommentResponse = BaseResponse & {
  __typename?: 'CreateCommentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type CreateCourierInquiryResponse = BaseResponse & {
  __typename?: 'CreateCourierInquiryResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type CreateInvoiceResponse = BaseResponse & {
  __typename?: 'CreateInvoiceResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  invoice?: Maybe<Order>;
};

export type CreateItem = {
  product_id: Scalars['String'];
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  weight: Scalars['Float'];
};

export type CreateOrderInput = {
  teamCustomerId: Scalars['String'];
  orderItems: Array<OrderItemInputForCreateOrder>;
  comments?: Maybe<Scalars['String']>;
  isAmountIncTax: Scalars['Boolean'];
};

export type CreateOrderResponse = BaseResponse & {
  __typename?: 'CreateOrderResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order: Order;
};

export type CreatePaymentResponse = BaseResponse & {
  __typename?: 'CreatePaymentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type CreateProductResponse = BaseResponse & {
  __typename?: 'CreateProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Scalars['JSON']>;
};

export type CreateShip = {
  teamId: Scalars['String'];
  orderId: Scalars['String'];
  notesOnLabel: Scalars['String'];
  items: Array<CreateItem>;
};

export type CreateShipmentResponse = BaseResponse & {
  __typename?: 'CreateShipmentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  shipment?: Maybe<Shipment>;
};

export type CreateTeamChannelResponse = BaseResponse & {
  __typename?: 'CreateTeamChannelResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannel: TeamChannel;
};

export type CreateTeamChannelSupplierResponse = BaseResponse & {
  __typename?: 'CreateTeamChannelSupplierResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannelSupplier: TeamChannelSupplier;
};

export type CreateTeamChargeResponse = BaseResponse & {
  __typename?: 'CreateTeamChargeResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  confirmationUrl?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  chargeType: Scalars['Int'];
  chargeId?: Maybe<Scalars['String']>;
};

export type CreateTeamCustomerAddessInput = {
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type CreateTeamCustomerCommentResponse = BaseResponse & {
  __typename?: 'CreateTeamCustomerCommentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  comment?: Maybe<TeamCustomerComment>;
};

export type CreateTeamCustomerInput = {
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<CreateTeamCustomerAddessInput>;
  billingAddress?: Maybe<CreateTeamCustomerAddessInput>;
};

export type CreateTeamCustomerResponse = BaseResponse & {
  __typename?: 'CreateTeamCustomerResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCustomer: TeamCustomer;
};

export type CreateXeroInvoiceResponse = BaseResponse & {
  __typename?: 'CreateXeroInvoiceResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type CreateXeroInvoicesResponse = BaseResponse & {
  __typename?: 'CreateXeroInvoicesResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type CustomerSortInput = {
  key: Scalars['String'];
  sortOrder: Scalars['String'];
};

export type CybersourceChargeSubResponse = BaseResponse & {
  __typename?: 'CybersourceChargeSubResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  userCheckUrl?: Maybe<Scalars['String']>;
  checkStatus?: Maybe<Scalars['String']>;
  chargeId?: Maybe<Scalars['String']>;
  authenticationTransactionId?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
};

export type CybersourceValidateResResponse = BaseResponse & {
  __typename?: 'CybersourceValidateResResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};


export type DeactivatedTeamChannelResponse = BaseResponse & {
  __typename?: 'DeactivatedTeamChannelResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type DeactivatedTeamChannelSupplierResponse = BaseResponse & {
  __typename?: 'DeactivatedTeamChannelSupplierResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};


export type DeletePaymentInput = {
  orderId: Scalars['String'];
  paymentId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
};

export type DeletePaymentResponse = BaseResponse & {
  __typename?: 'DeletePaymentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type DeleteProductResponse = BaseResponse & {
  __typename?: 'DeleteProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  deletedId?: Maybe<Scalars['String']>;
};

export type DeleteShipmentResponse = BaseResponse & {
  __typename?: 'DeleteShipmentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  shipment?: Maybe<Shipment>;
};

export type Dimensions = {
  width: Scalars['Float'];
  length: Scalars['Float'];
  height: Scalars['Float'];
  unit: Scalars['String'];
};

export type DisconnectXeroResponse = BaseResponse & {
  __typename?: 'DisconnectXeroResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type EbayBulkUpdatePar = {
  productId: Scalars['String'];
  unitPrice: Scalars['Float'];
  stockOnHand: Scalars['Int'];
};

export type EbayBulkUpdateProductsResponse = BaseResponse & {
  __typename?: 'EbayBulkUpdateProductsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  products?: Maybe<Array<Maybe<EbayProductUpdateRes>>>;
};

export type EbayDelProductResponse = BaseResponse & {
  __typename?: 'EbayDelProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type EbayGetCategoryAspectsResponse = BaseResponse & {
  __typename?: 'EbayGetCategoryAspectsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type EbayGetCategorySubtreeResponse = BaseResponse & {
  __typename?: 'EbayGetCategorySubtreeResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type EbayGetCategorySuggestionsResponse = BaseResponse & {
  __typename?: 'EbayGetCategorySuggestionsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type EbayGetCategoryTreeResponse = BaseResponse & {
  __typename?: 'EbayGetCategoryTreeResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type EbayGetExistingProductsResponse = BaseResponse & {
  __typename?: 'EbayGetExistingProductsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  totalPages?: Maybe<Scalars['Int']>;
  currPageNum?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ProductEbay>>>;
};

export type EbayGetOrdersResponse = BaseResponse & {
  __typename?: 'EbayGetOrdersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type EbayGetOutOfStockStatusResponse = BaseResponse & {
  __typename?: 'EbayGetOutOfStockStatusResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  outOfStockStatus: Scalars['Boolean'];
};

export type EbayGetShipmentsResponse = BaseResponse & {
  __typename?: 'EbayGetShipmentsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type EbayOauthUrlResponse = BaseResponse & {
  __typename?: 'EbayOauthUrlResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  oauthUrl: Scalars['String'];
};

export type EbayProduct = {
  sku: Scalars['String'];
  /** title */
  name: Scalars['String'];
  fullDescription: Scalars['String'];
  images: Array<Maybe<Scalars['String']>>;
  specs: Array<Spec>;
  /**
   * "NEW" | "LIKE_NEW" | "NEW_OTHER" | "NEW_WITH_DEFECTS" |
   * "MANUFACTURER_REFURBISHED" | "SELLER_REFURBISHED" | "USED_EXCELLENT" |
   * "USED_VERY_GOOD" | "USED_GOOD" | "USED_ACCEPTABLE" |
   * "FOR_PARTS_OR_NOT_WORKING"
   */
  condition: Scalars['String'];
  stockOnHand: Scalars['Int'];
  unitPrice: Scalars['Int'];
  categoryId: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  packageWeightAndSize: PackageWeightAndSize;
  variations: Array<Variation>;
};

export type EbayProductSettings = {
  fulfillmentPolicyId: Scalars['String'];
  paymentPolicyId: Scalars['String'];
  returnPolicyId: Scalars['String'];
  merchantLocationKey: Scalars['String'];
  marketplaceId: Scalars['String'];
};

export type EbayProductUpdateRes = {
  __typename?: 'EbayProductUpdateRes';
  productId: Scalars['String'];
  /** success or failed */
  ack: Scalars['String'];
  /** if ack is failed, err will not null */
  err: Scalars['String'];
};

export type EbayPutProductResponse = BaseResponse & {
  __typename?: 'EbayPutProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  productId: Scalars['String'];
};

export type EbayPutShipmentResponse = BaseResponse & {
  __typename?: 'EbayPutShipmentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type EbayPutShipmentTrackInfo = {
  lineItems: Array<EbayShipmentLineItem>;
  shippingCarrierCode: Scalars['String'];
  trackingNumber: Scalars['String'];
};

export type EbayRefreshAccessTokenResponse = BaseResponse & {
  __typename?: 'EbayRefreshAccessTokenResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
};

export type EbayResponse = {
  __typename?: 'EbayResponse';
  response?: Maybe<Scalars['JSON']>;
};

export type EbaySetOutOfStockStatusResponse = BaseResponse & {
  __typename?: 'EbaySetOutOfStockStatusResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type EbayShipmentLineItem = {
  /** must match eaby order lineItem legacyItemId */
  lineItemId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Error = {
  __typename?: 'error';
  message?: Maybe<Scalars['String']>;
};

export type ExportOrdersResponse = BaseResponse & {
  __typename?: 'ExportOrdersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type ExportProductsResponse = BaseResponse & {
  __typename?: 'ExportProductsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type FastItemInput = {
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  weight: Scalars['Float'];
};

export type FastwayConsignmentInput = {
  quantityBox: Scalars['Int'];
  packageType: FastwayPackageType;
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  street: Scalars['String'];
  suburb: Scalars['String'];
  state: Scalars['String'];
  postcode: Scalars['String'];
  items: Array<FastItemInput>;
  notesOnLabel?: Maybe<Scalars['String']>;
  satchelSize?: Maybe<FastwaySatchelSize>;
};

export type FastwayCreateConsignmentResponse = BaseResponse & {
  __typename?: 'FastwayCreateConsignmentResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  conId: Scalars['String'];
  trackingNumber: Scalars['String'];
  externalResponse: Scalars['JSON'];
};

export type FastwayCreateLabelResponse = BaseResponse & {
  __typename?: 'FastwayCreateLabelResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['String']>;
};

export type FastwayGetBalanceResponse = BaseResponse & {
  __typename?: 'FastwayGetBalanceResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  balance?: Maybe<Scalars['Float']>;
};

export type FastwayGetQuote = {
  __typename?: 'FastwayGetQuote';
  priceInc: Scalars['Float'];
  description: Scalars['String'];
  packageType: FastwayPackageType;
  satchelSize?: Maybe<FastwaySatchelSize>;
  courierId: Scalars['String'];
};

export type FastWayGetQuoteResponse = BaseResponse & {
  __typename?: 'FastWayGetQuoteResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  quote?: Maybe<FastwayGetQuote>;
};

export enum FastwayPackageType {
  Package = 'Package',
  Satchel = 'Satchel'
}

export enum FastwaySatchelSize {
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5'
}

export type FeedBackResponse = BaseResponse & {
  __typename?: 'FeedBackResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  score?: Maybe<Scalars['Int']>;
};

export type GetCouriersResponse = BaseResponse & {
  __typename?: 'getCouriersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  couriers: Array<Courier>;
};

export type GetOrderByIdResponse = BaseResponse & {
  __typename?: 'GetOrderByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type GetOrderStatusResponse = BaseResponse & {
  __typename?: 'getOrderStatusResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  orderStatus?: Maybe<Array<OrderStatus>>;
};

export type GetPaymentMethodsResponse = BaseResponse & {
  __typename?: 'getPaymentMethodsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};

export type GetSeedBySkuResponse = BaseResponse & {
  __typename?: 'GetSeedBySkuResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  productSeed?: Maybe<ProductSeed>;
};

export type GetTcssByTeamIdResponse = BaseResponse & {
  __typename?: 'GetTcssByTeamIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannelSuppliers: Array<TeamChannelSupplier>;
};

export type GetTeamByIdResponse = BaseResponse & {
  __typename?: 'GetTeamByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  team?: Maybe<Team>;
};

export type GetTeamChannelByIdResponse = BaseResponse & {
  __typename?: 'GetTeamChannelByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannel: TeamChannel;
};

export type GetTeamChannelsByTeamIdResponse = BaseResponse & {
  __typename?: 'GetTeamChannelsByTeamIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannels: Array<TeamChannel>;
};

export type GetTeamChargeAliveResponse = BaseResponse & {
  __typename?: 'GetTeamChargeAliveResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCharge?: Maybe<TeamCharge>;
};

export type GetTeamChargeBillingsByIdResponse = BaseResponse & {
  __typename?: 'GetTeamChargeBillingsByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  billings?: Maybe<Array<TeamChargeBilling>>;
};

export type GetTeamChargePlanByIdResponse = BaseResponse & {
  __typename?: 'GetTeamChargePlanByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChargePlan?: Maybe<TeamChargePlan>;
};

export type GetTeamChargePlansResponse = BaseResponse & {
  __typename?: 'GetTeamChargePlansResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChargePlans?: Maybe<Array<TeamChargePlan>>;
};

export type GetTeamCourierResponse = BaseResponse & {
  __typename?: 'GetTeamCourierResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCourier?: Maybe<TeamCourier>;
};

export type GetTeamCouriersResponse = BaseResponse & {
  __typename?: 'GetTeamCouriersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCouriers?: Maybe<Array<TeamCourier>>;
};

export type GetTeamSmtpResponse = BaseResponse & {
  __typename?: 'GetTeamSMTPResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamSMTP?: Maybe<TeamSmtp>;
};

export type GetTeamTaxClassesResponse = BaseResponse & {
  __typename?: 'GetTeamTaxClassesResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  taxClasses?: Maybe<Array<ProductTaxClass>>;
};

export type GetTeamXeroAccountCodeResponse = BaseResponse & {
  __typename?: 'GetTeamXeroAccountCodeResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type GetTodayShipmentOrdersResponse = BaseResponse & {
  __typename?: 'GetTodayShipmentOrdersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  shipmentOrders?: Maybe<Array<Maybe<ShipmentOrder>>>;
  totalShipmentOrders?: Maybe<Scalars['Int']>;
  totalShipments?: Maybe<Scalars['Int']>;
};

export type GetUserByTokenResponse = BaseResponse & {
  __typename?: 'GetUserByTokenResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type GetUsersByTeamResponse = BaseResponse & {
  __typename?: 'GetUsersByTeamResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  users?: Maybe<Array<User>>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type ImportEbayListingsResponse = BaseResponse & {
  __typename?: 'ImportEbayListingsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  numberOfProductsCreated?: Maybe<Scalars['Int']>;
};

export type InviteUserResponse = BaseResponse & {
  __typename?: 'InviteUserResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type InvoiceChargePdfCreationResponse = BaseResponse & {
  __typename?: 'InvoiceChargePdfCreationResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['String']>;
};

export type InvoiceInput = {
  orderId: Scalars['String'];
  orderItems: Array<Maybe<OrderItemInput>>;
};

export type InvoicePdfCreationResponse = BaseResponse & {
  __typename?: 'InvoicePdfCreationResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  url?: Maybe<Scalars['String']>;
};

export type ItemInput = {
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  weight: Scalars['Float'];
};

export type ItemsInput = {
  teamId: Scalars['String'];
  fromName: Scalars['String'];
  toName: Scalars['String'];
  fromLine: Array<Scalars['String']>;
  toLine: Array<Scalars['String']>;
  fromSuburb: Scalars['String'];
  toSuburb: Scalars['String'];
  fromState: Scalars['String'];
  toState: Scalars['String'];
  fromPostCode: Scalars['String'];
  toPostCode: Scalars['String'];
  items: Array<ItemInput>;
};


export type Log = {
  __typename?: 'Log';
  id: Scalars['ID'];
  teamId: Scalars['String'];
  tableId: Scalars['String'];
  tableName: Scalars['String'];
  content: Scalars['String'];
  type: LogTypeEnum;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  comments: Scalars['String'];
};

export type LoginResponse = BaseResponse & {
  __typename?: 'LoginResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
  accessToken?: Maybe<Scalars['String']>;
};

export type LogoutResponse = BaseResponse & {
  __typename?: 'LogoutResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type LogResponse = BaseResponse & {
  __typename?: 'LogResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  log: Log;
};

export type LogsResponse = BaseResponse & {
  __typename?: 'LogsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  logs: Array<Log>;
  total?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/** The table name of backend Api that allow logging */
export enum LogTableName {
  Order = 'Order'
}

export enum LogTypeEnum {
  Api = 'API',
  Manual = 'MANUAL'
}

export type MagentoOrderMarkAsPaidInput = {
  orderId: Scalars['String'];
};

export type MagentoOrderMarkAsPaidResponse = BaseResponse & {
  __typename?: 'MagentoOrderMarkAsPaidResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type Mutation = {
  __typename?: 'Mutation';
  SignUp: SignupResponse;
  Login: LoginResponse;
  Logout: LogoutResponse;
  ActivateUser: UserActivationResponse;
  RequestPasswordReset: RequestPasswordResetResponse;
  ResetPassword: ResetPasswordResponse;
  InviteUser: InviteUserResponse;
  ActivateInvitedUser: ActivateInvitedUserResponse;
  UpdateUser: UpdateUserResponse;
  ChangePassword: ChangePasswordResponse;
  ResendSignupActivation: ResendSignupActivationResponse;
  XeroLogin: XeroLoginResponse;
  UnlinkTeam: UnlinkTeamResponse;
  UpdateTeamById: UpdateTeamByIdResponse;
  SetTeamTaxClasses: SetTeamDefaultTaxClassResponse;
  SetTeamSMTP: SetTeamSmtpResponse;
  SmtpSendCheck: SmtpSendCheckResponse;
  DisconnectXero: DisconnectXeroResponse;
  SetTeamXeroAccountCode: SetTeamXeroAccountCodeResponse;
  CreateTeamCharge: CreateTeamChargeResponse;
  InvoiceChargePdfCreation: InvoiceChargePdfCreationResponse;
  CancelTeamCharge: CancelTeamChargeResponse;
  CybersourceChargeSub: CybersourceChargeSubResponse;
  CybersourceValidateRes: CybersourceValidateResResponse;
  UpdateTeamChannel: UpdateTeamChannelResponse;
  CreateTeamChannel: CreateTeamChannelResponse;
  DeactivateTeamChannel: DeactivatedTeamChannelResponse;
  SyncEbayStoreInfo: SyncEbayStoreInfoResponse;
  CleanTeamChannelById: CleanTeamChannelByIdResponse;
  SyncEbayProductsViaQueue: SyncEbayProductsViaQueueResponse;
  CreateTeamChannelSupplier: CreateTeamChannelSupplierResponse;
  DeactivateTeamChannelSupplier: DeactivatedTeamChannelSupplierResponse;
  SyncProductsViaQueue: SyncProductsViaQueueResponse;
  /** Create a product */
  CreateProduct: CreateProductResponse;
  /** Update a product */
  UpdateProduct: UpdateProductResponse;
  DeleteProduct: DeleteProductResponse;
  /** updagte teamChannelProduct */
  TeamChannelProductUpdate: UpdateTeamChannelProductResponse;
  /** Update a product */
  UpdateProductStockOnHand: UpdateProductResponse;
  /** pass teamChannelID to import all ebay active listings into database.  */
  ImportEbayListings: ImportEbayListingsResponse;
  /** update bulk products */
  UpdateBulkProducts: UpdateBulklProductsResponse;
  CreateBulkProductUpdateReport: CreateBulkProductUpdateReportResponse;
  /** Push products to a target team channel */
  PushProductsToTeamChannel: PushProductsToTeamChannelResponse;
  /** export products csv */
  ExportProducts: ExportProductsResponse;
  /** Swap or replace a ebay product with target ebay product */
  SwapEBayProduct: SwapEBayProductResponse;
  AdjustInventory: AdjustInventoryResponse;
  CreateBulkProductTemplate: CreateBulkProductTemplateResponse;
  /** this mutation will pull ebay open orders,customers and address into database. */
  PullEbayOpenOrders: PullEbayOpenOrdersResponse;
  mutateCreateInvoice: CreateInvoiceResponse;
  InvoicePdfCreation: InvoicePdfCreationResponse;
  SendInvoiceEmailMutation: SendInvoiceEmailResponse;
  /** Create a shipment */
  CreateShipment: CreateShipmentResponse;
  UpdateOrderMutation: UpdateOrderResponse;
  CreateOrder: CreateOrderResponse;
  UpdateManualOrder: UpdateManualOrderResponse;
  UpdateShippingCost: UpdateShippingCostResponse;
  DeleteShipment: DeleteShipmentResponse;
  magentoOrderMarkAsPaid: MagentoOrderMarkAsPaidResponse;
  CancelOrder: CancelOrderResponse;
  /** Update order discount */
  UpdateOrderDiscount: UpdateOrderDiscountResponse;
  /** export orders csv */
  ExportOrders: ExportOrdersResponse;
  CreateXeroInvoice: CreateXeroInvoiceResponse;
  /** create orders invoices */
  CreateXeroInvoices: CreateXeroInvoicesResponse;
  UpdateAddress: UpdateAddressResponse;
  UpdateTeamCustomer: UpdateTeamCustomerResponse;
  CreateTeamCustomer: CreateTeamCustomerResponse;
  CreateTeamCustomerComment: CreateTeamCustomerCommentResponse;
  SetTeamCourier: SetTeamCourierResponse;
  CreateCourierInquiry: CreateCourierInquiryResponse;
  AuspostCreateShipment: AuspostCreateShipmentResponse;
  AuspostCreateLabels: AuspostCreateLabelsResponse;
  AddLog: LogResponse;
  EditLog: LogResponse;
  DeleteLog: LogResponse;
  createPayment: CreatePaymentResponse;
  deletePayment: DeletePaymentResponse;
  createComment: CreateCommentResponse;
  updateTicket: UpdateTicketInfoResponse;
  FastwayCreateShipment: FastwayCreateConsignmentResponse;
  FastwayCreateLabel: FastwayCreateLabelResponse;
  MutateEbay?: Maybe<EbayResponse>;
  EbayOauthUrl: EbayOauthUrlResponse;
  EbayPutProduct: EbayPutProductResponse;
  EbayDelProduct: EbayDelProductResponse;
  EbayBulkUpdateProducts: EbayBulkUpdateProductsResponse;
  EbaySetOutOfStockStatus: EbaySetOutOfStockStatusResponse;
  EbayPutShipment: EbayPutShipmentResponse;
};


export type MutationSignUpArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  teamName: Scalars['String'];
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationActivateUserArgs = {
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String'];
  teamID: Scalars['String'];
};


export type MutationActivateInvitedUserArgs = {
  userId: Scalars['String'];
  token: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationResendSignupActivationArgs = {
  userEmail: Scalars['String'];
  resendEmail: Scalars['String'];
};


export type MutationXeroLoginArgs = {
  accessToken?: Maybe<Scalars['String']>;
};


export type MutationUnlinkTeamArgs = {
  teamId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateTeamByIdArgs = {
  teamId: Scalars['String'];
  team: TeamInput;
  contact: AddressInput;
  defaultProductTaxClassId?: Maybe<Scalars['String']>;
  defaultShippingTaxClassId?: Maybe<Scalars['String']>;
  isOverwriteProducts?: Maybe<Scalars['Boolean']>;
};


export type MutationSetTeamTaxClassesArgs = {
  teamId: Scalars['String'];
  defaultProductTaxClassId: Scalars['String'];
  defaultShippingTaxClassId: Scalars['String'];
  businessNumber?: Maybe<Scalars['String']>;
};


export type MutationSetTeamSmtpArgs = {
  teamId: Scalars['String'];
  teamSMTP: TeamSmtpInput;
};


export type MutationSmtpSendCheckArgs = {
  teamId: Scalars['String'];
  sendTo: Scalars['String'];
};


export type MutationDisconnectXeroArgs = {
  teamId: Scalars['String'];
};


export type MutationSetTeamXeroAccountCodeArgs = {
  teamId: Scalars['String'];
  xeroAccountCode: Scalars['String'];
};


export type MutationCreateTeamChargeArgs = {
  teamId: Scalars['String'];
  teamChannelId?: Maybe<Scalars['String']>;
  chargeType: Scalars['Int'];
  cardNumber: Scalars['String'];
  expiryDate: Scalars['String'];
  cvc: Scalars['String'];
};


export type MutationInvoiceChargePdfCreationArgs = {
  teamChargeBillingId: Scalars['String'];
};


export type MutationCancelTeamChargeArgs = {
  teamId: Scalars['String'];
  teamChargeId: Scalars['String'];
};


export type MutationCybersourceChargeSubArgs = {
  teamId: Scalars['String'];
  chargeId?: Maybe<Scalars['String']>;
  authenticationTransactionId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  street: Scalars['String'];
  suburb: Scalars['String'];
  postcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  cardNumber: Scalars['String'];
  expiryDate: Scalars['String'];
  cvc: Scalars['String'];
  step: Scalars['Int'];
};


export type MutationCybersourceValidateResArgs = {
  teamId: Scalars['String'];
  chargeId?: Maybe<Scalars['String']>;
  authenticationTransactionId: Scalars['String'];
  cardNumber: Scalars['String'];
};


export type MutationUpdateTeamChannelArgs = {
  teamChannelID: Scalars['String'];
  fulfillmentPolicyId?: Maybe<Scalars['String']>;
  fulfillmentPolicyName?: Maybe<Scalars['String']>;
  merchantLocationKey?: Maybe<Scalars['String']>;
  merchantLocationName?: Maybe<Scalars['String']>;
  paymentPolicyId?: Maybe<Scalars['String']>;
  paymentPolicyName?: Maybe<Scalars['String']>;
  returnPolicyId?: Maybe<Scalars['String']>;
  returnPolicyName?: Maybe<Scalars['String']>;
  pushStockQty?: Maybe<Scalars['Boolean']>;
  pushPriceUpdates?: Maybe<Scalars['Boolean']>;
  onBoarded?: Maybe<Scalars['Boolean']>;
  watermarkUrl?: Maybe<Scalars['String']>;
};


export type MutationCreateTeamChannelArgs = {
  teamId: Scalars['String'];
  name: Scalars['String'];
  channelId?: Maybe<Scalars['String']>;
  channelSettings?: Maybe<Scalars['String']>;
};


export type MutationDeactivateTeamChannelArgs = {
  teamChannelId: Scalars['String'];
};


export type MutationSyncEbayStoreInfoArgs = {
  teamChannelId: Scalars['String'];
};


export type MutationCleanTeamChannelByIdArgs = {
  teamChannelId: Scalars['String'];
};


export type MutationSyncEbayProductsViaQueueArgs = {
  teamChannelId: Scalars['String'];
};


export type MutationCreateTeamChannelSupplierArgs = {
  teamId: Scalars['String'];
  name: Scalars['String'];
  tcsSettings: TcsSettingsInput;
};


export type MutationDeactivateTeamChannelSupplierArgs = {
  teamChannelSupplierId: Scalars['String'];
};


export type MutationSyncProductsViaQueueArgs = {
  teamChannelId: Scalars['String'];
};


export type MutationCreateProductArgs = {
  product: ProductInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['String'];
  product: ProductInput;
};


export type MutationDeleteProductArgs = {
  id: Scalars['String'];
};


export type MutationTeamChannelProductUpdateArgs = {
  id: Scalars['String'];
  unitPrice: Scalars['Float'];
  stockOnHand: Scalars['Int'];
};


export type MutationUpdateProductStockOnHandArgs = {
  id: Scalars['String'];
  stockOnHand: Scalars['Int'];
};


export type MutationImportEbayListingsArgs = {
  teamChannelID: Scalars['String'];
};


export type MutationUpdateBulkProductsArgs = {
  teamID: Scalars['String'];
  fileName: Scalars['String'];
};


export type MutationCreateBulkProductUpdateReportArgs = {
  jobId: Scalars['String'];
};


export type MutationPushProductsToTeamChannelArgs = {
  teamChannelId: Scalars['String'];
};


export type MutationExportProductsArgs = {
  teamId: Scalars['String'];
};


export type MutationSwapEBayProductArgs = {
  sourceProductId: Scalars['String'];
  targetProductId: Scalars['String'];
  mode: Scalars['String'];
};


export type MutationAdjustInventoryArgs = {
  productId: Scalars['String'];
  changeInQty: Scalars['Int'];
  costPerUnit?: Maybe<Scalars['Float']>;
  isTaxIncluded?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
};


export type MutationCreateBulkProductTemplateArgs = {
  teamID: Scalars['String'];
};


export type MutationPullEbayOpenOrdersArgs = {
  teamChannelID: Scalars['String'];
};


export type MutationMutateCreateInvoiceArgs = {
  invoice: InvoiceInput;
};


export type MutationInvoicePdfCreationArgs = {
  orderId: Scalars['String'];
};


export type MutationSendInvoiceEmailMutationArgs = {
  sendInvoiceEmailArg: SendInvoiceEmailInput;
};


export type MutationCreateShipmentArgs = {
  shipment: ShipmentInput;
};


export type MutationUpdateOrderMutationArgs = {
  updateOrderArg: UpdateOrderInput;
};


export type MutationCreateOrderArgs = {
  teamChannelId: Scalars['String'];
  data: CreateOrderInput;
};


export type MutationUpdateManualOrderArgs = {
  teamChannelId: Scalars['String'];
  data: UpdateManualOrderInput;
};


export type MutationUpdateShippingCostArgs = {
  orderId: Scalars['String'];
  shippingServiceCode?: Maybe<Scalars['String']>;
  shippingCarrierCode?: Maybe<Scalars['String']>;
  totalShippingAmtInclTax: Scalars['Float'];
};


export type MutationDeleteShipmentArgs = {
  shipmentId: Scalars['String'];
};


export type MutationMagentoOrderMarkAsPaidArgs = {
  markAsPaid: MagentoOrderMarkAsPaidInput;
};


export type MutationCancelOrderArgs = {
  cancelOrder: CancelOrderInput;
};


export type MutationUpdateOrderDiscountArgs = {
  discount: OrderDiscountInput;
};


export type MutationExportOrdersArgs = {
  teamId: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Scalars['String']>>;
  customerName?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};


export type MutationCreateXeroInvoiceArgs = {
  orderId: Scalars['String'];
  teamID: Scalars['String'];
};


export type MutationCreateXeroInvoicesArgs = {
  teamId: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Scalars['String']>>;
  customerName?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  isXeroSynced?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAddressArgs = {
  addressId: Scalars['String'];
  addressParams: AddressParams;
};


export type MutationUpdateTeamCustomerArgs = {
  customerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};


export type MutationCreateTeamCustomerArgs = {
  teamChannelId: Scalars['String'];
  teamCustomer: CreateTeamCustomerInput;
};


export type MutationCreateTeamCustomerCommentArgs = {
  teamId: Scalars['String'];
  customerId: Scalars['String'];
  content: Scalars['String'];
};


export type MutationSetTeamCourierArgs = {
  teamId: Scalars['String'];
  courierId: Scalars['String'];
  configuration: Scalars['String'];
  active: Scalars['Boolean'];
};


export type MutationCreateCourierInquiryArgs = {
  courierName: Scalars['String'];
  teamID: Scalars['String'];
};


export type MutationAuspostCreateShipmentArgs = {
  createShip?: Maybe<CreateShip>;
};


export type MutationAuspostCreateLabelsArgs = {
  teamId: Scalars['String'];
  shipmentIds: Array<Scalars['String']>;
};


export type MutationAddLogArgs = {
  teamId: Scalars['String'];
  tableId: Scalars['String'];
  tableName: LogTableName;
  content: Scalars['String'];
};


export type MutationEditLogArgs = {
  logId: Scalars['String'];
  content: Scalars['String'];
};


export type MutationDeleteLogArgs = {
  logId: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  payment: PaymentInput;
};


export type MutationDeletePaymentArgs = {
  deletePayment: DeletePaymentInput;
};


export type MutationCreateCommentArgs = {
  teamID: Scalars['String'];
  action: Scalars['String'];
  ticketId: Scalars['String'];
  body: Scalars['String'];
  authorId: Scalars['String'];
};


export type MutationUpdateTicketArgs = {
  teamID: Scalars['String'];
  ticketId: Scalars['String'];
  channel?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
};


export type MutationFastwayCreateShipmentArgs = {
  teamId: Scalars['String'];
  consignment: FastwayConsignmentInput;
};


export type MutationFastwayCreateLabelArgs = {
  teamId: Scalars['String'];
  conId: Scalars['String'];
};


export type MutationMutateEbayArgs = {
  resource: Scalars['String'];
  params: Array<Maybe<Scalars['JSON']>>;
};


export type MutationEbayOauthUrlArgs = {
  callbackUrl: Scalars['String'];
  redirectUrl: Scalars['String'];
  callbackToken: Scalars['String'];
  newOrderCallbackUrl: Scalars['String'];
};


export type MutationEbayPutProductArgs = {
  productId?: Maybe<Scalars['String']>;
  accessToken: Scalars['String'];
  productSettings: EbayProductSettings;
  product: EbayProduct;
};


export type MutationEbayDelProductArgs = {
  productId: Scalars['String'];
  accessToken: Scalars['String'];
};


export type MutationEbayBulkUpdateProductsArgs = {
  accessToken: Scalars['String'];
  products: Array<EbayBulkUpdatePar>;
};


export type MutationEbaySetOutOfStockStatusArgs = {
  accessToken: Scalars['String'];
  outOfStockStatus: Scalars['Boolean'];
};


export type MutationEbayPutShipmentArgs = {
  orderId: Scalars['String'];
  accessToken: Scalars['String'];
  trackInfo: EbayPutShipmentTrackInfo;
};

export type MyEbaySellingResponse = BaseResponse & {
  __typename?: 'MyEbaySellingResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  teamChannelID: Scalars['String'];
  teamID: Scalars['String'];
  channelID: Scalars['String'];
  teamCustomerID: Scalars['String'];
  shippingAddrID: Scalars['String'];
  billingAddrID: Scalars['String'];
  shippingAddress: Address;
  billingAddress: Address;
  status: OrderStatus;
  paymentStatus: Scalars['String'];
  currency: Scalars['String'];
  orderNumber: Scalars['String'];
  totalAmount: Scalars['Decimal'];
  paidAmount: Scalars['Decimal'];
  comments: Scalars['String'];
  totalTaxAmt: Scalars['Decimal'];
  totalShippingAmt: Scalars['Decimal'];
  shippingTaxAmt: Scalars['Decimal'];
  shippingTaxRate: Scalars['Decimal'];
  totalDiscountAmt: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  lastModifiedDate: Scalars['DateTime'];
  orderItems: Array<OrderItem>;
  teamCustomer: TeamCustomer;
  teamChannel: TeamChannel;
  channel: Channel;
  team: Team;
  version: Scalars['Int'];
  invNumber: Scalars['String'];
  invDate?: Maybe<Scalars['DateTime']>;
  rootId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  shipments: Array<Shipment>;
  totalShippingAmtInclTax: Scalars['Decimal'];
  totalAmtInclTax: Scalars['Decimal'];
  shippingServiceCode: Scalars['String'];
  shippingCarrierCode: Scalars['String'];
  marketPlaceFee: Scalars['Decimal'];
  legacyOrderId: Scalars['String'];
  shipByDate?: Maybe<Scalars['DateTime']>;
  amountBase: TaxAmountBaseEnum;
  totalPaidAmt: Scalars['Decimal'];
  payments: Array<Payment>;
  magentoEntityId: Scalars['String'];
  orderDiscountAmt: Scalars['Decimal'];
  preferPaymentMethod: Scalars['String'];
  xeroInvoiceId: Scalars['String'];
};


export type OrderShipmentsArgs = {
  orderBy: OrderShipmentsOrderByInput;
};

export type OrderDiscountInput = {
  orderId: Scalars['String'];
  discountAmt: Scalars['Float'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  productID: Scalars['String'];
  teamChannelProductID: Scalars['String'];
  orderID: Scalars['String'];
  sku: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  unitPriceExclGST: Scalars['Decimal'];
  qty: Scalars['Int'];
  discountAmount: Scalars['Decimal'];
  totalPrice: Scalars['Decimal'];
  taxAmt: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  extraInfo: Scalars['String'];
  order: Order;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  totalAmtInclTax: Scalars['Decimal'];
  shipByDate?: Maybe<Scalars['DateTime']>;
  productTaxClassId?: Maybe<Scalars['String']>;
};

export type OrderItemInput = {
  orderItemId: Scalars['String'];
  qty: Scalars['Int'];
};

export type OrderItemInputForCreateOrder = {
  teamChannelProductId: Scalars['String'];
  teamTaxClassId: Scalars['String'];
  qty: Scalars['Int'];
  unitPrice: Scalars['Float'];
};

export type OrderItemInputForUpdateOrder = {
  orderItemId: Scalars['String'];
  teamChannelProductId: Scalars['String'];
  teamTaxClassId: Scalars['String'];
  qty: Scalars['Int'];
  unitPrice: Scalars['Float'];
};

export type OrderItemUpdateInput = {
  orderItemId: Scalars['String'];
  taxClassId: Scalars['String'];
};

export type OrdersByCustomerResponse = BaseResponse & {
  __typename?: 'OrdersByCustomerResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  orders?: Maybe<Array<Order>>;
  total?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type OrderShipmentsOrderByInput = {
  createdAt: SortOrder;
};

export type OrdersResponse = BaseResponse & {
  __typename?: 'OrdersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  orders?: Maybe<Array<Order>>;
  totalAmtInclTax?: Maybe<Scalars['Float']>;
  totalOutstanding?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  take: Scalars['Int'];
  skip: Scalars['Int'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  id: Scalars['ID'];
  code: OrderStatusCodeEnum;
  name: Scalars['String'];
  order: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum OrderStatusCodeEnum {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  Packed = 'PACKED',
  Shipped = 'SHIPPED',
  Refunded = 'REFUNDED',
  Cancelled = 'CANCELLED'
}

export type OrderType = {
  __typename?: 'OrderType';
  id: Scalars['ID'];
  name: OrderTypeEnum;
};

export enum OrderTypeEnum {
  Order = 'ORDER',
  Invoice = 'INVOICE'
}

export type PackageWeightAndSize = {
  dimensions: Dimensions;
  weight: Weight;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  orderId: Scalars['String'];
  order: Order;
  paymentMethodId: Scalars['String'];
  paymentMethod: PaymentMethod;
  orderNumber: Scalars['String'];
  currency: Scalars['String'];
  paidAmount: Scalars['Decimal'];
  paidById: Scalars['String'];
  paidBy: TeamCustomer;
  createdById?: Maybe<Scalars['String']>;
  paymentCreatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['String']>;
  paymentUpdatedBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  teamChannelId: Scalars['String'];
  teamChannel: TeamChannel;
};

export type PaymentInput = {
  orderId: Scalars['String'];
  paymentMethodId: Scalars['String'];
  teamChannelId: Scalars['String'];
  orderNumber: Scalars['String'];
  currency: Scalars['String'];
  paidAmount: Scalars['Float'];
  paidById: Scalars['String'];
  createdById: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  teamID: Scalars['String'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  sku: Scalars['String'];
  shortDescription: Scalars['String'];
  fullDescription: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  stockOnHand: Scalars['Int'];
  totalInventoryCost: Scalars['Decimal'];
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isForSale: Scalars['Boolean'];
  productProp: Scalars['String'];
  fromChannelId: Scalars['String'];
  teamChannelProducts: Array<TeamChannelProduct>;
  images: Array<Image>;
  productAttributeValues: Array<ProductAttributeValue>;
  attributeSet: AttributeSet;
  productTaxClass?: Maybe<ProductTaxClass>;
  unitPriceExclGST: Scalars['Decimal'];
  snapshot: Scalars['String'];
};


export type ProductTeamChannelProductsArgs = {
  where?: Maybe<ProductTeamChannelProductsWhereInput>;
};


export type ProductImagesArgs = {
  orderBy: ProductImagesOrderByInput;
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProductAttributeValue = {
  __typename?: 'ProductAttributeValue';
  id: Scalars['ID'];
  value: Scalars['String'];
  productAttribute: ProductAttribute;
};

export type ProductEbay = {
  __typename?: 'ProductEBAY';
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  itemID?: Maybe<Scalars['String']>;
  category?: Maybe<CategoryEbay>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  sellerShippingProfile?: Maybe<SellerShippingProfileEbay>;
  sellerReturnProfile?: Maybe<SellerReturnProfileEbay>;
  sellerPaymentProfile?: Maybe<SellerPaymentProfileEbay>;
  brand?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  length?: Maybe<ProductSizeEbay>;
  weight?: Maybe<ProductSizeEbay>;
  height?: Maybe<ProductSizeEbay>;
  width?: Maybe<ProductSizeEbay>;
  variations?: Maybe<Array<Maybe<VariationEbay>>>;
};

export enum ProductFilterCondition {
  IsEqualTo = 'isEqualTo',
  IsGreaterThan = 'isGreaterThan',
  IsLessThan = 'isLessThan',
  Contains = 'contains'
}

export enum ProductFilterKey {
  StockOnHand = 'stockOnHand',
  ExternalListingId = 'externalListingId',
  Name = 'name',
  Sku = 'sku',
  UnitPrice = 'unitPrice',
  UnitPriceExclGst = 'unitPriceExclGST'
}

export type ProductImagesOrderByInput = {
  orderNum: SortOrder;
};

export type ProductInput = {
  teamID: Scalars['String'];
  name: Scalars['String'];
  sku: Scalars['String'];
  shortDescription: Scalars['String'];
  fullDescription: Scalars['String'];
  prices: Array<TeamChannelPrice>;
  isForSale: Scalars['Boolean'];
  stockOnHand: Scalars['Int'];
  totalInventoryCost: TotalInventoryCost;
  attributeSetId: Scalars['String'];
  attributeSetName: Scalars['String'];
  images: Array<Scalars['String']>;
  specs: Array<SpecInput>;
  productTaxClassId?: Maybe<Scalars['String']>;
  settings: Array<Scalars['JSON']>;
  productType?: Maybe<Scalars['String']>;
  productProp?: Maybe<Scalars['JSON']>;
};

export type ProductResponse = BaseResponse & {
  __typename?: 'ProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
};

export type ProductsAdvancedFilterInput = {
  filterKey: ProductFilterKey;
  filterCondition: ProductFilterCondition;
  filterValue: Scalars['String'];
};

export type ProductSeed = {
  __typename?: 'ProductSeed';
  id: Scalars['ID'];
  name: Scalars['String'];
  stocks: Scalars['String'];
  pricingLevels: Scalars['String'];
  categoryName: Scalars['String'];
  apn: Scalars['String'];
  ean: Scalars['String'];
  brand: Scalars['String'];
  isESDProduct: Scalars['Boolean'];
  rrp: Scalars['Decimal'];
  shortDescription: Scalars['String'];
  upc: Scalars['String'];
  length: Scalars['Decimal'];
  moq: Scalars['Decimal'];
  sku: Scalars['String'];
  volume: Scalars['Decimal'];
  weight: Scalars['Decimal'];
  height: Scalars['Decimal'];
  width: Scalars['Decimal'];
};

export type ProductSeedsResponse = BaseResponse & {
  __typename?: 'ProductSeedsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  productSeeds?: Maybe<Array<Maybe<ProductSeed>>>;
};

export type ProductSizeEbay = {
  __typename?: 'ProductSizeEBAY';
  value?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type ProductsResponse = BaseResponse & {
  __typename?: 'ProductsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  products?: Maybe<Array<Product>>;
  teamTaxClasses: Array<ProductTaxClass>;
  total: Scalars['Int'];
  take: Scalars['Int'];
  skip: Scalars['Int'];
};

export type ProductTaxClass = {
  __typename?: 'ProductTaxClass';
  id: Scalars['ID'];
  name: Scalars['String'];
  isForShipping: Scalars['Boolean'];
  taxRules: Array<TaxRule>;
};

export type ProductTeamChannelProductsWhereInput = {
  active: BoolFilter;
};

export type ProductTypeDefineResponse = BaseResponse & {
  __typename?: 'ProductTypeDefineResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type ProductTypesResponse = BaseResponse & {
  __typename?: 'ProductTypesResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  productTypes?: Maybe<Scalars['String']>;
};

export type PullEbayOpenOrdersResponse = BaseResponse & {
  __typename?: 'PullEbayOpenOrdersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  numberOfOrdersCreated?: Maybe<Scalars['Int']>;
  numberOfCustomersCreated?: Maybe<Scalars['Int']>;
};

export type PushProductsToTeamChannelResponse = BaseResponse & {
  __typename?: 'PushProductsToTeamChannelResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type Query = {
  __typename?: 'Query';
  Me: User;
  GetUserByToken: GetUserByTokenResponse;
  ValidatePasswordResetLink: ValidatePasswordResetLinkResponse;
  GetUsersByTeam: GetUsersByTeamResponse;
  GetTeamById: GetTeamByIdResponse;
  GetTeamTaxClasses: GetTeamTaxClassesResponse;
  GetTeamSMTP: GetTeamSmtpResponse;
  GetTeamXeroAccountCode: GetTeamXeroAccountCodeResponse;
  GetTeamChargeAlive: GetTeamChargeAliveResponse;
  GetTeamChargeBillingsById: GetTeamChargeBillingsByIdResponse;
  GetTeamChargePlans: GetTeamChargePlansResponse;
  GetTeamChargePlanById: GetTeamChargePlanByIdResponse;
  GetTeamChannelsByTeamId: GetTeamChannelsByTeamIdResponse;
  GetTeamChannelById: GetTeamChannelByIdResponse;
  FindOrCreateMerchantLocation: EbayResponse;
  /** Query ebay selling limit */
  QueryMyEbaySelling: MyEbaySellingResponse;
  GetTcssByTeamId: GetTcssByTeamIdResponse;
  ping?: Maybe<Scalars['String']>;
  Product: ProductResponse;
  Products: ProductsResponse;
  QueryEbayListingsQty: QueryEbayListingsQtyResponse;
  GetSeedBySku: GetSeedBySkuResponse;
  ProductSeeds: ProductSeedsResponse;
  Orders: OrdersResponse;
  queryEbayOpenOrdersQty: QueryNumberOfEbayOrdersResponse;
  OrdersByCustomer: OrdersByCustomerResponse;
  GetOrderById: GetOrderByIdResponse;
  GetOrderStatus: GetOrderStatusResponse;
  GetCouriers: GetCouriersResponse;
  TeamCustomerDetail: TeamCustomerResponse;
  TeamCustomers: TeamCustomersResponse;
  TeamCustomerFeedBack: FeedBackResponse;
  QuerySummaryReport: SummaryResponse;
  QueryTopSKUSalesReport: TopSkuSalesResponse;
  QueryTopCustomerSalesReport: TopCustomerSalesResponse;
  TeamChannelSummary: TeamChannelSummaryResponse;
  TeamCustomerComments: TeamCustomerCommentsResponse;
  GetTeamCouriers: GetTeamCouriersResponse;
  GetTeamCourier: GetTeamCourierResponse;
  AuspostGetAccounts: AuspostGetAccountsResponse;
  AuspostGetQuote: AuspostGetQuoteResponse;
  GetTodayShipmentOrders: GetTodayShipmentOrdersResponse;
  Logs: LogsResponse;
  GetPaymentMethods: GetPaymentMethodsResponse;
  TicketsByTeam: TicketsResponse;
  getTicketComments: TicketCommentsResponse;
  FastwayGetBalance: FastwayGetBalanceResponse;
  FastwayGetQuote: FastWayGetQuoteResponse;
  /**
   * e.g. QueryEbay(resource: "catalog.search", params: [{value: {q: "led"}}]),
   * QueryEbay(resource: "taxonomy.getItemAspectsForCategory", params: [{value:
   * "15"}, { value: {category_id: "80077"} }]), QueryEbay(resource:
   * "catalog.getProduct", params: [{value: "26034980475"}] ), QueryEbay(resource:
   * "taxonomy.getCategorySuggestions", params: [{value: "15"}, {value: {q:
   * "airpods pro"}}] )
   */
  QueryEbay: EbayResponse;
  QueryProductTypes: ProductTypesResponse;
  QueryProductTypeDefine: ProductTypeDefineResponse;
  EbayGetCategorySuggestions: EbayGetCategorySuggestionsResponse;
  EbayRefreshAccessToken: EbayRefreshAccessTokenResponse;
  EbayGetCategoryAspects: EbayGetCategoryAspectsResponse;
  EbayGetExistingProducts: EbayGetExistingProductsResponse;
  EbayGetOrders: EbayGetOrdersResponse;
  EbayGetOutOfStockStatus: EbayGetOutOfStockStatusResponse;
  EbayGetShipments: EbayGetShipmentsResponse;
  EbayGetCategorySubtree: EbayGetCategorySubtreeResponse;
  EbayGetCategoryTree: EbayGetCategoryTreeResponse;
};


export type QueryGetUserByTokenArgs = {
  userId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryValidatePasswordResetLinkArgs = {
  userId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryGetUsersByTeamArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamByIdArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamTaxClassesArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamSmtpArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamXeroAccountCodeArgs = {
  teamId: Scalars['String'];
};

export type QueryGetTeamChargeAliveArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamChargeBillingsByIdArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamChargePlanByIdArgs = {
  teamChargePlanId: Scalars['String'];
};


export type QueryGetTeamChannelsByTeamIdArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamChannelByIdArgs = {
  teamChannelId: Scalars['String'];
};


export type QueryFindOrCreateMerchantLocationArgs = {
  teamChannelId: Scalars['String'];
};


export type QueryQueryMyEbaySellingArgs = {
  teamChannelId: Scalars['String'];
};


export type QueryGetTcssByTeamIdArgs = {
  teamId: Scalars['String'];
};


export type QueryProductArgs = {
  teamID: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryProductsArgs = {
  teamID: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: Maybe<Scalars['String']>;
  sortKey?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  productIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<ProductsAdvancedFilterInput>>>;
};


export type QueryQueryEbayListingsQtyArgs = {
  teamChannelID: Scalars['String'];
};


export type QueryGetSeedBySkuArgs = {
  sku: Scalars['String'];
};


export type QueryProductSeedsArgs = {
  keyword: Scalars['String'];
};


export type QueryOrdersArgs = {
  teamID: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortKey?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  isXeroSynced?: Maybe<Scalars['String']>;
  pinShipByOrder?: Maybe<Scalars['Boolean']>;
};


export type QueryQueryEbayOpenOrdersQtyArgs = {
  teamChannelID: Scalars['String'];
};


export type QueryOrdersByCustomerArgs = {
  teamID: Scalars['String'];
  customerId: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryGetOrderByIdArgs = {
  orderId: Scalars['String'];
};


export type QueryGetOrderStatusArgs = {
  teamId: Scalars['String'];
};


export type QueryTeamCustomerDetailArgs = {
  customerId: Scalars['String'];
};


export type QueryTeamCustomersArgs = {
  teamID: Scalars['String'];
  searchTxt?: Maybe<Scalars['String']>;
  sortArg?: Maybe<CustomerSortInput>;
  dateRange?: Maybe<Scalars['String']>;
  teamChannelIds?: Maybe<Array<Scalars['String']>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type QueryTeamCustomerFeedBackArgs = {
  teamID: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryQuerySummaryReportArgs = {
  teamId: Scalars['String'];
};


export type QueryQueryTopSkuSalesReportArgs = {
  teamId: Scalars['String'];
  dateStart: Scalars['String'];
  dateEnd: Scalars['String'];
};


export type QueryQueryTopCustomerSalesReportArgs = {
  teamId: Scalars['String'];
  dateStart: Scalars['String'];
  dateEnd: Scalars['String'];
};


export type QueryTeamChannelSummaryArgs = {
  teamChannelId: Scalars['String'];
};


export type QueryTeamCustomerCommentsArgs = {
  teamID: Scalars['String'];
  customerId: Scalars['String'];
};


export type QueryGetTeamCouriersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamCourierArgs = {
  teamId: Scalars['String'];
  courierId: Scalars['String'];
};


export type QueryAuspostGetAccountsArgs = {
  teamId: Scalars['String'];
  key: Scalars['String'];
  password: Scalars['String'];
  account: Scalars['String'];
};


export type QueryAuspostGetQuoteArgs = {
  itemsInput?: Maybe<ItemsInput>;
};


export type QueryGetTodayShipmentOrdersArgs = {
  teamId: Scalars['String'];
};


export type QueryLogsArgs = {
  teamId: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  tableId?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryTicketsByTeamArgs = {
  teamID: Scalars['String'];
  searchTxt?: Maybe<Scalars['String']>;
  sortKey?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  pageNum: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryGetTicketCommentsArgs = {
  teamID: Scalars['String'];
  ticketId: Scalars['String'];
};


export type QueryFastwayGetBalanceArgs = {
  teamId: Scalars['String'];
};


export type QueryFastwayGetQuoteArgs = {
  teamId: Scalars['String'];
  consignment: FastwayConsignmentInput;
};


export type QueryQueryEbayArgs = {
  resource: Scalars['String'];
  params: Array<Maybe<Scalars['JSON']>>;
  teamChannelId?: Maybe<Scalars['String']>;
};


export type QueryQueryProductTypesArgs = {
  keyword: Scalars['String'];
  teamId: Scalars['String'];
};


export type QueryQueryProductTypeDefineArgs = {
  productType: Scalars['String'];
  teamId: Scalars['String'];
};


export type QueryEbayGetCategorySuggestionsArgs = {
  marketPlace: Scalars['String'];
  keywords: Scalars['String'];
};


export type QueryEbayRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QueryEbayGetCategoryAspectsArgs = {
  categoryId: Scalars['String'];
  marketPlace: Scalars['String'];
};


export type QueryEbayGetExistingProductsArgs = {
  accessToken: Scalars['String'];
  pageSize: Scalars['Int'];
  currPageNum: Scalars['Int'];
};


export type QueryEbayGetOrdersArgs = {
  accessToken: Scalars['String'];
  lastModifiedDate: Scalars['String'];
  country: Scalars['String'];
};


export type QueryEbayGetOutOfStockStatusArgs = {
  accessToken: Scalars['String'];
};


export type QueryEbayGetShipmentsArgs = {
  accessToken: Scalars['String'];
  orderId: Scalars['String'];
};


export type QueryEbayGetCategorySubtreeArgs = {
  categoryId: Scalars['String'];
  marketPlace: Scalars['String'];
};


export type QueryEbayGetCategoryTreeArgs = {
  marketPlace: Scalars['String'];
};

export type QueryEbayListingsQtyResponse = BaseResponse & {
  __typename?: 'queryEbayListingsQtyResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  numberOfListings?: Maybe<Scalars['Int']>;
};

export type QueryNumberOfEbayOrdersResponse = BaseResponse & {
  __typename?: 'queryNumberOfEbayOrdersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  numberOfOrders?: Maybe<Scalars['Int']>;
};

export type RequestPasswordResetResponse = BaseResponse & {
  __typename?: 'RequestPasswordResetResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  email?: Maybe<Scalars['String']>;
};

export type ResendSignupActivationResponse = BaseResponse & {
  __typename?: 'ResendSignupActivationResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
};

export type ResetPasswordResponse = BaseResponse & {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type ScheduleJob = {
  __typename?: 'ScheduleJob';
  id: Scalars['ID'];
  type: Scalars['String'];
  subType: Scalars['String'];
  status: Scalars['String'];
  teamChannelId: Scalars['String'];
  message: Scalars['String'];
  lastModifiedDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SellerPaymentProfileEbay = {
  __typename?: 'SellerPaymentProfileEBAY';
  paymentProfileID?: Maybe<Scalars['String']>;
  paymentProfileName?: Maybe<Scalars['String']>;
};

export type SellerReturnProfileEbay = {
  __typename?: 'SellerReturnProfileEBAY';
  returnProfileID?: Maybe<Scalars['String']>;
  returnProfileName?: Maybe<Scalars['String']>;
};

export type SellerShippingProfileEbay = {
  __typename?: 'SellerShippingProfileEBAY';
  shippingProfileID?: Maybe<Scalars['String']>;
  shippingProfileName?: Maybe<Scalars['String']>;
};

export type SendInvoiceEmailInput = {
  orderId: Scalars['String'];
  emailTo: Array<Scalars['String']>;
  subject: Scalars['String'];
  body: Scalars['String'];
};

export type SendInvoiceEmailResponse = BaseResponse & {
  __typename?: 'SendInvoiceEmailResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type SetTeamCourierResponse = BaseResponse & {
  __typename?: 'SetTeamCourierResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCourier: TeamCourier;
};

export type SetTeamDefaultTaxClassResponse = BaseResponse & {
  __typename?: 'SetTeamDefaultTaxClassResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type SetTeamSmtpResponse = BaseResponse & {
  __typename?: 'SetTeamSMTPResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type SetTeamXeroAccountCodeResponse = BaseResponse & {
  __typename?: 'SetTeamXeroAccountCodeResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type Shipment = {
  __typename?: 'Shipment';
  id: Scalars['ID'];
  orderId: Scalars['String'];
  trackNumber: Scalars['String'];
  courierId: Scalars['String'];
  courier: Courier;
  noOfBoxes: Scalars['Int'];
  totalCost: Scalars['Decimal'];
  totalWeight: Scalars['Decimal'];
  status: Scalars['String'];
  externalId: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  totalCostExTax: Scalars['Decimal'];
  externalRes: Scalars['String'];
  labelUrl: Scalars['String'];
};

export type ShipmentInput = {
  orderId: Scalars['String'];
  trackNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  courierId: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  labelUrl?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalRes?: Maybe<Scalars['String']>;
};

export type ShipmentOrder = {
  __typename?: 'ShipmentOrder';
  id: Scalars['ID'];
  courier: Courier;
  courierId: Scalars['String'];
  teamId: Scalars['String'];
  team: Team;
  shipments: Array<Shipment>;
  totalCost: Scalars['Decimal'];
  totalCostExTax: Scalars['Decimal'];
  totalTax: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SignupResponse = BaseResponse & {
  __typename?: 'SignupResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type SmtpSendCheckResponse = BaseResponse & {
  __typename?: 'SmtpSendCheckResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  checkSuccess?: Maybe<Scalars['Boolean']>;
  errorMsg?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Spec = {
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type SpecInput = {
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type SummaryResponse = BaseResponse & {
  __typename?: 'SummaryResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type SwapEBayProductResponse = BaseResponse & {
  __typename?: 'SwapEBayProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type SyncEbayProductsViaQueueResponse = BaseResponse & {
  __typename?: 'SyncEbayProductsViaQueueResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type SyncEbayStoreInfoResponse = BaseResponse & {
  __typename?: 'SyncEbayStoreInfoResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannel?: Maybe<TeamChannel>;
};

export type SyncProductsViaQueueResponse = BaseResponse & {
  __typename?: 'SyncProductsViaQueueResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type SystemSetting = {
  __typename?: 'SystemSetting';
  id: Scalars['ID'];
  name: Scalars['String'];
  settings: Scalars['String'];
};

export enum TaxAmountBaseEnum {
  Taxexclu = 'TAXEXCLU',
  Taxinclu = 'TAXINCLU',
  Taxfree = 'TAXFREE'
}

export type TaxRate = {
  __typename?: 'TaxRate';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  ratePercent: Scalars['Decimal'];
  country: Scalars['String'];
  state: Scalars['String'];
  postCodeRange: Scalars['String'];
  teamId: Scalars['String'];
};

export type TaxRule = {
  __typename?: 'TaxRule';
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  taxRates: Array<TaxRate>;
  teamId: Scalars['String'];
};

export type TcsSettingsInput = {
  consumerKey: Scalars['String'];
  consumerSecret: Scalars['String'];
  sourceIdentifier: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo: Scalars['String'];
  abn: Scalars['String'];
  description: Scalars['String'];
  addresses: Array<TeamAddress>;
  baseUrl: Scalars['String'];
  users: Array<TeamUser>;
  orderStatus: Array<OrderStatus>;
  teamChannels: Array<TeamChannel>;
  settings: Scalars['String'];
  xeroConnected: Scalars['Boolean'];
  xeroAccountCode: Scalars['String'];
  tenantName: Scalars['String'];
  teamChargePlanId: Scalars['String'];
  createdAt: Scalars['DateTime'];
};


export type TeamTeamChannelsArgs = {
  where?: Maybe<TeamTeamChannelsWhereInput>;
};

export type TeamAddress = {
  __typename?: 'TeamAddress';
  id: Scalars['ID'];
  address: Address;
  teamId: Scalars['String'];
  addressId: Scalars['String'];
};

export type TeamChannel = {
  __typename?: 'TeamChannel';
  id: Scalars['ID'];
  name: Scalars['String'];
  channelID: Scalars['String'];
  active: Scalars['Boolean'];
  team: Team;
  channel: Channel;
  onBoarded: Scalars['Boolean'];
  lastOrderSync: Scalars['DateTime'];
  settings: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  externalId: Scalars['String'];
  watermarkUrl: Scalars['String'];
};

export type TeamChannelPrice = {
  teamChannelId: Scalars['String'];
  unitPrice: Scalars['Float'];
  channelId?: Maybe<Scalars['String']>;
  /** If not explicitly set it to false, the default value is true. */
  active?: Maybe<Scalars['Boolean']>;
};

export type TeamChannelProduct = {
  __typename?: 'TeamChannelProduct';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  status: Scalars['String'];
  unitPrice: Scalars['Decimal'];
  stockOnHand: Scalars['Int'];
  errorMsg: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  teamChannel: TeamChannel;
  externalListingId: Scalars['String'];
  settings: Scalars['String'];
};

export type TeamChannelSummaryResponse = BaseResponse & {
  __typename?: 'TeamChannelSummaryResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  numberOfProducts?: Maybe<Scalars['Int']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  numberOfOrders?: Maybe<Scalars['Int']>;
};

export type TeamChannelSupplier = {
  __typename?: 'TeamChannelSupplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  team: Team;
  onBoarded: Scalars['Boolean'];
  lastProductSync: Scalars['DateTime'];
  settings: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TeamCharge = {
  __typename?: 'TeamCharge';
  id: Scalars['ID'];
  teamChannelId: Scalars['String'];
  status: Scalars['String'];
  chargeType: Scalars['Int'];
  activeEndAt: Scalars['DateTime'];
  monthPlan: Scalars['Boolean'];
  teamChargePlanId: Scalars['String'];
};

export type TeamChargeBilling = {
  __typename?: 'TeamChargeBilling';
  id: Scalars['ID'];
  amount: Scalars['Decimal'];
  paid: Scalars['Boolean'];
  invNumber: Scalars['String'];
  invDate?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
};

export type TeamChargePlan = {
  __typename?: 'TeamChargePlan';
  id: Scalars['ID'];
  title: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['Decimal'];
  planType: Scalars['Int'];
};

export type TeamCourier = {
  __typename?: 'TeamCourier';
  id: Scalars['ID'];
  teamId: Scalars['String'];
  courierId: Scalars['String'];
  configuration: Scalars['String'];
  active: Scalars['Boolean'];
  createdById: Scalars['String'];
  updatedById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  info: Scalars['String'];
};

export type TeamCustomer = {
  __typename?: 'TeamCustomer';
  id: Scalars['ID'];
  teamID: Scalars['String'];
  channelID: Scalars['String'];
  createdViaTeamChannelID: Scalars['String'];
  externalCustomerID: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  company: Scalars['String'];
  fax: Scalars['String'];
  phone: Scalars['String'];
  mobile: Scalars['String'];
  orders: Array<Order>;
  teamCustomerComments: Array<TeamCustomerComment>;
  billingAddresses: Array<TeamCustomerBillingAddr>;
  shippingAddresses: Array<TeamCustomerShippingAddr>;
  team: Team;
  teamChannel: TeamChannel;
  totalExpense: Scalars['Decimal'];
  purchases: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type TeamCustomerBillingAddr = {
  __typename?: 'TeamCustomerBillingAddr';
  id: Scalars['ID'];
  addressID: Scalars['String'];
  teamCustomerID: Scalars['String'];
  address: Address;
  teamCustomer: TeamCustomer;
};

export type TeamCustomerComment = {
  __typename?: 'TeamCustomerComment';
  id: Scalars['ID'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  teamCustomer: TeamCustomer;
};

export type TeamCustomerCommentsResponse = BaseResponse & {
  __typename?: 'TeamCustomerCommentsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  comments?: Maybe<Array<TeamCustomerComment>>;
};

export type TeamCustomerResponse = BaseResponse & {
  __typename?: 'TeamCustomerResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCustomer?: Maybe<TeamCustomer>;
};

export type TeamCustomerShippingAddr = {
  __typename?: 'TeamCustomerShippingAddr';
  id: Scalars['ID'];
  addressID: Scalars['String'];
  teamCustomerID: Scalars['String'];
  address: Address;
  teamCustomer: TeamCustomer;
};

export type TeamCustomersResponse = BaseResponse & {
  __typename?: 'TeamCustomersResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCustomers?: Maybe<Array<TeamCustomer>>;
  teamChannels?: Maybe<Array<Maybe<TeamChannel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TeamInput = {
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  abn: Scalars['String'];
  description: Scalars['String'];
  baseUrl?: Maybe<Scalars['String']>;
};

export type TeamSmtp = {
  __typename?: 'TeamSMTP';
  id: Scalars['ID'];
  host: Scalars['String'];
  port: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  secure: Scalars['Boolean'];
};

export type TeamSmtpInput = {
  host: Scalars['String'];
  port: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type TeamTeamChannelsWhereInput = {
  active: BoolFilter;
};

export type TeamUser = {
  __typename?: 'TeamUser';
  id: Scalars['ID'];
  team: Team;
  user: User;
};

export type Ticket = {
  __typename?: 'Ticket';
  id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  requester?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type TicketCommentsResponse = BaseResponse & {
  __typename?: 'TicketCommentsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  requester: Scalars['String'];
  assignee: Scalars['String'];
  comments: Array<Comment>;
  assigneeId: Scalars['String'];
};

export type TicketsResponse = BaseResponse & {
  __typename?: 'TicketsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TopCustomerSalesResponse = BaseResponse & {
  __typename?: 'TopCustomerSalesResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type TopSkuSalesResponse = BaseResponse & {
  __typename?: 'TopSKUSalesResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  response?: Maybe<Scalars['JSON']>;
};

export type TotalInventoryCost = {
  value: Scalars['Float'];
  isTaxIncluded: Scalars['Boolean'];
};

export type UnlinkTeamResponse = BaseResponse & {
  __typename?: 'UnlinkTeamResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type UpdateAddressResponse = BaseResponse & {
  __typename?: 'UpdateAddressResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  address?: Maybe<Address>;
};

export type UpdateBulklProductsResponse = BaseResponse & {
  __typename?: 'UpdateBulklProductsResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  numberOfBulkProducts?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['String']>;
};

export type UpdateManualOrderInput = {
  orderId: Scalars['String'];
  orderItems: Array<OrderItemInputForUpdateOrder>;
  isAmountIncTax: Scalars['Boolean'];
};

export type UpdateManualOrderResponse = BaseResponse & {
  __typename?: 'UpdateManualOrderResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type UpdateOrderDiscountResponse = BaseResponse & {
  __typename?: 'UpdateOrderDiscountResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type UpdateOrderInput = {
  orderId: Scalars['String'];
  taxBase: Scalars['String'];
  orderItems: Array<OrderItemUpdateInput>;
};

export type UpdateOrderResponse = BaseResponse & {
  __typename?: 'UpdateOrderResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type UpdateProductResponse = BaseResponse & {
  __typename?: 'UpdateProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Scalars['JSON']>;
};

export type UpdateShippingCostResponse = BaseResponse & {
  __typename?: 'UpdateShippingCostResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type UpdateTeamByIdResponse = BaseResponse & {
  __typename?: 'UpdateTeamByIdResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  team?: Maybe<Team>;
};

export type UpdateTeamChannelProductResponse = BaseResponse & {
  __typename?: 'UpdateTeamChannelProductResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannelProduct?: Maybe<Scalars['JSON']>;
};

export type UpdateTeamChannelResponse = BaseResponse & {
  __typename?: 'UpdateTeamChannelResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamChannel?: Maybe<TeamChannel>;
};

export type UpdateTeamCustomerResponse = BaseResponse & {
  __typename?: 'UpdateTeamCustomerResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  teamCustomer?: Maybe<TeamCustomer>;
};

export type UpdateTicketInfoResponse = BaseResponse & {
  __typename?: 'UpdateTicketInfoResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type UpdateUserResponse = BaseResponse & {
  __typename?: 'UpdateUserResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  activated: Scalars['Boolean'];
  email: Scalars['String'];
  teams: Array<TeamUser>;
  language: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserActivationResponse = BaseResponse & {
  __typename?: 'UserActivationResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type ValidatePasswordResetLinkResponse = BaseResponse & {
  __typename?: 'ValidatePasswordResetLinkResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
};

export type Variation = {
  sku: Scalars['String'];
  stockOnHand: Scalars['Int'];
  unitPrice: Scalars['Int'];
  ean?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  variationSpecifics: Array<VariationSpecific>;
};

export type VariationEbay = {
  __typename?: 'VariationEBAY';
  sku?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  brand?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  variationSpecifics?: Maybe<Array<Maybe<VariationSpecificEbay>>>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  upc?: Maybe<Scalars['String']>;
};

export type VariationSpecific = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type VariationSpecificEbay = {
  __typename?: 'VariationSpecificEBAY';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Weight = {
  value: Scalars['Float'];
  unit: Scalars['String'];
};

export type XeroLoginResponse = BaseResponse & {
  __typename?: 'XeroLoginResponse';
  status: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
  accessToken?: Maybe<Scalars['String']>;
};

export type FullAddressFragmentFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'name' | 'company' | 'email' | 'phone' | 'fax' | 'mobile' | 'street' | 'suburb' | 'state' | 'country' | 'postcode' | 'comments'>
);

export type UpdateAddressMutationVariables = Exact<{
  addressId: Scalars['String'];
  addressParams: AddressParams;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { UpdateAddress: (
    { __typename?: 'UpdateAddressResponse' }
    & Pick<UpdateAddressResponse, 'status'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'email' | 'company' | 'name' | 'street' | 'state' | 'phone' | 'suburb' | 'postcode' | 'country'>
    )> }
  ) }
);

export type AuspostCreateLabelsMutationVariables = Exact<{
  teamId: Scalars['String'];
  shipmentIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type AuspostCreateLabelsMutation = (
  { __typename?: 'Mutation' }
  & { AuspostCreateLabels: (
    { __typename?: 'AuspostCreateLabelsResponse' }
    & Pick<AuspostCreateLabelsResponse, 'status' | 'response'>
  ) }
);

export type AuspostCreateShipmentMutationVariables = Exact<{
  createShip: CreateShip;
}>;


export type AuspostCreateShipmentMutation = (
  { __typename?: 'Mutation' }
  & { AuspostCreateShipment: (
    { __typename?: 'AuspostCreateShipmentResponse' }
    & Pick<AuspostCreateShipmentResponse, 'status' | 'response'>
  ) }
);

export type AuspostGetAccountQueryVariables = Exact<{
  teamId: Scalars['String'];
  key: Scalars['String'];
  password: Scalars['String'];
  account: Scalars['String'];
}>;


export type AuspostGetAccountQuery = (
  { __typename?: 'Query' }
  & { AuspostGetAccounts: (
    { __typename?: 'AuspostGetAccountsResponse' }
    & Pick<AuspostGetAccountsResponse, 'status' | 'response'>
  ) }
);

export type AuspostGetQuoteQueryVariables = Exact<{
  itemsInput: ItemsInput;
}>;


export type AuspostGetQuoteQuery = (
  { __typename?: 'Query' }
  & { AuspostGetQuote: (
    { __typename?: 'AuspostGetQuoteResponse' }
    & Pick<AuspostGetQuoteResponse, 'status' | 'response'>
  ) }
);

export type CancelTeamChargeMutationVariables = Exact<{
  teamId: Scalars['String'];
  teamChargeId: Scalars['String'];
}>;


export type CancelTeamChargeMutation = (
  { __typename?: 'Mutation' }
  & { CancelTeamCharge: (
    { __typename?: 'CancelTeamChargeResponse' }
    & Pick<CancelTeamChargeResponse, 'status'>
  ) }
);

export type CreateTeamChargeMutationVariables = Exact<{
  teamId: Scalars['String'];
  teamChannelId: Scalars['String'];
  chargeType: Scalars['Int'];
  cardNumber: Scalars['String'];
  expiryDate: Scalars['String'];
  cvc: Scalars['String'];
}>;


export type CreateTeamChargeMutation = (
  { __typename?: 'Mutation' }
  & { CreateTeamCharge: (
    { __typename?: 'CreateTeamChargeResponse' }
    & Pick<CreateTeamChargeResponse, 'status' | 'confirmationUrl' | 'chargeType' | 'accessToken' | 'chargeId'>
  ) }
);

export type CybersourceChargeSubMutationVariables = Exact<{
  teamId: Scalars['String'];
  chargeId: Scalars['String'];
  authenticationTransactionId: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  street: Scalars['String'];
  suburb: Scalars['String'];
  postcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  cardNumber: Scalars['String'];
  expiryDate: Scalars['String'];
  cvc: Scalars['String'];
  step: Scalars['Int'];
}>;


export type CybersourceChargeSubMutation = (
  { __typename?: 'Mutation' }
  & { CybersourceChargeSub: (
    { __typename?: 'CybersourceChargeSubResponse' }
    & Pick<CybersourceChargeSubResponse, 'status' | 'userCheckUrl' | 'accessToken' | 'checkStatus' | 'chargeId' | 'authenticationTransactionId'>
  ) }
);

export type GetTeamChargeAliveQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamChargeAliveQuery = (
  { __typename?: 'Query' }
  & { GetTeamChargeAlive: (
    { __typename?: 'GetTeamChargeAliveResponse' }
    & Pick<GetTeamChargeAliveResponse, 'status'>
    & { teamCharge?: Maybe<(
      { __typename?: 'TeamCharge' }
      & Pick<TeamCharge, 'id' | 'teamChannelId' | 'status' | 'chargeType' | 'activeEndAt' | 'monthPlan' | 'teamChargePlanId'>
    )> }
  ) }
);

export type GetTeamChargeBillingsByIdQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamChargeBillingsByIdQuery = (
  { __typename?: 'Query' }
  & { GetTeamChargeBillingsById: (
    { __typename?: 'GetTeamChargeBillingsByIdResponse' }
    & Pick<GetTeamChargeBillingsByIdResponse, 'status'>
    & { billings?: Maybe<Array<(
      { __typename?: 'TeamChargeBilling' }
      & Pick<TeamChargeBilling, 'id' | 'paid' | 'invNumber' | 'invDate' | 'amount' | 'title'>
    )>> }
  ) }
);

export type GetTeamChargePlanByIdQueryVariables = Exact<{
  teamChargePlanId: Scalars['String'];
}>;


export type GetTeamChargePlanByIdQuery = (
  { __typename?: 'Query' }
  & { GetTeamChargePlanById: (
    { __typename?: 'GetTeamChargePlanByIdResponse' }
    & Pick<GetTeamChargePlanByIdResponse, 'status'>
    & { teamChargePlan?: Maybe<(
      { __typename?: 'TeamChargePlan' }
      & Pick<TeamChargePlan, 'id' | 'title' | 'amount' | 'currency' | 'planType'>
    )> }
  ) }
);

export type GetTeamChargePlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamChargePlansQuery = (
  { __typename?: 'Query' }
  & { GetTeamChargePlans: (
    { __typename?: 'GetTeamChargePlansResponse' }
    & Pick<GetTeamChargePlansResponse, 'status'>
    & { teamChargePlans?: Maybe<Array<(
      { __typename?: 'TeamChargePlan' }
      & Pick<TeamChargePlan, 'id' | 'title' | 'amount' | 'currency'>
    )>> }
  ) }
);

export type InvoiceChargePdfCreationMutationVariables = Exact<{
  teamChargeBillingId: Scalars['String'];
}>;


export type InvoiceChargePdfCreationMutation = (
  { __typename?: 'Mutation' }
  & { InvoiceChargePdfCreation: (
    { __typename?: 'InvoiceChargePdfCreationResponse' }
    & Pick<InvoiceChargePdfCreationResponse, 'status' | 'url'>
  ) }
);

export type TeamChannelFragmentFragment = (
  { __typename?: 'TeamChannel' }
  & Pick<TeamChannel, 'id' | 'name' | 'externalId' | 'channelID' | 'onBoarded' | 'active' | 'lastOrderSync' | 'settings' | 'createdAt' | 'updatedAt'>
  & { channel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'name'>
  ), createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )>, updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )> }
);

export type CleanTeamChannelByIdMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type CleanTeamChannelByIdMutation = (
  { __typename?: 'Mutation' }
  & { CleanTeamChannelById: (
    { __typename?: 'CleanTeamChannelByIdResponse' }
    & Pick<CleanTeamChannelByIdResponse, 'status'>
  ) }
);

export type CreateTeamChannelMutationVariables = Exact<{
  teamId: Scalars['String'];
  name: Scalars['String'];
  channelId?: Maybe<Scalars['String']>;
  channelSettings?: Maybe<Scalars['String']>;
}>;


export type CreateTeamChannelMutation = (
  { __typename?: 'Mutation' }
  & { CreateTeamChannel: (
    { __typename?: 'CreateTeamChannelResponse' }
    & Pick<CreateTeamChannelResponse, 'status'>
    & { teamChannel: (
      { __typename?: 'TeamChannel' }
      & Pick<TeamChannel, 'id' | 'name' | 'active' | 'externalId' | 'settings' | 'onBoarded' | 'createdAt' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'id' | 'name'>
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    ) }
  ) }
);

export type CreateTeamChannelSupplierMutationVariables = Exact<{
  teamId: Scalars['String'];
  name: Scalars['String'];
  tcsSettings: TcsSettingsInput;
}>;


export type CreateTeamChannelSupplierMutation = (
  { __typename?: 'Mutation' }
  & { CreateTeamChannelSupplier: (
    { __typename?: 'CreateTeamChannelSupplierResponse' }
    & Pick<CreateTeamChannelSupplierResponse, 'status'>
    & { teamChannelSupplier: (
      { __typename?: 'TeamChannelSupplier' }
      & Pick<TeamChannelSupplier, 'id' | 'name' | 'settings' | 'onBoarded' | 'createdAt' | 'updatedAt' | 'lastProductSync'>
    ) }
  ) }
);

export type DeactivateTeamChannelMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type DeactivateTeamChannelMutation = (
  { __typename?: 'Mutation' }
  & { DeactivateTeamChannel: (
    { __typename?: 'DeactivatedTeamChannelResponse' }
    & Pick<DeactivatedTeamChannelResponse, 'status'>
  ) }
);

export type DeactivateTeamChannelSupplierMutationVariables = Exact<{
  teamChannelSupplierId: Scalars['String'];
}>;


export type DeactivateTeamChannelSupplierMutation = (
  { __typename?: 'Mutation' }
  & { DeactivateTeamChannelSupplier: (
    { __typename?: 'DeactivatedTeamChannelSupplierResponse' }
    & Pick<DeactivatedTeamChannelSupplierResponse, 'status'>
  ) }
);

export type SyncEbayStoreInfoMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type SyncEbayStoreInfoMutation = (
  { __typename?: 'Mutation' }
  & { SyncEbayStoreInfo: (
    { __typename?: 'SyncEbayStoreInfoResponse' }
    & Pick<SyncEbayStoreInfoResponse, 'status'>
    & { teamChannel?: Maybe<(
      { __typename?: 'TeamChannel' }
      & Pick<TeamChannel, 'id' | 'externalId' | 'name'>
    )> }
  ) }
);

export type UpdateTeamChannelMutationVariables = Exact<{
  teamChannelID: Scalars['String'];
  fulfillmentPolicyId?: Maybe<Scalars['String']>;
  fulfillmentPolicyName?: Maybe<Scalars['String']>;
  merchantLocationKey?: Maybe<Scalars['String']>;
  merchantLocationName?: Maybe<Scalars['String']>;
  paymentPolicyId?: Maybe<Scalars['String']>;
  paymentPolicyName?: Maybe<Scalars['String']>;
  returnPolicyId?: Maybe<Scalars['String']>;
  returnPolicyName?: Maybe<Scalars['String']>;
  pushStockQty?: Maybe<Scalars['Boolean']>;
  pushPriceUpdates?: Maybe<Scalars['Boolean']>;
  onBoarded?: Maybe<Scalars['Boolean']>;
  watermarkUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamChannelMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTeamChannel: (
    { __typename?: 'UpdateTeamChannelResponse' }
    & Pick<UpdateTeamChannelResponse, 'status'>
    & { teamChannel?: Maybe<(
      { __typename?: 'TeamChannel' }
      & Pick<TeamChannel, 'id' | 'onBoarded'>
    )> }
  ) }
);

export type GetTcssByTeamIdQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTcssByTeamIdQuery = (
  { __typename?: 'Query' }
  & { GetTcssByTeamId: (
    { __typename?: 'GetTcssByTeamIdResponse' }
    & Pick<GetTcssByTeamIdResponse, 'status'>
    & { teamChannelSuppliers: Array<(
      { __typename?: 'TeamChannelSupplier' }
      & Pick<TeamChannelSupplier, 'id' | 'name' | 'settings' | 'onBoarded' | 'createdAt' | 'updatedAt' | 'lastProductSync'>
    )> }
  ) }
);

export type GetTeamChannelByIdQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type GetTeamChannelByIdQuery = (
  { __typename?: 'Query' }
  & { GetTeamChannelById: (
    { __typename?: 'GetTeamChannelByIdResponse' }
    & Pick<GetTeamChannelByIdResponse, 'status'>
    & { teamChannel: (
      { __typename?: 'TeamChannel' }
      & Pick<TeamChannel, 'id' | 'name' | 'externalId' | 'onBoarded' | 'lastOrderSync' | 'settings' | 'createdAt' | 'updatedAt' | 'watermarkUrl'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'id' | 'name'>
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    ) }
  ) }
);

export type GetTeamChannelsByTeamIdQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamChannelsByTeamIdQuery = (
  { __typename?: 'Query' }
  & { GetTeamChannelsByTeamId: (
    { __typename?: 'GetTeamChannelsByTeamIdResponse' }
    & Pick<GetTeamChannelsByTeamIdResponse, 'status'>
    & { teamChannels: Array<(
      { __typename?: 'TeamChannel' }
      & TeamChannelFragmentFragment
    )> }
  ) }
);

export type CreateTeamCustomerCommentMutationVariables = Exact<{
  teamId: Scalars['String'];
  customerId: Scalars['String'];
  content: Scalars['String'];
}>;


export type CreateTeamCustomerCommentMutation = (
  { __typename?: 'Mutation' }
  & { CreateTeamCustomerComment: (
    { __typename?: 'CreateTeamCustomerCommentResponse' }
    & Pick<CreateTeamCustomerCommentResponse, 'status'>
    & { comment?: Maybe<(
      { __typename?: 'TeamCustomerComment' }
      & Pick<TeamCustomerComment, 'content' | 'createdAt'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  ) }
);

export type TeamCustomerCommentsQueryVariables = Exact<{
  teamID: Scalars['String'];
  customerId: Scalars['String'];
}>;


export type TeamCustomerCommentsQuery = (
  { __typename?: 'Query' }
  & { TeamCustomerComments: (
    { __typename?: 'TeamCustomerCommentsResponse' }
    & Pick<TeamCustomerCommentsResponse, 'status'>
    & { comments?: Maybe<Array<(
      { __typename?: 'TeamCustomerComment' }
      & Pick<TeamCustomerComment, 'id' | 'content' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )>> }
  ) }
);

export type SharedTeamCustomerFragmentFragment = (
  { __typename?: 'TeamCustomer' }
  & Pick<TeamCustomer, 'id' | 'name' | 'company' | 'email' | 'mobile' | 'phone' | 'totalExpense' | 'purchases' | 'createdAt'>
  & { shippingAddresses: Array<(
    { __typename?: 'TeamCustomerShippingAddr' }
    & Pick<TeamCustomerShippingAddr, 'id'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'name' | 'company' | 'email' | 'phone' | 'fax' | 'mobile' | 'street' | 'suburb' | 'state' | 'country' | 'postcode'>
    ) }
  )>, billingAddresses: Array<(
    { __typename?: 'TeamCustomerBillingAddr' }
    & Pick<TeamCustomerBillingAddr, 'id'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'name' | 'company' | 'email' | 'phone' | 'fax' | 'mobile' | 'street' | 'suburb' | 'state' | 'country' | 'postcode'>
    ) }
  )>, team: (
    { __typename?: 'Team' }
    & Pick<Team, 'name'>
  ), teamChannel: (
    { __typename?: 'TeamChannel' }
    & Pick<TeamChannel, 'name'>
    & { channel: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'name'>
    ) }
  ) }
);

export type CreateTeamCustomerMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
  teamCustomer: CreateTeamCustomerInput;
}>;


export type CreateTeamCustomerMutation = (
  { __typename?: 'Mutation' }
  & { CreateTeamCustomer: (
    { __typename?: 'CreateTeamCustomerResponse' }
    & Pick<CreateTeamCustomerResponse, 'status'>
    & { teamCustomer: (
      { __typename?: 'TeamCustomer' }
      & SharedTeamCustomerFragmentFragment
    ) }
  ) }
);

export type UpdateTeamCustomerMutationVariables = Exact<{
  customerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamCustomerMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTeamCustomer: (
    { __typename?: 'UpdateTeamCustomerResponse' }
    & Pick<UpdateTeamCustomerResponse, 'status'>
    & { teamCustomer?: Maybe<(
      { __typename?: 'TeamCustomer' }
      & Pick<TeamCustomer, 'id' | 'name' | 'email' | 'phone' | 'mobile'>
    )> }
  ) }
);

export type TcAutoCompleteQueryVariables = Exact<{
  teamID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;


export type TcAutoCompleteQuery = (
  { __typename?: 'Query' }
  & { TeamCustomers: (
    { __typename?: 'TeamCustomersResponse' }
    & Pick<TeamCustomersResponse, 'status' | 'total'>
    & { teamCustomers?: Maybe<Array<(
      { __typename?: 'TeamCustomer' }
      & Pick<TeamCustomer, 'id' | 'name' | 'email' | 'externalCustomerID'>
    )>> }
  ) }
);

export type TeamCustomerDetailQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type TeamCustomerDetailQuery = (
  { __typename?: 'Query' }
  & { TeamCustomerDetail: (
    { __typename?: 'TeamCustomerResponse' }
    & Pick<TeamCustomerResponse, 'status'>
    & { teamCustomer?: Maybe<(
      { __typename?: 'TeamCustomer' }
      & Pick<TeamCustomer, 'name' | 'email' | 'phone' | 'mobile' | 'externalCustomerID' | 'createdAt'>
      & { shippingAddresses: Array<(
        { __typename?: 'TeamCustomerShippingAddr' }
        & Pick<TeamCustomerShippingAddr, 'id'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'company' | 'name' | 'street' | 'state' | 'suburb' | 'postcode' | 'country'>
        ) }
      )>, billingAddresses: Array<(
        { __typename?: 'TeamCustomerBillingAddr' }
        & Pick<TeamCustomerBillingAddr, 'id'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'company' | 'name' | 'street' | 'state' | 'suburb' | 'postcode' | 'country'>
        ) }
      )> }
    )> }
  ) }
);

export type TeamCustomerFeedBackQueryVariables = Exact<{
  teamID: Scalars['String'];
  userId: Scalars['String'];
}>;


export type TeamCustomerFeedBackQuery = (
  { __typename?: 'Query' }
  & { TeamCustomerFeedBack: (
    { __typename?: 'FeedBackResponse' }
    & Pick<FeedBackResponse, 'score' | 'status'>
  ) }
);

export type TeamCustomersQueryVariables = Exact<{
  teamID: Scalars['String'];
  searchTxt?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Scalars['String']>;
  sortArg?: Maybe<CustomerSortInput>;
  teamChannelIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type TeamCustomersQuery = (
  { __typename?: 'Query' }
  & { TeamCustomers: (
    { __typename?: 'TeamCustomersResponse' }
    & Pick<TeamCustomersResponse, 'status' | 'total'>
    & { teamChannels?: Maybe<Array<Maybe<(
      { __typename?: 'TeamChannel' }
      & Pick<TeamChannel, 'id' | 'name'>
    )>>>, teamCustomers?: Maybe<Array<(
      { __typename?: 'TeamCustomer' }
      & SharedTeamCustomerFragmentFragment
    )>> }
  ) }
);

export type QueryEbayProductsQueryVariables = Exact<{
  q: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
}>;


export type QueryEbayProductsQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryEbayProductQueryVariables = Exact<{
  epid?: Maybe<Scalars['String']>;
}>;


export type QueryEbayProductQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryEbayCategoryFeaturesQueryVariables = Exact<{
  categoryId?: Maybe<Scalars['String']>;
}>;


export type QueryEbayCategoryFeaturesQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryFulfilmentPoliciesQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type QueryFulfilmentPoliciesQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type FindOrCreateLocationQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type FindOrCreateLocationQuery = (
  { __typename?: 'Query' }
  & { FindOrCreateMerchantLocation: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryMyEbaySellingQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type QueryMyEbaySellingQuery = (
  { __typename?: 'Query' }
  & { QueryMyEbaySelling: (
    { __typename?: 'MyEbaySellingResponse' }
    & Pick<MyEbaySellingResponse, 'response'>
  ) }
);

export type QueryPaymentPoliciesQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type QueryPaymentPoliciesQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryReturnPoliciesQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type QueryReturnPoliciesQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryCategorySubtreeQueryVariables = Exact<{
  categoryId: Scalars['String'];
}>;


export type QueryCategorySubtreeQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryEbayCategorySuggestionsQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type QueryEbayCategorySuggestionsQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type QueryEbayCategoryAspectsQueryVariables = Exact<{
  categoryId: Scalars['String'];
}>;


export type QueryEbayCategoryAspectsQuery = (
  { __typename?: 'Query' }
  & { QueryEbay: (
    { __typename?: 'EbayResponse' }
    & Pick<EbayResponse, 'response'>
  ) }
);

export type FastwayCreateLabelMutationVariables = Exact<{
  teamId: Scalars['String'];
  conId: Scalars['String'];
}>;


export type FastwayCreateLabelMutation = (
  { __typename?: 'Mutation' }
  & { FastwayCreateLabel: (
    { __typename?: 'FastwayCreateLabelResponse' }
    & Pick<FastwayCreateLabelResponse, 'status' | 'url'>
  ) }
);

export type FastwayCreateShipmentMutationVariables = Exact<{
  teamId: Scalars['String'];
  consignment: FastwayConsignmentInput;
}>;


export type FastwayCreateShipmentMutation = (
  { __typename?: 'Mutation' }
  & { FastwayCreateShipment: (
    { __typename?: 'FastwayCreateConsignmentResponse' }
    & Pick<FastwayCreateConsignmentResponse, 'status' | 'conId' | 'externalResponse' | 'trackingNumber'>
  ) }
);

export type FastwayGetQuoteQueryVariables = Exact<{
  teamId: Scalars['String'];
  consignment: FastwayConsignmentInput;
}>;


export type FastwayGetQuoteQuery = (
  { __typename?: 'Query' }
  & { FastwayGetQuote: (
    { __typename?: 'FastWayGetQuoteResponse' }
    & Pick<FastWayGetQuoteResponse, 'status'>
    & { quote?: Maybe<(
      { __typename?: 'FastwayGetQuote' }
      & Pick<FastwayGetQuote, 'priceInc' | 'description' | 'packageType' | 'satchelSize' | 'courierId'>
    )> }
  ) }
);

export type AddLogMutationVariables = Exact<{
  teamId: Scalars['String'];
  tableId: Scalars['String'];
  tableName: LogTableName;
  content: Scalars['String'];
}>;


export type AddLogMutation = (
  { __typename?: 'Mutation' }
  & { AddLog: (
    { __typename?: 'LogResponse' }
    & Pick<LogResponse, 'status'>
    & { log: (
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'teamId' | 'tableId' | 'tableName' | 'content' | 'type' | 'active' | 'createdAt' | 'updatedAt' | 'comments'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'lastName' | 'firstName'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'lastName' | 'firstName'>
      )> }
    ) }
  ) }
);

export type DeleteLogMutationVariables = Exact<{
  logId: Scalars['String'];
}>;


export type DeleteLogMutation = (
  { __typename?: 'Mutation' }
  & { DeleteLog: (
    { __typename?: 'LogResponse' }
    & Pick<LogResponse, 'status'>
    & { log: (
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'active'>
    ) }
  ) }
);

export type EditLogMutationVariables = Exact<{
  logId: Scalars['String'];
  content: Scalars['String'];
}>;


export type EditLogMutation = (
  { __typename?: 'Mutation' }
  & { EditLog: (
    { __typename?: 'LogResponse' }
    & Pick<LogResponse, 'status'>
    & { log: (
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'content' | 'updatedAt'>
    ) }
  ) }
);

export type LogsQueryVariables = Exact<{
  teamId: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  tableName?: Maybe<Scalars['String']>;
  tableId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}>;


export type LogsQuery = (
  { __typename?: 'Query' }
  & { Logs: (
    { __typename?: 'LogsResponse' }
    & Pick<LogsResponse, 'status' | 'total' | 'skip' | 'take'>
    & { logs: Array<(
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'active' | 'content' | 'createdAt' | 'updatedAt' | 'comments'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);

export type OrderItemFragmentFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'productID' | 'teamChannelProductID' | 'sku' | 'name' | 'description' | 'unitPrice' | 'unitPriceExclGST' | 'qty' | 'discountAmount' | 'totalPrice' | 'taxAmt' | 'taxRate' | 'createdAt' | 'updatedAt' | 'totalAmtInclTax' | 'shipByDate' | 'productTaxClassId'>
);

export type SharedOrderFragmentFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'amountBase' | 'teamChannelID' | 'teamID' | 'channelID' | 'teamCustomerID' | 'shippingAddrID' | 'billingAddrID' | 'marketPlaceFee' | 'shippingCarrierCode' | 'shipByDate' | 'paymentStatus' | 'currency' | 'orderNumber' | 'legacyOrderId' | 'totalAmount' | 'totalAmtInclTax' | 'paidAmount' | 'totalPaidAmt' | 'comments' | 'totalTaxAmt' | 'totalShippingAmt' | 'shippingTaxAmt' | 'shippingServiceCode' | 'preferPaymentMethod' | 'totalShippingAmtInclTax' | 'shippingTaxRate' | 'totalDiscountAmt' | 'orderDiscountAmt' | 'createdAt' | 'updatedAt' | 'lastModifiedDate'>
  & { shippingAddress: (
    { __typename?: 'Address' }
    & FullAddressFragmentFragment
  ), billingAddress: (
    { __typename?: 'Address' }
    & FullAddressFragmentFragment
  ), status: (
    { __typename?: 'OrderStatus' }
    & FullOrderStatusFragmentFragment
  ), payments: Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'orderId' | 'currency' | 'paidAmount' | 'createdAt'>
    & { paymentMethod: (
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'name'>
    ), paymentCreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )>, paidBy: (
      { __typename?: 'TeamCustomer' }
      & Pick<TeamCustomer, 'name'>
    ) }
  )>, orderItems: Array<(
    { __typename?: 'OrderItem' }
    & OrderItemFragmentFragment
  )>, shipments: Array<(
    { __typename?: 'Shipment' }
    & ShipmentFragmentFragment
  )>, teamChannel: (
    { __typename?: 'TeamChannel' }
    & TeamChannelFragmentFragment
  ), channel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'name'>
  ), teamCustomer: (
    { __typename?: 'TeamCustomer' }
    & SharedTeamCustomerFragmentFragment
  ) }
);

export type ShipmentFragmentFragment = (
  { __typename?: 'Shipment' }
  & Pick<Shipment, 'id' | 'active' | 'orderId' | 'trackNumber' | 'courierId' | 'noOfBoxes' | 'totalCost' | 'totalWeight' | 'status' | 'externalId' | 'labelUrl' | 'externalRes' | 'createdAt' | 'updatedAt'>
  & { courier: (
    { __typename?: 'Courier' }
    & Pick<Courier, 'id' | 'name'>
  ) }
);

export type CancelOrderMutationVariables = Exact<{
  cancelOrder: CancelOrderInput;
}>;


export type CancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { CancelOrder: (
    { __typename?: 'CancelOrderResponse' }
    & Pick<CancelOrderResponse, 'status'>
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
  data: CreateOrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { CreateOrder: (
    { __typename?: 'CreateOrderResponse' }
    & Pick<CreateOrderResponse, 'status'>
    & { order: (
      { __typename?: 'Order' }
      & SharedOrderFragmentFragment
    ) }
  ) }
);

export type CreateXeroInvoicesMutationVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type CreateXeroInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { CreateXeroInvoices: (
    { __typename?: 'CreateXeroInvoicesResponse' }
    & Pick<CreateXeroInvoicesResponse, 'status'>
  ) }
);

export type CreateXeroInvoiceMutationVariables = Exact<{
  orderId: Scalars['String'];
  teamID: Scalars['String'];
}>;


export type CreateXeroInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { CreateXeroInvoice: (
    { __typename?: 'CreateXeroInvoiceResponse' }
    & Pick<CreateXeroInvoiceResponse, 'status'>
  ) }
);

export type DeleteShipmentMutationVariables = Exact<{
  shipmentId: Scalars['String'];
}>;


export type DeleteShipmentMutation = (
  { __typename?: 'Mutation' }
  & { DeleteShipment: (
    { __typename?: 'DeleteShipmentResponse' }
    & Pick<DeleteShipmentResponse, 'status'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & Pick<Shipment, 'id' | 'active' | 'orderId' | 'trackNumber' | 'courierId' | 'noOfBoxes' | 'totalCost' | 'totalWeight' | 'status' | 'externalId' | 'labelUrl' | 'externalRes' | 'createdAt' | 'updatedAt'>
      & { courier: (
        { __typename?: 'Courier' }
        & Pick<Courier, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ExportOrdersMutationVariables = Exact<{
  teamId: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
}>;


export type ExportOrdersMutation = (
  { __typename?: 'Mutation' }
  & { ExportOrders: (
    { __typename?: 'ExportOrdersResponse' }
    & Pick<ExportOrdersResponse, 'downloadUrl' | 'status'>
  ) }
);

export type InvoicePdfCreationMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type InvoicePdfCreationMutation = (
  { __typename?: 'Mutation' }
  & { InvoicePdfCreation: (
    { __typename?: 'InvoicePdfCreationResponse' }
    & Pick<InvoicePdfCreationResponse, 'status' | 'url'>
  ) }
);

export type MagentoOrderMarkAsPaidMutationVariables = Exact<{
  markAsPaid: MagentoOrderMarkAsPaidInput;
}>;


export type MagentoOrderMarkAsPaidMutation = (
  { __typename?: 'Mutation' }
  & { magentoOrderMarkAsPaid: (
    { __typename?: 'MagentoOrderMarkAsPaidResponse' }
    & Pick<MagentoOrderMarkAsPaidResponse, 'status'>
  ) }
);

export type PullEbayOpenOrdersMutationVariables = Exact<{
  teamChannelID: Scalars['String'];
}>;


export type PullEbayOpenOrdersMutation = (
  { __typename?: 'Mutation' }
  & { PullEbayOpenOrders: (
    { __typename?: 'PullEbayOpenOrdersResponse' }
    & Pick<PullEbayOpenOrdersResponse, 'status' | 'numberOfOrdersCreated' | 'numberOfCustomersCreated'>
  ) }
);

export type SendInvoiceEmailMutationMutationVariables = Exact<{
  args: SendInvoiceEmailInput;
}>;


export type SendInvoiceEmailMutationMutation = (
  { __typename?: 'Mutation' }
  & { SendInvoiceEmailMutation: (
    { __typename?: 'SendInvoiceEmailResponse' }
    & Pick<SendInvoiceEmailResponse, 'status'>
  ) }
);

export type UpdateManualOrderMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
  data: UpdateManualOrderInput;
}>;


export type UpdateManualOrderMutation = (
  { __typename?: 'Mutation' }
  & { UpdateManualOrder: (
    { __typename?: 'UpdateManualOrderResponse' }
    & Pick<UpdateManualOrderResponse, 'status'>
  ) }
);

export type UpdateOrderMutationMutationVariables = Exact<{
  order: UpdateOrderInput;
}>;


export type UpdateOrderMutationMutation = (
  { __typename?: 'Mutation' }
  & { UpdateOrderMutation: (
    { __typename?: 'UpdateOrderResponse' }
    & Pick<UpdateOrderResponse, 'status'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'totalAmount' | 'totalAmtInclTax' | 'totalTaxAmt' | 'amountBase'>
      & { orderItems: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'unitPriceExclGST' | 'totalPrice' | 'taxAmt' | 'taxRate' | 'productTaxClassId' | 'totalAmtInclTax'>
      )> }
    )> }
  ) }
);

export type UpdateOrderDiscountMutationVariables = Exact<{
  discount: OrderDiscountInput;
}>;


export type UpdateOrderDiscountMutation = (
  { __typename?: 'Mutation' }
  & { UpdateOrderDiscount: (
    { __typename?: 'UpdateOrderDiscountResponse' }
    & Pick<UpdateOrderDiscountResponse, 'status'>
  ) }
);

export type UpdateShippingCostMutationVariables = Exact<{
  orderId: Scalars['String'];
  shippingServiceCode?: Maybe<Scalars['String']>;
  shippingCarrierCode?: Maybe<Scalars['String']>;
  totalShippingAmtInclTax: Scalars['Float'];
}>;


export type UpdateShippingCostMutation = (
  { __typename?: 'Mutation' }
  & { UpdateShippingCost: (
    { __typename?: 'UpdateShippingCostResponse' }
    & Pick<UpdateShippingCostResponse, 'status'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'shippingServiceCode' | 'shippingCarrierCode' | 'shippingTaxAmt' | 'shippingTaxRate' | 'totalShippingAmt' | 'totalShippingAmtInclTax' | 'totalAmount' | 'totalAmtInclTax' | 'totalTaxAmt'>
    )> }
  ) }
);

export type QueryEbayOpenOrdersQtyQueryVariables = Exact<{
  teamChannelID: Scalars['String'];
}>;


export type QueryEbayOpenOrdersQtyQuery = (
  { __typename?: 'Query' }
  & { queryEbayOpenOrdersQty: (
    { __typename?: 'queryNumberOfEbayOrdersResponse' }
    & Pick<QueryNumberOfEbayOrdersResponse, 'status' | 'numberOfOrders'>
  ) }
);

export type GetOrderByIdQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetOrderByIdQuery = (
  { __typename?: 'Query' }
  & { GetOrderById: (
    { __typename?: 'GetOrderByIdResponse' }
    & Pick<GetOrderByIdResponse, 'status'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & SharedOrderFragmentFragment
    )> }
  ) }
);

export type GetOrderStatusQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetOrderStatusQuery = (
  { __typename?: 'Query' }
  & { GetOrderStatus: (
    { __typename?: 'getOrderStatusResponse' }
    & Pick<GetOrderStatusResponse, 'status'>
    & { orderStatus?: Maybe<Array<(
      { __typename?: 'OrderStatus' }
      & Pick<OrderStatus, 'id' | 'name' | 'code' | 'order'>
    )>> }
  ) }
);

export type OrderAutoCompleteQueryVariables = Exact<{
  teamID: Scalars['String'];
  orderNumber?: Maybe<Scalars['String']>;
}>;


export type OrderAutoCompleteQuery = (
  { __typename?: 'Query' }
  & { Orders: (
    { __typename?: 'OrdersResponse' }
    & Pick<OrdersResponse, 'status' | 'total' | 'take' | 'skip'>
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'orderNumber'>
    )>> }
  ) }
);

export type OrdersQueryVariables = Exact<{
  teamID: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  status?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sortKey?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  isXeroSynced?: Maybe<Scalars['String']>;
  pinShipByOrder?: Maybe<Scalars['Boolean']>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { Orders: (
    { __typename?: 'OrdersResponse' }
    & Pick<OrdersResponse, 'status' | 'total' | 'take' | 'skip' | 'totalAmtInclTax' | 'totalOutstanding'>
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & SharedOrderFragmentFragment
    )>> }
  ) }
);

export type OrdersByCustomerQueryVariables = Exact<{
  teamID: Scalars['String'];
  customerId: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type OrdersByCustomerQuery = (
  { __typename?: 'Query' }
  & { OrdersByCustomer: (
    { __typename?: 'OrdersByCustomerResponse' }
    & Pick<OrdersByCustomerResponse, 'status' | 'total' | 'take' | 'skip'>
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'currency' | 'orderNumber' | 'totalAmtInclTax' | 'createdAt' | 'updatedAt'>
      & { status: (
        { __typename?: 'OrderStatus' }
        & Pick<OrderStatus, 'id' | 'name' | 'code' | 'order'>
      ) }
    )>> }
  ) }
);

export type FullOrderStatusFragmentFragment = (
  { __typename?: 'OrderStatus' }
  & Pick<OrderStatus, 'id' | 'name' | 'code' | 'order'>
);

export type CreatePaymentMutationVariables = Exact<{
  payment: PaymentInput;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'CreatePaymentResponse' }
    & Pick<CreatePaymentResponse, 'status'>
  ) }
);

export type DeletePaymentMutationVariables = Exact<{
  deletePayment: DeletePaymentInput;
}>;


export type DeletePaymentMutation = (
  { __typename?: 'Mutation' }
  & { deletePayment: (
    { __typename?: 'DeletePaymentResponse' }
    & Pick<DeletePaymentResponse, 'status'>
  ) }
);

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { GetPaymentMethods: (
    { __typename?: 'getPaymentMethodsResponse' }
    & Pick<GetPaymentMethodsResponse, 'status'>
    & { paymentMethods?: Maybe<Array<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'name'>
    )>> }
  ) }
);

export type AdjustInventoryMutationVariables = Exact<{
  productId: Scalars['String'];
  changeInQty: Scalars['Int'];
  costPerUnit?: Maybe<Scalars['Float']>;
  isTaxIncluded?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
}>;


export type AdjustInventoryMutation = (
  { __typename?: 'Mutation' }
  & { AdjustInventory: (
    { __typename?: 'AdjustInventoryResponse' }
    & Pick<AdjustInventoryResponse, 'status' | 'sohBeforeMutation' | 'totalInventoryValueBeforeMutation'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'stockOnHand' | 'totalInventoryCost'>
    )> }
  ) }
);

export type CreateBulkProductTemplateMutationVariables = Exact<{
  teamID: Scalars['String'];
}>;


export type CreateBulkProductTemplateMutation = (
  { __typename?: 'Mutation' }
  & { CreateBulkProductTemplate: (
    { __typename?: 'CreateBulkProductTemplateResponse' }
    & Pick<CreateBulkProductTemplateResponse, 'status' | 'url'>
  ) }
);

export type CreateBulkProductUpdateReportMutationVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type CreateBulkProductUpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { CreateBulkProductUpdateReport: (
    { __typename?: 'CreateBulkProductUpdateReportResponse' }
    & Pick<CreateBulkProductUpdateReportResponse, 'status' | 'url'>
  ) }
);

export type CreateProductMutationVariables = Exact<{
  product: ProductInput;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { CreateProduct: (
    { __typename?: 'CreateProductResponse' }
    & Pick<CreateProductResponse, 'status' | 'product'>
  ) }
);

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & { DeleteProduct: (
    { __typename?: 'DeleteProductResponse' }
    & Pick<DeleteProductResponse, 'status' | 'deletedId'>
  ) }
);

export type ExportProductsMutationVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type ExportProductsMutation = (
  { __typename?: 'Mutation' }
  & { ExportProducts: (
    { __typename?: 'ExportProductsResponse' }
    & Pick<ExportProductsResponse, 'status' | 'downloadUrl'>
  ) }
);

export type ImportEbayListingsMutationVariables = Exact<{
  teamChannelID: Scalars['String'];
}>;


export type ImportEbayListingsMutation = (
  { __typename?: 'Mutation' }
  & { ImportEbayListings: (
    { __typename?: 'ImportEbayListingsResponse' }
    & Pick<ImportEbayListingsResponse, 'status' | 'numberOfProductsCreated'>
  ) }
);

export type PushProductsToTeamChannelMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type PushProductsToTeamChannelMutation = (
  { __typename?: 'Mutation' }
  & { PushProductsToTeamChannel: (
    { __typename?: 'PushProductsToTeamChannelResponse' }
    & Pick<PushProductsToTeamChannelResponse, 'status'>
  ) }
);

export type SwapEBayProductMutationVariables = Exact<{
  sourceProductId: Scalars['String'];
  targetProductId: Scalars['String'];
  mode: Scalars['String'];
}>;


export type SwapEBayProductMutation = (
  { __typename?: 'Mutation' }
  & { SwapEBayProduct: (
    { __typename?: 'SwapEBayProductResponse' }
    & Pick<SwapEBayProductResponse, 'status'>
    & { errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type SyncEbayProductsViaQueueMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type SyncEbayProductsViaQueueMutation = (
  { __typename?: 'Mutation' }
  & { SyncEbayProductsViaQueue: (
    { __typename?: 'SyncEbayProductsViaQueueResponse' }
    & Pick<SyncEbayProductsViaQueueResponse, 'status'>
  ) }
);

export type SyncProductsViaQueueMutationVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type SyncProductsViaQueueMutation = (
  { __typename?: 'Mutation' }
  & { SyncProductsViaQueue: (
    { __typename?: 'SyncProductsViaQueueResponse' }
    & Pick<SyncProductsViaQueueResponse, 'status'>
  ) }
);

export type UpdateBulkProductsMutationVariables = Exact<{
  fileName: Scalars['String'];
  teamID: Scalars['String'];
}>;


export type UpdateBulkProductsMutation = (
  { __typename?: 'Mutation' }
  & { UpdateBulkProducts: (
    { __typename?: 'UpdateBulklProductsResponse' }
    & Pick<UpdateBulklProductsResponse, 'status' | 'jobId' | 'numberOfBulkProducts'>
  ) }
);

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['String'];
  product: ProductInput;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { UpdateProduct: (
    { __typename?: 'UpdateProductResponse' }
    & Pick<UpdateProductResponse, 'status' | 'product'>
  ) }
);

export type UpdateProductStockOnHandMutationVariables = Exact<{
  id: Scalars['String'];
  stockOnHand: Scalars['Int'];
}>;


export type UpdateProductStockOnHandMutation = (
  { __typename?: 'Mutation' }
  & { UpdateProductStockOnHand: (
    { __typename?: 'UpdateProductResponse' }
    & Pick<UpdateProductResponse, 'status' | 'product'>
  ) }
);

export type TeamChannelProductUpdateMutationVariables = Exact<{
  id: Scalars['String'];
  unitPrice: Scalars['Float'];
  stockOnHand: Scalars['Int'];
}>;


export type TeamChannelProductUpdateMutation = (
  { __typename?: 'Mutation' }
  & { TeamChannelProductUpdate: (
    { __typename?: 'UpdateTeamChannelProductResponse' }
    & Pick<UpdateTeamChannelProductResponse, 'status' | 'teamChannelProduct'>
  ) }
);

export type QueryEbayListingsQtyQueryVariables = Exact<{
  teamChannelID: Scalars['String'];
}>;


export type QueryEbayListingsQtyQuery = (
  { __typename?: 'Query' }
  & { QueryEbayListingsQty: (
    { __typename?: 'queryEbayListingsQtyResponse' }
    & Pick<QueryEbayListingsQtyResponse, 'status' | 'numberOfListings'>
  ) }
);

export type QueryProductTypeDefineQueryVariables = Exact<{
  productType: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type QueryProductTypeDefineQuery = (
  { __typename?: 'Query' }
  & { QueryProductTypeDefine: (
    { __typename?: 'ProductTypeDefineResponse' }
    & Pick<ProductTypeDefineResponse, 'status' | 'response'>
  ) }
);

export type GetSeedBySkuQueryVariables = Exact<{
  sku: Scalars['String'];
}>;


export type GetSeedBySkuQuery = (
  { __typename?: 'Query' }
  & { GetSeedBySku: (
    { __typename?: 'GetSeedBySkuResponse' }
    & Pick<GetSeedBySkuResponse, 'status'>
    & { productSeed?: Maybe<(
      { __typename?: 'ProductSeed' }
      & Pick<ProductSeed, 'id' | 'name' | 'stocks' | 'pricingLevels'>
    )> }
  ) }
);

export type ProductQueryVariables = Exact<{
  teamID: Scalars['String'];
  productId: Scalars['String'];
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { Product: (
    { __typename?: 'ProductResponse' }
    & Pick<ProductResponse, 'status'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku' | 'unitPrice' | 'isForSale' | 'stockOnHand' | 'totalInventoryCost' | 'fullDescription' | 'shortDescription' | 'fromChannelId' | 'teamID'>
      & { images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )>, productAttributeValues: Array<(
        { __typename?: 'ProductAttributeValue' }
        & Pick<ProductAttributeValue, 'id' | 'value'>
        & { productAttribute: (
          { __typename?: 'ProductAttribute' }
          & Pick<ProductAttribute, 'id' | 'name'>
        ) }
      )>, teamChannelProducts: Array<(
        { __typename?: 'TeamChannelProduct' }
        & Pick<TeamChannelProduct, 'id' | 'unitPrice' | 'active' | 'stockOnHand' | 'externalListingId' | 'settings'>
        & { teamChannel: (
          { __typename?: 'TeamChannel' }
          & Pick<TeamChannel, 'id' | 'active' | 'name' | 'channelID'>
        ) }
      )>, attributeSet: (
        { __typename?: 'AttributeSet' }
        & Pick<AttributeSet, 'id' | 'channelAttributeSetId'>
      ), productTaxClass?: Maybe<(
        { __typename?: 'ProductTaxClass' }
        & Pick<ProductTaxClass, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type ProductImageQueryVariables = Exact<{
  teamID: Scalars['String'];
  productId: Scalars['String'];
}>;


export type ProductImageQuery = (
  { __typename?: 'Query' }
  & { Product: (
    { __typename?: 'ProductResponse' }
    & Pick<ProductResponse, 'status'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
      & { images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )> }
    )> }
  ) }
);

export type ProductSeedsQueryVariables = Exact<{
  keyword: Scalars['String'];
}>;


export type ProductSeedsQuery = (
  { __typename?: 'Query' }
  & { ProductSeeds: (
    { __typename?: 'ProductSeedsResponse' }
    & Pick<ProductSeedsResponse, 'status'>
    & { productSeeds?: Maybe<Array<Maybe<(
      { __typename?: 'ProductSeed' }
      & Pick<ProductSeed, 'id' | 'name' | 'stocks' | 'pricingLevels' | 'categoryName' | 'apn' | 'ean' | 'brand' | 'isESDProduct' | 'rrp' | 'shortDescription' | 'upc' | 'length' | 'moq' | 'sku' | 'volume' | 'weight' | 'width'>
    )>>> }
  ) }
);

export type ProductsQueryVariables = Exact<{
  teamID: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: Maybe<Scalars['String']>;
  sortKey?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  teamChannelIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  productIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  filters?: Maybe<Array<ProductsAdvancedFilterInput> | ProductsAdvancedFilterInput>;
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { Products: (
    { __typename?: 'ProductsResponse' }
    & Pick<ProductsResponse, 'status' | 'total' | 'take' | 'skip'>
    & { teamTaxClasses: Array<(
      { __typename?: 'ProductTaxClass' }
      & Pick<ProductTaxClass, 'id' | 'name' | 'isForShipping'>
      & { taxRules: Array<(
        { __typename?: 'TaxRule' }
        & Pick<TaxRule, 'id' | 'name' | 'priority'>
        & { taxRates: Array<(
          { __typename?: 'TaxRate' }
          & Pick<TaxRate, 'id' | 'identifier' | 'ratePercent'>
        )> }
      )> }
    )>, products?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku' | 'unitPrice' | 'unitPriceExclGST' | 'stockOnHand' | 'totalInventoryCost' | 'createdAt' | 'updatedAt' | 'productProp' | 'fromChannelId'>
      & { productTaxClass?: Maybe<(
        { __typename?: 'ProductTaxClass' }
        & Pick<ProductTaxClass, 'id' | 'name'>
      )>, teamChannelProducts: Array<(
        { __typename?: 'TeamChannelProduct' }
        & Pick<TeamChannelProduct, 'id' | 'status' | 'unitPrice' | 'stockOnHand' | 'externalListingId'>
        & { teamChannel: (
          { __typename?: 'TeamChannel' }
          & Pick<TeamChannel, 'id' | 'name'>
          & { channel: (
            { __typename?: 'Channel' }
            & Pick<Channel, 'id' | 'name'>
          ) }
        ) }
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      )>, attributeSet: (
        { __typename?: 'AttributeSet' }
        & Pick<AttributeSet, 'id' | 'name' | 'channelAttributeSetId'>
      ), productAttributeValues: Array<(
        { __typename?: 'ProductAttributeValue' }
        & Pick<ProductAttributeValue, 'id' | 'value'>
        & { productAttribute: (
          { __typename?: 'ProductAttribute' }
          & Pick<ProductAttribute, 'id' | 'name'>
        ) }
      )> }
    )>> }
  ), GetTeamChannelsByTeamId: (
    { __typename?: 'GetTeamChannelsByTeamIdResponse' }
    & Pick<GetTeamChannelsByTeamIdResponse, 'status'>
    & { teamChannels: Array<(
      { __typename?: 'TeamChannel' }
      & Pick<TeamChannel, 'id' | 'name'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ProductsAutoCompleteQueryVariables = Exact<{
  teamID: Scalars['String'];
  filters?: Maybe<Array<ProductsAdvancedFilterInput> | ProductsAdvancedFilterInput>;
}>;


export type ProductsAutoCompleteQuery = (
  { __typename?: 'Query' }
  & { Products: (
    { __typename?: 'ProductsResponse' }
    & Pick<ProductsResponse, 'status' | 'total'>
    & { products?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku'>
    )>> }
  ) }
);

export type QueryProductTypesQueryVariables = Exact<{
  keyword: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type QueryProductTypesQuery = (
  { __typename?: 'Query' }
  & { QueryProductTypes: (
    { __typename?: 'ProductTypesResponse' }
    & Pick<ProductTypesResponse, 'status' | 'productTypes'>
  ) }
);

export type QuerySummaryReportQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type QuerySummaryReportQuery = (
  { __typename?: 'Query' }
  & { QuerySummaryReport: (
    { __typename?: 'SummaryResponse' }
    & Pick<SummaryResponse, 'status' | 'response'>
    & { errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type TeamChannelSummaryQueryVariables = Exact<{
  teamChannelId: Scalars['String'];
}>;


export type TeamChannelSummaryQuery = (
  { __typename?: 'Query' }
  & { TeamChannelSummary: (
    { __typename?: 'TeamChannelSummaryResponse' }
    & Pick<TeamChannelSummaryResponse, 'status' | 'numberOfOrders' | 'numberOfProducts' | 'totalRevenue'>
  ) }
);

export type QueryTopCustomerSalesQueryVariables = Exact<{
  teamId: Scalars['String'];
  dateStart: Scalars['String'];
  dateEnd: Scalars['String'];
}>;


export type QueryTopCustomerSalesQuery = (
  { __typename?: 'Query' }
  & { QueryTopCustomerSalesReport: (
    { __typename?: 'TopCustomerSalesResponse' }
    & Pick<TopCustomerSalesResponse, 'status' | 'response'>
    & { errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type QueryTopSkuSalesQueryVariables = Exact<{
  teamId: Scalars['String'];
  dateStart: Scalars['String'];
  dateEnd: Scalars['String'];
}>;


export type QueryTopSkuSalesQuery = (
  { __typename?: 'Query' }
  & { QueryTopSKUSalesReport: (
    { __typename?: 'TopSKUSalesResponse' }
    & Pick<TopSkuSalesResponse, 'status' | 'response'>
    & { errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type CreateShipmentMutationVariables = Exact<{
  shipment: ShipmentInput;
}>;


export type CreateShipmentMutation = (
  { __typename?: 'Mutation' }
  & { CreateShipment: (
    { __typename?: 'CreateShipmentResponse' }
    & Pick<CreateShipmentResponse, 'status'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & Pick<Shipment, 'id' | 'orderId' | 'trackNumber' | 'courierId' | 'noOfBoxes' | 'totalCost' | 'totalCostExTax' | 'totalWeight' | 'status' | 'externalId' | 'active' | 'createdAt' | 'updatedAt' | 'createdById' | 'updatedById' | 'externalRes' | 'labelUrl'>
      & { courier: (
        { __typename?: 'Courier' }
        & Pick<Courier, 'id' | 'name' | 'code'>
      ) }
    )> }
  ) }
);

export type GetCouriersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCouriersQuery = (
  { __typename?: 'Query' }
  & { GetCouriers: (
    { __typename?: 'getCouriersResponse' }
    & Pick<GetCouriersResponse, 'status'>
    & { couriers: Array<(
      { __typename?: 'Courier' }
      & Pick<Courier, 'id' | 'name' | 'code' | 'active'>
    )> }
  ) }
);

export type DisconnectXeroMutationVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type DisconnectXeroMutation = (
  { __typename?: 'Mutation' }
  & { DisconnectXero: (
    { __typename?: 'DisconnectXeroResponse' }
    & Pick<DisconnectXeroResponse, 'status'>
  ) }
);

export type SetTeamSmtpMutationVariables = Exact<{
  teamId: Scalars['String'];
  teamSMTP: TeamSmtpInput;
}>;


export type SetTeamSmtpMutation = (
  { __typename?: 'Mutation' }
  & { SetTeamSMTP: (
    { __typename?: 'SetTeamSMTPResponse' }
    & Pick<SetTeamSmtpResponse, 'status'>
  ) }
);

export type SetTeamXeroAccountCodeMutationVariables = Exact<{
  teamId: Scalars['String'];
  xeroAccountCode: Scalars['String'];
}>;


export type SetTeamXeroAccountCodeMutation = (
  { __typename?: 'Mutation' }
  & { SetTeamXeroAccountCode: (
    { __typename?: 'SetTeamXeroAccountCodeResponse' }
    & Pick<SetTeamXeroAccountCodeResponse, 'status'>
  ) }
);

export type SmtpSendCheckMutationVariables = Exact<{
  teamId: Scalars['String'];
  sendTo: Scalars['String'];
}>;


export type SmtpSendCheckMutation = (
  { __typename?: 'Mutation' }
  & { SmtpSendCheck: (
    { __typename?: 'SmtpSendCheckResponse' }
    & Pick<SmtpSendCheckResponse, 'status' | 'checkSuccess' | 'errorMsg'>
  ) }
);

export type UnlinkTeamMutationVariables = Exact<{
  teamId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type UnlinkTeamMutation = (
  { __typename?: 'Mutation' }
  & { UnlinkTeam: (
    { __typename?: 'UnlinkTeamResponse' }
    & Pick<UnlinkTeamResponse, 'status'>
  ) }
);

export type UpdateTeamByIdMutationVariables = Exact<{
  teamId: Scalars['String'];
  team: TeamInput;
  contact: AddressInput;
  defaultProductTaxClassId?: Maybe<Scalars['String']>;
  defaultShippingTaxClassId?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamByIdMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTeamById: (
    { __typename?: 'UpdateTeamByIdResponse' }
    & Pick<UpdateTeamByIdResponse, 'status'>
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )> }
  ) }
);

export type GetTeamByIdQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamByIdQuery = (
  { __typename?: 'Query' }
  & { GetTeamById: (
    { __typename?: 'GetTeamByIdResponse' }
    & Pick<GetTeamByIdResponse, 'status'>
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'logo' | 'abn' | 'description' | 'baseUrl' | 'settings' | 'xeroConnected' | 'tenantName' | 'xeroAccountCode' | 'teamChargePlanId' | 'createdAt'>
      & { addresses: Array<(
        { __typename?: 'TeamAddress' }
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'company' | 'email' | 'phone' | 'fax' | 'mobile' | 'street' | 'suburb' | 'state' | 'country' | 'postcode'>
        ) }
      )> }
    )> }
  ) }
);

export type GetTeamSmtpQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamSmtpQuery = (
  { __typename?: 'Query' }
  & { GetTeamSMTP: (
    { __typename?: 'GetTeamSMTPResponse' }
    & Pick<GetTeamSmtpResponse, 'status'>
    & { teamSMTP?: Maybe<(
      { __typename?: 'TeamSMTP' }
      & Pick<TeamSmtp, 'id' | 'host' | 'username' | 'password' | 'port'>
    )> }
  ) }
);

export type GetTeamTaxClassesQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamTaxClassesQuery = (
  { __typename?: 'Query' }
  & { GetTeamTaxClasses: (
    { __typename?: 'GetTeamTaxClassesResponse' }
    & { taxClasses?: Maybe<Array<(
      { __typename?: 'ProductTaxClass' }
      & Pick<ProductTaxClass, 'id' | 'name' | 'isForShipping'>
    )>> }
  ) }
);

export type GetTeamXeroAccountCodeQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamXeroAccountCodeQuery = (
  { __typename?: 'Query' }
  & { GetTeamXeroAccountCode: (
    { __typename?: 'GetTeamXeroAccountCodeResponse' }
    & Pick<GetTeamXeroAccountCodeResponse, 'status' | 'response'>
  ) }
);

export type GetUsersByTeamQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetUsersByTeamQuery = (
  { __typename?: 'Query' }
  & { GetUsersByTeam: (
    { __typename?: 'GetUsersByTeamResponse' }
    & Pick<GetUsersByTeamResponse, 'status'>
    & { users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'activated' | 'email' | 'createdAt' | 'updatedAt'>
    )>> }
  ) }
);

export type CreateCourierInquiryMutationVariables = Exact<{
  courierName: Scalars['String'];
  teamID: Scalars['String'];
}>;


export type CreateCourierInquiryMutation = (
  { __typename?: 'Mutation' }
  & { CreateCourierInquiry: (
    { __typename?: 'CreateCourierInquiryResponse' }
    & Pick<CreateCourierInquiryResponse, 'status'>
    & { errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type SetTeamCourierMutationVariables = Exact<{
  teamId: Scalars['String'];
  courierId: Scalars['String'];
  configuration: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type SetTeamCourierMutation = (
  { __typename?: 'Mutation' }
  & { SetTeamCourier: (
    { __typename?: 'SetTeamCourierResponse' }
    & Pick<SetTeamCourierResponse, 'status'>
    & { teamCourier: (
      { __typename?: 'TeamCourier' }
      & Pick<TeamCourier, 'id' | 'teamId' | 'courierId' | 'configuration' | 'active'>
    ) }
  ) }
);

export type GetTeamCourierQueryVariables = Exact<{
  teamId: Scalars['String'];
  courierId: Scalars['String'];
}>;


export type GetTeamCourierQuery = (
  { __typename?: 'Query' }
  & { GetTeamCourier: (
    { __typename?: 'GetTeamCourierResponse' }
    & Pick<GetTeamCourierResponse, 'status'>
    & { teamCourier?: Maybe<(
      { __typename?: 'TeamCourier' }
      & Pick<TeamCourier, 'id' | 'teamId' | 'courierId' | 'configuration' | 'active' | 'createdById' | 'updatedById' | 'createdAt' | 'updatedAt' | 'info'>
    )> }
  ) }
);

export type GetTeamCouriersQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamCouriersQuery = (
  { __typename?: 'Query' }
  & { GetTeamCouriers: (
    { __typename?: 'GetTeamCouriersResponse' }
    & Pick<GetTeamCouriersResponse, 'status'>
    & { teamCouriers?: Maybe<Array<(
      { __typename?: 'TeamCourier' }
      & Pick<TeamCourier, 'id' | 'teamId' | 'courierId' | 'configuration' | 'active' | 'createdAt' | 'updatedAt' | 'info'>
    )>> }
  ) }
);

export type GetTeamCouriersAndSystemsCouriersQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamCouriersAndSystemsCouriersQuery = (
  { __typename?: 'Query' }
  & { GetCouriers: (
    { __typename?: 'getCouriersResponse' }
    & Pick<GetCouriersResponse, 'status'>
    & { couriers: Array<(
      { __typename?: 'Courier' }
      & Pick<Courier, 'id' | 'name' | 'code' | 'active'>
    )> }
  ), GetTeamCouriers: (
    { __typename?: 'GetTeamCouriersResponse' }
    & Pick<GetTeamCouriersResponse, 'status'>
    & { teamCouriers?: Maybe<Array<(
      { __typename?: 'TeamCourier' }
      & Pick<TeamCourier, 'id' | 'teamId' | 'courierId' | 'configuration' | 'active' | 'createdAt' | 'updatedAt' | 'info'>
    )>> }
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  teamID: Scalars['String'];
  action: Scalars['String'];
  ticketId: Scalars['String'];
  body: Scalars['String'];
  authorId: Scalars['String'];
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'CreateCommentResponse' }
    & Pick<CreateCommentResponse, 'status'>
  ) }
);

export type UpdateTicketMutationVariables = Exact<{
  teamID: Scalars['String'];
  ticketId: Scalars['String'];
  priority?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
}>;


export type UpdateTicketMutation = (
  { __typename?: 'Mutation' }
  & { updateTicket: (
    { __typename?: 'UpdateTicketInfoResponse' }
    & Pick<UpdateTicketInfoResponse, 'status'>
  ) }
);

export type GetTicketCommentsQueryVariables = Exact<{
  teamID: Scalars['String'];
  ticketId: Scalars['String'];
}>;


export type GetTicketCommentsQuery = (
  { __typename?: 'Query' }
  & { getTicketComments: (
    { __typename?: 'TicketCommentsResponse' }
    & Pick<TicketCommentsResponse, 'status' | 'requester' | 'assignee' | 'assigneeId'>
    & { comments: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'author' | 'htmlBody' | 'created_at'>
      & { attachments: Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'id' | 'file_name' | 'content_url'>
      )> }
    )> }
  ) }
);

export type TicketsByTeamQueryVariables = Exact<{
  teamID: Scalars['String'];
  searchTxt?: Maybe<Scalars['String']>;
  sortKey: Scalars['String'];
  sortOrder: Scalars['String'];
  pageSize: Scalars['Int'];
  pageNum: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
}>;


export type TicketsByTeamQuery = (
  { __typename?: 'Query' }
  & { TicketsByTeam: (
    { __typename?: 'TicketsResponse' }
    & Pick<TicketsResponse, 'status' | 'total'>
    & { tickets?: Maybe<Array<Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'id' | 'subject' | 'status' | 'itemId' | 'orderId' | 'channel' | 'description' | 'requester' | 'created_at' | 'updated_at' | 'priority' | 'image'>
    )>>> }
  ) }
);

export type ActivateInvitedUserMutationVariables = Exact<{
  userId: Scalars['String'];
  token: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
}>;


export type ActivateInvitedUserMutation = (
  { __typename?: 'Mutation' }
  & { ActivateInvitedUser: (
    { __typename?: 'ActivateInvitedUserResponse' }
    & Pick<ActivateInvitedUserResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'activated' | 'email'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & Pick<TeamUser, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
          & { teamChannels: Array<(
            { __typename?: 'TeamChannel' }
            & Pick<TeamChannel, 'id' | 'name' | 'active' | 'externalId' | 'onBoarded' | 'settings' | 'createdAt' | 'updatedAt'>
            & { channel: (
              { __typename?: 'Channel' }
              & Pick<Channel, 'id' | 'name'>
            ), createdBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )>, updatedBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        ) }
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type ActivateUserMutationVariables = Exact<{
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & { ActivateUser: (
    { __typename?: 'UserActivationResponse' }
    & Pick<UserActivationResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'activated' | 'email'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & Pick<TeamUser, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
          & { teamChannels: Array<(
            { __typename?: 'TeamChannel' }
            & Pick<TeamChannel, 'id' | 'active' | 'name' | 'externalId' | 'onBoarded' | 'settings' | 'createdAt' | 'updatedAt'>
            & { channel: (
              { __typename?: 'Channel' }
              & Pick<Channel, 'id' | 'name'>
            ), createdBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )>, updatedBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { ChangePassword: (
    { __typename?: 'ChangePasswordResponse' }
    & Pick<ChangePasswordResponse, 'status'>
  ) }
);

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  teamID: Scalars['String'];
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { InviteUser: (
    { __typename?: 'InviteUserResponse' }
    & Pick<InviteUserResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'activated'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { Login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'activated' | 'email' | 'language'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & Pick<TeamUser, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name' | 'settings'>
          & { teamChannels: Array<(
            { __typename?: 'TeamChannel' }
            & Pick<TeamChannel, 'id' | 'active' | 'name' | 'externalId' | 'onBoarded' | 'settings' | 'createdAt' | 'updatedAt'>
            & { channel: (
              { __typename?: 'Channel' }
              & Pick<Channel, 'id' | 'name'>
            ), createdBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )>, updatedBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        ) }
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { RequestPasswordReset: (
    { __typename?: 'RequestPasswordResetResponse' }
    & Pick<RequestPasswordResetResponse, 'status' | 'email'>
  ) }
);

export type ResendSignupActivationMutationVariables = Exact<{
  userEmail: Scalars['String'];
  resendEmail: Scalars['String'];
}>;


export type ResendSignupActivationMutation = (
  { __typename?: 'Mutation' }
  & { ResendSignupActivation: (
    { __typename?: 'ResendSignupActivationResponse' }
    & Pick<ResendSignupActivationResponse, 'token' | 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'activated'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { ResetPassword: (
    { __typename?: 'ResetPasswordResponse' }
    & Pick<ResetPasswordResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'activated' | 'email'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & Pick<TeamUser, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
          & { teamChannels: Array<(
            { __typename?: 'TeamChannel' }
            & Pick<TeamChannel, 'id' | 'name' | 'active' | 'externalId' | 'onBoarded' | 'settings' | 'createdAt' | 'updatedAt'>
            & { channel: (
              { __typename?: 'Channel' }
              & Pick<Channel, 'id' | 'name'>
            ), createdBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )>, updatedBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = (
  { __typename?: 'Mutation' }
  & { Logout: (
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'status'>
    & { errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type SignUpMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  teamName: Scalars['String'];
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { SignUp: (
    { __typename?: 'SignupResponse' }
    & Pick<SignupResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'activated'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id'>
        ) }
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { UpdateUser: (
    { __typename?: 'UpdateUserResponse' }
    & Pick<UpdateUserResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'language'>
    )> }
  ) }
);

export type XeroLoginMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type XeroLoginMutation = (
  { __typename?: 'Mutation' }
  & { XeroLogin: (
    { __typename?: 'XeroLoginResponse' }
    & Pick<XeroLoginResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'activated' | 'email'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & Pick<TeamUser, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
          & { teamChannels: Array<(
            { __typename?: 'TeamChannel' }
            & Pick<TeamChannel, 'id' | 'active' | 'name' | 'externalId' | 'onBoarded' | 'settings' | 'watermarkUrl' | 'createdAt' | 'updatedAt'>
            & { channel: (
              { __typename?: 'Channel' }
              & Pick<Channel, 'id' | 'name'>
            ), createdBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )>, updatedBy?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        ) }
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'error' }
      & Pick<Error, 'message'>
    )>> }
  ) }
);

export type GetUserByTokenQueryVariables = Exact<{
  userId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetUserByTokenQuery = (
  { __typename?: 'Query' }
  & { GetUserByToken: (
    { __typename?: 'GetUserByTokenResponse' }
    & Pick<GetUserByTokenResponse, 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'activated'>
      & { teams: Array<(
        { __typename?: 'TeamUser' }
        & Pick<TeamUser, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
          & { teamChannels: Array<(
            { __typename?: 'TeamChannel' }
            & Pick<TeamChannel, 'id' | 'name'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { Me: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email'>
    & { teams: Array<(
      { __typename?: 'TeamUser' }
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ValidatePasswordResetLinkQueryVariables = Exact<{
  userId: Scalars['String'];
  token: Scalars['String'];
}>;


export type ValidatePasswordResetLinkQuery = (
  { __typename?: 'Query' }
  & { ValidatePasswordResetLink: (
    { __typename?: 'ValidatePasswordResetLinkResponse' }
    & Pick<ValidatePasswordResetLinkResponse, 'status'>
  ) }
);

export const FullAddressFragmentFragmentDoc = gql`
    fragment fullAddressFragment on Address {
  id
  name
  company
  email
  phone
  fax
  mobile
  street
  suburb
  state
  country
  postcode
  comments
}
    `;
export const FullOrderStatusFragmentFragmentDoc = gql`
    fragment fullOrderStatusFragment on OrderStatus {
  id
  name
  code
  order
}
    `;
export const OrderItemFragmentFragmentDoc = gql`
    fragment orderItemFragment on OrderItem {
  id
  productID
  teamChannelProductID
  sku
  name
  description
  unitPrice
  unitPriceExclGST
  qty
  discountAmount
  totalPrice
  taxAmt
  taxRate
  createdAt
  updatedAt
  totalAmtInclTax
  shipByDate
  productTaxClassId
}
    `;
export const ShipmentFragmentFragmentDoc = gql`
    fragment shipmentFragment on Shipment {
  id
  active
  orderId
  trackNumber
  courierId
  courier {
    id
    name
  }
  noOfBoxes
  totalCost
  totalWeight
  status
  externalId
  labelUrl
  externalRes
  createdAt
  updatedAt
}
    `;
export const TeamChannelFragmentFragmentDoc = gql`
    fragment teamChannelFragment on TeamChannel {
  id
  name
  externalId
  channelID
  channel {
    id
    name
  }
  onBoarded
  active
  lastOrderSync
  settings
  createdAt
  updatedAt
  createdBy {
    firstName
    lastName
  }
  updatedBy {
    firstName
    lastName
  }
}
    `;
export const SharedTeamCustomerFragmentFragmentDoc = gql`
    fragment SharedTeamCustomerFragment on TeamCustomer {
  id
  name
  company
  email
  mobile
  phone
  shippingAddresses {
    id
    address {
      id
      name
      company
      email
      phone
      fax
      mobile
      street
      suburb
      state
      country
      postcode
    }
  }
  billingAddresses {
    id
    address {
      id
      name
      company
      email
      phone
      fax
      mobile
      street
      suburb
      state
      country
      postcode
    }
  }
  team {
    name
  }
  teamChannel {
    name
    channel {
      name
    }
  }
  totalExpense
  purchases
  createdAt
}
    `;
export const SharedOrderFragmentFragmentDoc = gql`
    fragment sharedOrderFragment on Order {
  id
  amountBase
  teamChannelID
  teamID
  channelID
  teamCustomerID
  shippingAddrID
  billingAddrID
  marketPlaceFee
  shippingCarrierCode
  shipByDate
  shippingAddress {
    ...fullAddressFragment
  }
  billingAddress {
    ...fullAddressFragment
  }
  status {
    ...fullOrderStatusFragment
  }
  payments {
    id
    orderId
    currency
    paidAmount
    createdAt
    paymentMethod {
      name
    }
    paymentCreatedBy {
      firstName
      lastName
      email
    }
    paidBy {
      name
    }
  }
  paymentStatus
  currency
  orderNumber
  legacyOrderId
  totalAmount
  totalAmtInclTax
  paidAmount
  totalPaidAmt
  comments
  totalTaxAmt
  totalShippingAmt
  shippingTaxAmt
  shippingServiceCode
  preferPaymentMethod
  totalShippingAmtInclTax
  shippingTaxRate
  totalDiscountAmt
  orderDiscountAmt
  createdAt
  updatedAt
  lastModifiedDate
  orderItems {
    ...orderItemFragment
  }
  shipments(orderBy: {createdAt: asc}) {
    ...shipmentFragment
  }
  teamChannel {
    ...teamChannelFragment
  }
  channel {
    id
    name
  }
  teamCustomer {
    ...SharedTeamCustomerFragment
  }
}
    ${FullAddressFragmentFragmentDoc}
${FullOrderStatusFragmentFragmentDoc}
${OrderItemFragmentFragmentDoc}
${ShipmentFragmentFragmentDoc}
${TeamChannelFragmentFragmentDoc}
${SharedTeamCustomerFragmentFragmentDoc}`;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($addressId: String!, $addressParams: AddressParams!) {
  UpdateAddress(addressId: $addressId, addressParams: $addressParams) {
    status
    address {
      id
      email
      company
      name
      street
      state
      phone
      suburb
      postcode
      country
    }
  }
}
    `;
export type UpdateAddressMutationFn = ApolloReactCommon.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      addressParams: // value for 'addressParams'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, baseOptions);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = ApolloReactCommon.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const AuspostCreateLabelsDocument = gql`
    mutation AuspostCreateLabels($teamId: String!, $shipmentIds: [String!]!) {
  AuspostCreateLabels(teamId: $teamId, shipmentIds: $shipmentIds) {
    status
    response
  }
}
    `;
export type AuspostCreateLabelsMutationFn = ApolloReactCommon.MutationFunction<AuspostCreateLabelsMutation, AuspostCreateLabelsMutationVariables>;

/**
 * __useAuspostCreateLabelsMutation__
 *
 * To run a mutation, you first call `useAuspostCreateLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuspostCreateLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auspostCreateLabelsMutation, { data, loading, error }] = useAuspostCreateLabelsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      shipmentIds: // value for 'shipmentIds'
 *   },
 * });
 */
export function useAuspostCreateLabelsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuspostCreateLabelsMutation, AuspostCreateLabelsMutationVariables>) {
        return ApolloReactHooks.useMutation<AuspostCreateLabelsMutation, AuspostCreateLabelsMutationVariables>(AuspostCreateLabelsDocument, baseOptions);
      }
export type AuspostCreateLabelsMutationHookResult = ReturnType<typeof useAuspostCreateLabelsMutation>;
export type AuspostCreateLabelsMutationResult = ApolloReactCommon.MutationResult<AuspostCreateLabelsMutation>;
export type AuspostCreateLabelsMutationOptions = ApolloReactCommon.BaseMutationOptions<AuspostCreateLabelsMutation, AuspostCreateLabelsMutationVariables>;
export const AuspostCreateShipmentDocument = gql`
    mutation AuspostCreateShipment($createShip: CreateShip!) {
  AuspostCreateShipment(createShip: $createShip) {
    status
    response
  }
}
    `;
export type AuspostCreateShipmentMutationFn = ApolloReactCommon.MutationFunction<AuspostCreateShipmentMutation, AuspostCreateShipmentMutationVariables>;

/**
 * __useAuspostCreateShipmentMutation__
 *
 * To run a mutation, you first call `useAuspostCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuspostCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auspostCreateShipmentMutation, { data, loading, error }] = useAuspostCreateShipmentMutation({
 *   variables: {
 *      createShip: // value for 'createShip'
 *   },
 * });
 */
export function useAuspostCreateShipmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuspostCreateShipmentMutation, AuspostCreateShipmentMutationVariables>) {
        return ApolloReactHooks.useMutation<AuspostCreateShipmentMutation, AuspostCreateShipmentMutationVariables>(AuspostCreateShipmentDocument, baseOptions);
      }
export type AuspostCreateShipmentMutationHookResult = ReturnType<typeof useAuspostCreateShipmentMutation>;
export type AuspostCreateShipmentMutationResult = ApolloReactCommon.MutationResult<AuspostCreateShipmentMutation>;
export type AuspostCreateShipmentMutationOptions = ApolloReactCommon.BaseMutationOptions<AuspostCreateShipmentMutation, AuspostCreateShipmentMutationVariables>;
export const AuspostGetAccountDocument = gql`
    query AuspostGetAccount($teamId: String!, $key: String!, $password: String!, $account: String!) {
  AuspostGetAccounts(teamId: $teamId, key: $key, password: $password, account: $account) {
    status
    response
  }
}
    `;

/**
 * __useAuspostGetAccountQuery__
 *
 * To run a query within a React component, call `useAuspostGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuspostGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuspostGetAccountQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      key: // value for 'key'
 *      password: // value for 'password'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useAuspostGetAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuspostGetAccountQuery, AuspostGetAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<AuspostGetAccountQuery, AuspostGetAccountQueryVariables>(AuspostGetAccountDocument, baseOptions);
      }
export function useAuspostGetAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuspostGetAccountQuery, AuspostGetAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuspostGetAccountQuery, AuspostGetAccountQueryVariables>(AuspostGetAccountDocument, baseOptions);
        }
export type AuspostGetAccountQueryHookResult = ReturnType<typeof useAuspostGetAccountQuery>;
export type AuspostGetAccountLazyQueryHookResult = ReturnType<typeof useAuspostGetAccountLazyQuery>;
export type AuspostGetAccountQueryResult = ApolloReactCommon.QueryResult<AuspostGetAccountQuery, AuspostGetAccountQueryVariables>;
export const AuspostGetQuoteDocument = gql`
    query AuspostGetQuote($itemsInput: ItemsInput!) {
  AuspostGetQuote(itemsInput: $itemsInput) {
    status
    response
  }
}
    `;

/**
 * __useAuspostGetQuoteQuery__
 *
 * To run a query within a React component, call `useAuspostGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuspostGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuspostGetQuoteQuery({
 *   variables: {
 *      itemsInput: // value for 'itemsInput'
 *   },
 * });
 */
export function useAuspostGetQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuspostGetQuoteQuery, AuspostGetQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<AuspostGetQuoteQuery, AuspostGetQuoteQueryVariables>(AuspostGetQuoteDocument, baseOptions);
      }
export function useAuspostGetQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuspostGetQuoteQuery, AuspostGetQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuspostGetQuoteQuery, AuspostGetQuoteQueryVariables>(AuspostGetQuoteDocument, baseOptions);
        }
export type AuspostGetQuoteQueryHookResult = ReturnType<typeof useAuspostGetQuoteQuery>;
export type AuspostGetQuoteLazyQueryHookResult = ReturnType<typeof useAuspostGetQuoteLazyQuery>;
export type AuspostGetQuoteQueryResult = ApolloReactCommon.QueryResult<AuspostGetQuoteQuery, AuspostGetQuoteQueryVariables>;
export const CancelTeamChargeDocument = gql`
    mutation CancelTeamCharge($teamId: String!, $teamChargeId: String!) {
  CancelTeamCharge(teamId: $teamId, teamChargeId: $teamChargeId) {
    status
  }
}
    `;
export type CancelTeamChargeMutationFn = ApolloReactCommon.MutationFunction<CancelTeamChargeMutation, CancelTeamChargeMutationVariables>;

/**
 * __useCancelTeamChargeMutation__
 *
 * To run a mutation, you first call `useCancelTeamChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTeamChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTeamChargeMutation, { data, loading, error }] = useCancelTeamChargeMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      teamChargeId: // value for 'teamChargeId'
 *   },
 * });
 */
export function useCancelTeamChargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelTeamChargeMutation, CancelTeamChargeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelTeamChargeMutation, CancelTeamChargeMutationVariables>(CancelTeamChargeDocument, baseOptions);
      }
export type CancelTeamChargeMutationHookResult = ReturnType<typeof useCancelTeamChargeMutation>;
export type CancelTeamChargeMutationResult = ApolloReactCommon.MutationResult<CancelTeamChargeMutation>;
export type CancelTeamChargeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelTeamChargeMutation, CancelTeamChargeMutationVariables>;
export const CreateTeamChargeDocument = gql`
    mutation CreateTeamCharge($teamId: String!, $teamChannelId: String!, $chargeType: Int!, $cardNumber: String!, $expiryDate: String!, $cvc: String!) {
  CreateTeamCharge(teamId: $teamId, teamChannelId: $teamChannelId, chargeType: $chargeType, cardNumber: $cardNumber, expiryDate: $expiryDate, cvc: $cvc) {
    status
    confirmationUrl
    chargeType
    accessToken
    chargeId
  }
}
    `;
export type CreateTeamChargeMutationFn = ApolloReactCommon.MutationFunction<CreateTeamChargeMutation, CreateTeamChargeMutationVariables>;

/**
 * __useCreateTeamChargeMutation__
 *
 * To run a mutation, you first call `useCreateTeamChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamChargeMutation, { data, loading, error }] = useCreateTeamChargeMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      teamChannelId: // value for 'teamChannelId'
 *      chargeType: // value for 'chargeType'
 *      cardNumber: // value for 'cardNumber'
 *      expiryDate: // value for 'expiryDate'
 *      cvc: // value for 'cvc'
 *   },
 * });
 */
export function useCreateTeamChargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamChargeMutation, CreateTeamChargeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamChargeMutation, CreateTeamChargeMutationVariables>(CreateTeamChargeDocument, baseOptions);
      }
export type CreateTeamChargeMutationHookResult = ReturnType<typeof useCreateTeamChargeMutation>;
export type CreateTeamChargeMutationResult = ApolloReactCommon.MutationResult<CreateTeamChargeMutation>;
export type CreateTeamChargeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamChargeMutation, CreateTeamChargeMutationVariables>;
export const CybersourceChargeSubDocument = gql`
    mutation CybersourceChargeSub($teamId: String!, $chargeId: String!, $authenticationTransactionId: String!, $email: String!, $phone: String!, $state: String!, $country: String!, $street: String!, $suburb: String!, $postcode: String!, $firstName: String!, $lastName: String!, $cardNumber: String!, $expiryDate: String!, $cvc: String!, $step: Int!) {
  CybersourceChargeSub(teamId: $teamId, chargeId: $chargeId, authenticationTransactionId: $authenticationTransactionId, email: $email, phone: $phone, state: $state, country: $country, street: $street, suburb: $suburb, postcode: $postcode, firstName: $firstName, lastName: $lastName, cardNumber: $cardNumber, expiryDate: $expiryDate, cvc: $cvc, step: $step) {
    status
    userCheckUrl
    accessToken
    checkStatus
    chargeId
    authenticationTransactionId
  }
}
    `;
export type CybersourceChargeSubMutationFn = ApolloReactCommon.MutationFunction<CybersourceChargeSubMutation, CybersourceChargeSubMutationVariables>;

/**
 * __useCybersourceChargeSubMutation__
 *
 * To run a mutation, you first call `useCybersourceChargeSubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCybersourceChargeSubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cybersourceChargeSubMutation, { data, loading, error }] = useCybersourceChargeSubMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      chargeId: // value for 'chargeId'
 *      authenticationTransactionId: // value for 'authenticationTransactionId'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      street: // value for 'street'
 *      suburb: // value for 'suburb'
 *      postcode: // value for 'postcode'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      cardNumber: // value for 'cardNumber'
 *      expiryDate: // value for 'expiryDate'
 *      cvc: // value for 'cvc'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useCybersourceChargeSubMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CybersourceChargeSubMutation, CybersourceChargeSubMutationVariables>) {
        return ApolloReactHooks.useMutation<CybersourceChargeSubMutation, CybersourceChargeSubMutationVariables>(CybersourceChargeSubDocument, baseOptions);
      }
export type CybersourceChargeSubMutationHookResult = ReturnType<typeof useCybersourceChargeSubMutation>;
export type CybersourceChargeSubMutationResult = ApolloReactCommon.MutationResult<CybersourceChargeSubMutation>;
export type CybersourceChargeSubMutationOptions = ApolloReactCommon.BaseMutationOptions<CybersourceChargeSubMutation, CybersourceChargeSubMutationVariables>;
export const GetTeamChargeAliveDocument = gql`
    query GetTeamChargeAlive($teamId: String!) {
  GetTeamChargeAlive(teamId: $teamId) {
    status
    teamCharge {
      id
      teamChannelId
      status
      chargeType
      activeEndAt
      monthPlan
      teamChargePlanId
    }
  }
}
    `;

/**
 * __useGetTeamChargeAliveQuery__
 *
 * To run a query within a React component, call `useGetTeamChargeAliveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChargeAliveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChargeAliveQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamChargeAliveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamChargeAliveQuery, GetTeamChargeAliveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamChargeAliveQuery, GetTeamChargeAliveQueryVariables>(GetTeamChargeAliveDocument, baseOptions);
      }
export function useGetTeamChargeAliveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamChargeAliveQuery, GetTeamChargeAliveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamChargeAliveQuery, GetTeamChargeAliveQueryVariables>(GetTeamChargeAliveDocument, baseOptions);
        }
export type GetTeamChargeAliveQueryHookResult = ReturnType<typeof useGetTeamChargeAliveQuery>;
export type GetTeamChargeAliveLazyQueryHookResult = ReturnType<typeof useGetTeamChargeAliveLazyQuery>;
export type GetTeamChargeAliveQueryResult = ApolloReactCommon.QueryResult<GetTeamChargeAliveQuery, GetTeamChargeAliveQueryVariables>;
export const GetTeamChargeBillingsByIdDocument = gql`
    query GetTeamChargeBillingsById($teamId: String!) {
  GetTeamChargeBillingsById(teamId: $teamId) {
    status
    billings {
      id
      paid
      invNumber
      invDate
      amount
      title
    }
  }
}
    `;

/**
 * __useGetTeamChargeBillingsByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamChargeBillingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChargeBillingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChargeBillingsByIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamChargeBillingsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamChargeBillingsByIdQuery, GetTeamChargeBillingsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamChargeBillingsByIdQuery, GetTeamChargeBillingsByIdQueryVariables>(GetTeamChargeBillingsByIdDocument, baseOptions);
      }
export function useGetTeamChargeBillingsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamChargeBillingsByIdQuery, GetTeamChargeBillingsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamChargeBillingsByIdQuery, GetTeamChargeBillingsByIdQueryVariables>(GetTeamChargeBillingsByIdDocument, baseOptions);
        }
export type GetTeamChargeBillingsByIdQueryHookResult = ReturnType<typeof useGetTeamChargeBillingsByIdQuery>;
export type GetTeamChargeBillingsByIdLazyQueryHookResult = ReturnType<typeof useGetTeamChargeBillingsByIdLazyQuery>;
export type GetTeamChargeBillingsByIdQueryResult = ApolloReactCommon.QueryResult<GetTeamChargeBillingsByIdQuery, GetTeamChargeBillingsByIdQueryVariables>;
export const GetTeamChargePlanByIdDocument = gql`
    query GetTeamChargePlanById($teamChargePlanId: String!) {
  GetTeamChargePlanById(teamChargePlanId: $teamChargePlanId) {
    status
    teamChargePlan {
      id
      title
      amount
      currency
      planType
    }
  }
}
    `;

/**
 * __useGetTeamChargePlanByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamChargePlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChargePlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChargePlanByIdQuery({
 *   variables: {
 *      teamChargePlanId: // value for 'teamChargePlanId'
 *   },
 * });
 */
export function useGetTeamChargePlanByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamChargePlanByIdQuery, GetTeamChargePlanByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamChargePlanByIdQuery, GetTeamChargePlanByIdQueryVariables>(GetTeamChargePlanByIdDocument, baseOptions);
      }
export function useGetTeamChargePlanByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamChargePlanByIdQuery, GetTeamChargePlanByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamChargePlanByIdQuery, GetTeamChargePlanByIdQueryVariables>(GetTeamChargePlanByIdDocument, baseOptions);
        }
export type GetTeamChargePlanByIdQueryHookResult = ReturnType<typeof useGetTeamChargePlanByIdQuery>;
export type GetTeamChargePlanByIdLazyQueryHookResult = ReturnType<typeof useGetTeamChargePlanByIdLazyQuery>;
export type GetTeamChargePlanByIdQueryResult = ApolloReactCommon.QueryResult<GetTeamChargePlanByIdQuery, GetTeamChargePlanByIdQueryVariables>;
export const GetTeamChargePlansDocument = gql`
    query GetTeamChargePlans {
  GetTeamChargePlans {
    status
    teamChargePlans {
      id
      title
      amount
      currency
    }
  }
}
    `;

/**
 * __useGetTeamChargePlansQuery__
 *
 * To run a query within a React component, call `useGetTeamChargePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChargePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChargePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamChargePlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamChargePlansQuery, GetTeamChargePlansQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamChargePlansQuery, GetTeamChargePlansQueryVariables>(GetTeamChargePlansDocument, baseOptions);
      }
export function useGetTeamChargePlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamChargePlansQuery, GetTeamChargePlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamChargePlansQuery, GetTeamChargePlansQueryVariables>(GetTeamChargePlansDocument, baseOptions);
        }
export type GetTeamChargePlansQueryHookResult = ReturnType<typeof useGetTeamChargePlansQuery>;
export type GetTeamChargePlansLazyQueryHookResult = ReturnType<typeof useGetTeamChargePlansLazyQuery>;
export type GetTeamChargePlansQueryResult = ApolloReactCommon.QueryResult<GetTeamChargePlansQuery, GetTeamChargePlansQueryVariables>;
export const InvoiceChargePdfCreationDocument = gql`
    mutation InvoiceChargePdfCreation($teamChargeBillingId: String!) {
  InvoiceChargePdfCreation(teamChargeBillingId: $teamChargeBillingId) {
    status
    url
  }
}
    `;
export type InvoiceChargePdfCreationMutationFn = ApolloReactCommon.MutationFunction<InvoiceChargePdfCreationMutation, InvoiceChargePdfCreationMutationVariables>;

/**
 * __useInvoiceChargePdfCreationMutation__
 *
 * To run a mutation, you first call `useInvoiceChargePdfCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceChargePdfCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceChargePdfCreationMutation, { data, loading, error }] = useInvoiceChargePdfCreationMutation({
 *   variables: {
 *      teamChargeBillingId: // value for 'teamChargeBillingId'
 *   },
 * });
 */
export function useInvoiceChargePdfCreationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceChargePdfCreationMutation, InvoiceChargePdfCreationMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceChargePdfCreationMutation, InvoiceChargePdfCreationMutationVariables>(InvoiceChargePdfCreationDocument, baseOptions);
      }
export type InvoiceChargePdfCreationMutationHookResult = ReturnType<typeof useInvoiceChargePdfCreationMutation>;
export type InvoiceChargePdfCreationMutationResult = ApolloReactCommon.MutationResult<InvoiceChargePdfCreationMutation>;
export type InvoiceChargePdfCreationMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceChargePdfCreationMutation, InvoiceChargePdfCreationMutationVariables>;
export const CleanTeamChannelByIdDocument = gql`
    mutation cleanTeamChannelById($teamChannelId: String!) {
  CleanTeamChannelById(teamChannelId: $teamChannelId) {
    status
  }
}
    `;
export type CleanTeamChannelByIdMutationFn = ApolloReactCommon.MutationFunction<CleanTeamChannelByIdMutation, CleanTeamChannelByIdMutationVariables>;

/**
 * __useCleanTeamChannelByIdMutation__
 *
 * To run a mutation, you first call `useCleanTeamChannelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanTeamChannelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanTeamChannelByIdMutation, { data, loading, error }] = useCleanTeamChannelByIdMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useCleanTeamChannelByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CleanTeamChannelByIdMutation, CleanTeamChannelByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<CleanTeamChannelByIdMutation, CleanTeamChannelByIdMutationVariables>(CleanTeamChannelByIdDocument, baseOptions);
      }
export type CleanTeamChannelByIdMutationHookResult = ReturnType<typeof useCleanTeamChannelByIdMutation>;
export type CleanTeamChannelByIdMutationResult = ApolloReactCommon.MutationResult<CleanTeamChannelByIdMutation>;
export type CleanTeamChannelByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<CleanTeamChannelByIdMutation, CleanTeamChannelByIdMutationVariables>;
export const CreateTeamChannelDocument = gql`
    mutation createTeamChannel($teamId: String!, $name: String!, $channelId: String, $channelSettings: String) {
  CreateTeamChannel(teamId: $teamId, name: $name, channelId: $channelId, channelSettings: $channelSettings) {
    status
    teamChannel {
      id
      name
      active
      externalId
      channel {
        id
        name
      }
      settings
      onBoarded
      createdAt
      updatedAt
      createdBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreateTeamChannelMutationFn = ApolloReactCommon.MutationFunction<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>;

/**
 * __useCreateTeamChannelMutation__
 *
 * To run a mutation, you first call `useCreateTeamChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamChannelMutation, { data, loading, error }] = useCreateTeamChannelMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *      channelId: // value for 'channelId'
 *      channelSettings: // value for 'channelSettings'
 *   },
 * });
 */
export function useCreateTeamChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>(CreateTeamChannelDocument, baseOptions);
      }
export type CreateTeamChannelMutationHookResult = ReturnType<typeof useCreateTeamChannelMutation>;
export type CreateTeamChannelMutationResult = ApolloReactCommon.MutationResult<CreateTeamChannelMutation>;
export type CreateTeamChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>;
export const CreateTeamChannelSupplierDocument = gql`
    mutation createTeamChannelSupplier($teamId: String!, $name: String!, $tcsSettings: TcsSettingsInput!) {
  CreateTeamChannelSupplier(teamId: $teamId, name: $name, tcsSettings: $tcsSettings) {
    status
    teamChannelSupplier {
      id
      name
      settings
      onBoarded
      createdAt
      updatedAt
      lastProductSync
    }
  }
}
    `;
export type CreateTeamChannelSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateTeamChannelSupplierMutation, CreateTeamChannelSupplierMutationVariables>;

/**
 * __useCreateTeamChannelSupplierMutation__
 *
 * To run a mutation, you first call `useCreateTeamChannelSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamChannelSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamChannelSupplierMutation, { data, loading, error }] = useCreateTeamChannelSupplierMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *      tcsSettings: // value for 'tcsSettings'
 *   },
 * });
 */
export function useCreateTeamChannelSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamChannelSupplierMutation, CreateTeamChannelSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamChannelSupplierMutation, CreateTeamChannelSupplierMutationVariables>(CreateTeamChannelSupplierDocument, baseOptions);
      }
export type CreateTeamChannelSupplierMutationHookResult = ReturnType<typeof useCreateTeamChannelSupplierMutation>;
export type CreateTeamChannelSupplierMutationResult = ApolloReactCommon.MutationResult<CreateTeamChannelSupplierMutation>;
export type CreateTeamChannelSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamChannelSupplierMutation, CreateTeamChannelSupplierMutationVariables>;
export const DeactivateTeamChannelDocument = gql`
    mutation deactivateTeamChannel($teamChannelId: String!) {
  DeactivateTeamChannel(teamChannelId: $teamChannelId) {
    status
  }
}
    `;
export type DeactivateTeamChannelMutationFn = ApolloReactCommon.MutationFunction<DeactivateTeamChannelMutation, DeactivateTeamChannelMutationVariables>;

/**
 * __useDeactivateTeamChannelMutation__
 *
 * To run a mutation, you first call `useDeactivateTeamChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTeamChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTeamChannelMutation, { data, loading, error }] = useDeactivateTeamChannelMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useDeactivateTeamChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateTeamChannelMutation, DeactivateTeamChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateTeamChannelMutation, DeactivateTeamChannelMutationVariables>(DeactivateTeamChannelDocument, baseOptions);
      }
export type DeactivateTeamChannelMutationHookResult = ReturnType<typeof useDeactivateTeamChannelMutation>;
export type DeactivateTeamChannelMutationResult = ApolloReactCommon.MutationResult<DeactivateTeamChannelMutation>;
export type DeactivateTeamChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateTeamChannelMutation, DeactivateTeamChannelMutationVariables>;
export const DeactivateTeamChannelSupplierDocument = gql`
    mutation deactivateTeamChannelSupplier($teamChannelSupplierId: String!) {
  DeactivateTeamChannelSupplier(teamChannelSupplierId: $teamChannelSupplierId) {
    status
  }
}
    `;
export type DeactivateTeamChannelSupplierMutationFn = ApolloReactCommon.MutationFunction<DeactivateTeamChannelSupplierMutation, DeactivateTeamChannelSupplierMutationVariables>;

/**
 * __useDeactivateTeamChannelSupplierMutation__
 *
 * To run a mutation, you first call `useDeactivateTeamChannelSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTeamChannelSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTeamChannelSupplierMutation, { data, loading, error }] = useDeactivateTeamChannelSupplierMutation({
 *   variables: {
 *      teamChannelSupplierId: // value for 'teamChannelSupplierId'
 *   },
 * });
 */
export function useDeactivateTeamChannelSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateTeamChannelSupplierMutation, DeactivateTeamChannelSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateTeamChannelSupplierMutation, DeactivateTeamChannelSupplierMutationVariables>(DeactivateTeamChannelSupplierDocument, baseOptions);
      }
export type DeactivateTeamChannelSupplierMutationHookResult = ReturnType<typeof useDeactivateTeamChannelSupplierMutation>;
export type DeactivateTeamChannelSupplierMutationResult = ApolloReactCommon.MutationResult<DeactivateTeamChannelSupplierMutation>;
export type DeactivateTeamChannelSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateTeamChannelSupplierMutation, DeactivateTeamChannelSupplierMutationVariables>;
export const SyncEbayStoreInfoDocument = gql`
    mutation syncEbayStoreInfo($teamChannelId: String!) {
  SyncEbayStoreInfo(teamChannelId: $teamChannelId) {
    status
    teamChannel {
      id
      externalId
      name
    }
  }
}
    `;
export type SyncEbayStoreInfoMutationFn = ApolloReactCommon.MutationFunction<SyncEbayStoreInfoMutation, SyncEbayStoreInfoMutationVariables>;

/**
 * __useSyncEbayStoreInfoMutation__
 *
 * To run a mutation, you first call `useSyncEbayStoreInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncEbayStoreInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncEbayStoreInfoMutation, { data, loading, error }] = useSyncEbayStoreInfoMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useSyncEbayStoreInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncEbayStoreInfoMutation, SyncEbayStoreInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncEbayStoreInfoMutation, SyncEbayStoreInfoMutationVariables>(SyncEbayStoreInfoDocument, baseOptions);
      }
export type SyncEbayStoreInfoMutationHookResult = ReturnType<typeof useSyncEbayStoreInfoMutation>;
export type SyncEbayStoreInfoMutationResult = ApolloReactCommon.MutationResult<SyncEbayStoreInfoMutation>;
export type SyncEbayStoreInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncEbayStoreInfoMutation, SyncEbayStoreInfoMutationVariables>;
export const UpdateTeamChannelDocument = gql`
    mutation updateTeamChannel($teamChannelID: String!, $fulfillmentPolicyId: String, $fulfillmentPolicyName: String, $merchantLocationKey: String, $merchantLocationName: String, $paymentPolicyId: String, $paymentPolicyName: String, $returnPolicyId: String, $returnPolicyName: String, $pushStockQty: Boolean, $pushPriceUpdates: Boolean, $onBoarded: Boolean, $watermarkUrl: String) {
  UpdateTeamChannel(teamChannelID: $teamChannelID, fulfillmentPolicyId: $fulfillmentPolicyId, fulfillmentPolicyName: $fulfillmentPolicyName, merchantLocationKey: $merchantLocationKey, merchantLocationName: $merchantLocationName, paymentPolicyId: $paymentPolicyId, paymentPolicyName: $paymentPolicyName, returnPolicyId: $returnPolicyId, returnPolicyName: $returnPolicyName, pushStockQty: $pushStockQty, pushPriceUpdates: $pushPriceUpdates, onBoarded: $onBoarded, watermarkUrl: $watermarkUrl) {
    status
    teamChannel {
      id
      onBoarded
    }
  }
}
    `;
export type UpdateTeamChannelMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamChannelMutation, UpdateTeamChannelMutationVariables>;

/**
 * __useUpdateTeamChannelMutation__
 *
 * To run a mutation, you first call `useUpdateTeamChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamChannelMutation, { data, loading, error }] = useUpdateTeamChannelMutation({
 *   variables: {
 *      teamChannelID: // value for 'teamChannelID'
 *      fulfillmentPolicyId: // value for 'fulfillmentPolicyId'
 *      fulfillmentPolicyName: // value for 'fulfillmentPolicyName'
 *      merchantLocationKey: // value for 'merchantLocationKey'
 *      merchantLocationName: // value for 'merchantLocationName'
 *      paymentPolicyId: // value for 'paymentPolicyId'
 *      paymentPolicyName: // value for 'paymentPolicyName'
 *      returnPolicyId: // value for 'returnPolicyId'
 *      returnPolicyName: // value for 'returnPolicyName'
 *      pushStockQty: // value for 'pushStockQty'
 *      pushPriceUpdates: // value for 'pushPriceUpdates'
 *      onBoarded: // value for 'onBoarded'
 *      watermarkUrl: // value for 'watermarkUrl'
 *   },
 * });
 */
export function useUpdateTeamChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamChannelMutation, UpdateTeamChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeamChannelMutation, UpdateTeamChannelMutationVariables>(UpdateTeamChannelDocument, baseOptions);
      }
export type UpdateTeamChannelMutationHookResult = ReturnType<typeof useUpdateTeamChannelMutation>;
export type UpdateTeamChannelMutationResult = ApolloReactCommon.MutationResult<UpdateTeamChannelMutation>;
export type UpdateTeamChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamChannelMutation, UpdateTeamChannelMutationVariables>;
export const GetTcssByTeamIdDocument = gql`
    query getTcssByTeamId($teamId: String!) {
  GetTcssByTeamId(teamId: $teamId) {
    status
    teamChannelSuppliers {
      id
      name
      settings
      onBoarded
      createdAt
      updatedAt
      lastProductSync
    }
  }
}
    `;

/**
 * __useGetTcssByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetTcssByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTcssByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTcssByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTcssByTeamIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTcssByTeamIdQuery, GetTcssByTeamIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTcssByTeamIdQuery, GetTcssByTeamIdQueryVariables>(GetTcssByTeamIdDocument, baseOptions);
      }
export function useGetTcssByTeamIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTcssByTeamIdQuery, GetTcssByTeamIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTcssByTeamIdQuery, GetTcssByTeamIdQueryVariables>(GetTcssByTeamIdDocument, baseOptions);
        }
export type GetTcssByTeamIdQueryHookResult = ReturnType<typeof useGetTcssByTeamIdQuery>;
export type GetTcssByTeamIdLazyQueryHookResult = ReturnType<typeof useGetTcssByTeamIdLazyQuery>;
export type GetTcssByTeamIdQueryResult = ApolloReactCommon.QueryResult<GetTcssByTeamIdQuery, GetTcssByTeamIdQueryVariables>;
export const GetTeamChannelByIdDocument = gql`
    query getTeamChannelById($teamChannelId: String!) {
  GetTeamChannelById(teamChannelId: $teamChannelId) {
    status
    teamChannel {
      id
      name
      externalId
      channel {
        id
        name
      }
      onBoarded
      lastOrderSync
      settings
      createdAt
      updatedAt
      createdBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      watermarkUrl
    }
  }
}
    `;

/**
 * __useGetTeamChannelByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamChannelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChannelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChannelByIdQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useGetTeamChannelByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamChannelByIdQuery, GetTeamChannelByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamChannelByIdQuery, GetTeamChannelByIdQueryVariables>(GetTeamChannelByIdDocument, baseOptions);
      }
export function useGetTeamChannelByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamChannelByIdQuery, GetTeamChannelByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamChannelByIdQuery, GetTeamChannelByIdQueryVariables>(GetTeamChannelByIdDocument, baseOptions);
        }
export type GetTeamChannelByIdQueryHookResult = ReturnType<typeof useGetTeamChannelByIdQuery>;
export type GetTeamChannelByIdLazyQueryHookResult = ReturnType<typeof useGetTeamChannelByIdLazyQuery>;
export type GetTeamChannelByIdQueryResult = ApolloReactCommon.QueryResult<GetTeamChannelByIdQuery, GetTeamChannelByIdQueryVariables>;
export const GetTeamChannelsByTeamIdDocument = gql`
    query getTeamChannelsByTeamId($teamId: String!) {
  GetTeamChannelsByTeamId(teamId: $teamId) {
    status
    teamChannels {
      ...teamChannelFragment
    }
  }
}
    ${TeamChannelFragmentFragmentDoc}`;

/**
 * __useGetTeamChannelsByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetTeamChannelsByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChannelsByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChannelsByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamChannelsByTeamIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamChannelsByTeamIdQuery, GetTeamChannelsByTeamIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamChannelsByTeamIdQuery, GetTeamChannelsByTeamIdQueryVariables>(GetTeamChannelsByTeamIdDocument, baseOptions);
      }
export function useGetTeamChannelsByTeamIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamChannelsByTeamIdQuery, GetTeamChannelsByTeamIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamChannelsByTeamIdQuery, GetTeamChannelsByTeamIdQueryVariables>(GetTeamChannelsByTeamIdDocument, baseOptions);
        }
export type GetTeamChannelsByTeamIdQueryHookResult = ReturnType<typeof useGetTeamChannelsByTeamIdQuery>;
export type GetTeamChannelsByTeamIdLazyQueryHookResult = ReturnType<typeof useGetTeamChannelsByTeamIdLazyQuery>;
export type GetTeamChannelsByTeamIdQueryResult = ApolloReactCommon.QueryResult<GetTeamChannelsByTeamIdQuery, GetTeamChannelsByTeamIdQueryVariables>;
export const CreateTeamCustomerCommentDocument = gql`
    mutation CreateTeamCustomerComment($teamId: String!, $customerId: String!, $content: String!) {
  CreateTeamCustomerComment(teamId: $teamId, customerId: $customerId, content: $content) {
    status
    comment {
      content
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreateTeamCustomerCommentMutationFn = ApolloReactCommon.MutationFunction<CreateTeamCustomerCommentMutation, CreateTeamCustomerCommentMutationVariables>;

/**
 * __useCreateTeamCustomerCommentMutation__
 *
 * To run a mutation, you first call `useCreateTeamCustomerCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamCustomerCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamCustomerCommentMutation, { data, loading, error }] = useCreateTeamCustomerCommentMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      customerId: // value for 'customerId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateTeamCustomerCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamCustomerCommentMutation, CreateTeamCustomerCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamCustomerCommentMutation, CreateTeamCustomerCommentMutationVariables>(CreateTeamCustomerCommentDocument, baseOptions);
      }
export type CreateTeamCustomerCommentMutationHookResult = ReturnType<typeof useCreateTeamCustomerCommentMutation>;
export type CreateTeamCustomerCommentMutationResult = ApolloReactCommon.MutationResult<CreateTeamCustomerCommentMutation>;
export type CreateTeamCustomerCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamCustomerCommentMutation, CreateTeamCustomerCommentMutationVariables>;
export const TeamCustomerCommentsDocument = gql`
    query TeamCustomerComments($teamID: String!, $customerId: String!) {
  TeamCustomerComments(teamID: $teamID, customerId: $customerId) {
    status
    comments {
      id
      content
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useTeamCustomerCommentsQuery__
 *
 * To run a query within a React component, call `useTeamCustomerCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCustomerCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCustomerCommentsQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useTeamCustomerCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamCustomerCommentsQuery, TeamCustomerCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamCustomerCommentsQuery, TeamCustomerCommentsQueryVariables>(TeamCustomerCommentsDocument, baseOptions);
      }
export function useTeamCustomerCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamCustomerCommentsQuery, TeamCustomerCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamCustomerCommentsQuery, TeamCustomerCommentsQueryVariables>(TeamCustomerCommentsDocument, baseOptions);
        }
export type TeamCustomerCommentsQueryHookResult = ReturnType<typeof useTeamCustomerCommentsQuery>;
export type TeamCustomerCommentsLazyQueryHookResult = ReturnType<typeof useTeamCustomerCommentsLazyQuery>;
export type TeamCustomerCommentsQueryResult = ApolloReactCommon.QueryResult<TeamCustomerCommentsQuery, TeamCustomerCommentsQueryVariables>;
export const CreateTeamCustomerDocument = gql`
    mutation CreateTeamCustomer($teamChannelId: String!, $teamCustomer: CreateTeamCustomerInput!) {
  CreateTeamCustomer(teamChannelId: $teamChannelId, teamCustomer: $teamCustomer) {
    status
    teamCustomer {
      ...SharedTeamCustomerFragment
    }
  }
}
    ${SharedTeamCustomerFragmentFragmentDoc}`;
export type CreateTeamCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateTeamCustomerMutation, CreateTeamCustomerMutationVariables>;

/**
 * __useCreateTeamCustomerMutation__
 *
 * To run a mutation, you first call `useCreateTeamCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamCustomerMutation, { data, loading, error }] = useCreateTeamCustomerMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *      teamCustomer: // value for 'teamCustomer'
 *   },
 * });
 */
export function useCreateTeamCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamCustomerMutation, CreateTeamCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamCustomerMutation, CreateTeamCustomerMutationVariables>(CreateTeamCustomerDocument, baseOptions);
      }
export type CreateTeamCustomerMutationHookResult = ReturnType<typeof useCreateTeamCustomerMutation>;
export type CreateTeamCustomerMutationResult = ApolloReactCommon.MutationResult<CreateTeamCustomerMutation>;
export type CreateTeamCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamCustomerMutation, CreateTeamCustomerMutationVariables>;
export const UpdateTeamCustomerDocument = gql`
    mutation UpdateTeamCustomer($customerId: String!, $email: String, $phone: String, $mobile: String) {
  UpdateTeamCustomer(customerId: $customerId, email: $email, phone: $phone, mobile: $mobile) {
    status
    teamCustomer {
      id
      name
      email
      phone
      mobile
    }
  }
}
    `;
export type UpdateTeamCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamCustomerMutation, UpdateTeamCustomerMutationVariables>;

/**
 * __useUpdateTeamCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateTeamCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamCustomerMutation, { data, loading, error }] = useUpdateTeamCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useUpdateTeamCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamCustomerMutation, UpdateTeamCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeamCustomerMutation, UpdateTeamCustomerMutationVariables>(UpdateTeamCustomerDocument, baseOptions);
      }
export type UpdateTeamCustomerMutationHookResult = ReturnType<typeof useUpdateTeamCustomerMutation>;
export type UpdateTeamCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateTeamCustomerMutation>;
export type UpdateTeamCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamCustomerMutation, UpdateTeamCustomerMutationVariables>;
export const TcAutoCompleteDocument = gql`
    query TcAutoComplete($teamID: String!, $name: String, $email: String, $username: String) {
  TeamCustomers(teamID: $teamID, skip: 0, take: 5, name: $name, email: $email, username: $username) {
    status
    total
    teamCustomers {
      id
      name
      email
      externalCustomerID
    }
  }
}
    `;

/**
 * __useTcAutoCompleteQuery__
 *
 * To run a query within a React component, call `useTcAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTcAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTcAutoCompleteQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useTcAutoCompleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TcAutoCompleteQuery, TcAutoCompleteQueryVariables>) {
        return ApolloReactHooks.useQuery<TcAutoCompleteQuery, TcAutoCompleteQueryVariables>(TcAutoCompleteDocument, baseOptions);
      }
export function useTcAutoCompleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TcAutoCompleteQuery, TcAutoCompleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TcAutoCompleteQuery, TcAutoCompleteQueryVariables>(TcAutoCompleteDocument, baseOptions);
        }
export type TcAutoCompleteQueryHookResult = ReturnType<typeof useTcAutoCompleteQuery>;
export type TcAutoCompleteLazyQueryHookResult = ReturnType<typeof useTcAutoCompleteLazyQuery>;
export type TcAutoCompleteQueryResult = ApolloReactCommon.QueryResult<TcAutoCompleteQuery, TcAutoCompleteQueryVariables>;
export const TeamCustomerDetailDocument = gql`
    query TeamCustomerDetail($customerId: String!) {
  TeamCustomerDetail(customerId: $customerId) {
    status
    teamCustomer {
      name
      email
      phone
      mobile
      externalCustomerID
      shippingAddresses {
        id
        address {
          id
          company
          name
          street
          state
          suburb
          postcode
          country
        }
      }
      billingAddresses {
        id
        address {
          id
          company
          name
          street
          state
          suburb
          postcode
          country
        }
      }
      createdAt
    }
  }
}
    `;

/**
 * __useTeamCustomerDetailQuery__
 *
 * To run a query within a React component, call `useTeamCustomerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCustomerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCustomerDetailQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useTeamCustomerDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamCustomerDetailQuery, TeamCustomerDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamCustomerDetailQuery, TeamCustomerDetailQueryVariables>(TeamCustomerDetailDocument, baseOptions);
      }
export function useTeamCustomerDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamCustomerDetailQuery, TeamCustomerDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamCustomerDetailQuery, TeamCustomerDetailQueryVariables>(TeamCustomerDetailDocument, baseOptions);
        }
export type TeamCustomerDetailQueryHookResult = ReturnType<typeof useTeamCustomerDetailQuery>;
export type TeamCustomerDetailLazyQueryHookResult = ReturnType<typeof useTeamCustomerDetailLazyQuery>;
export type TeamCustomerDetailQueryResult = ApolloReactCommon.QueryResult<TeamCustomerDetailQuery, TeamCustomerDetailQueryVariables>;
export const TeamCustomerFeedBackDocument = gql`
    query teamCustomerFeedBack($teamID: String!, $userId: String!) {
  TeamCustomerFeedBack(teamID: $teamID, userId: $userId) {
    score
    status
  }
}
    `;

/**
 * __useTeamCustomerFeedBackQuery__
 *
 * To run a query within a React component, call `useTeamCustomerFeedBackQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCustomerFeedBackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCustomerFeedBackQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTeamCustomerFeedBackQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamCustomerFeedBackQuery, TeamCustomerFeedBackQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamCustomerFeedBackQuery, TeamCustomerFeedBackQueryVariables>(TeamCustomerFeedBackDocument, baseOptions);
      }
export function useTeamCustomerFeedBackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamCustomerFeedBackQuery, TeamCustomerFeedBackQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamCustomerFeedBackQuery, TeamCustomerFeedBackQueryVariables>(TeamCustomerFeedBackDocument, baseOptions);
        }
export type TeamCustomerFeedBackQueryHookResult = ReturnType<typeof useTeamCustomerFeedBackQuery>;
export type TeamCustomerFeedBackLazyQueryHookResult = ReturnType<typeof useTeamCustomerFeedBackLazyQuery>;
export type TeamCustomerFeedBackQueryResult = ApolloReactCommon.QueryResult<TeamCustomerFeedBackQuery, TeamCustomerFeedBackQueryVariables>;
export const TeamCustomersDocument = gql`
    query TeamCustomers($teamID: String!, $searchTxt: String, $dateRange: String, $sortArg: CustomerSortInput, $teamChannelIds: [String!], $skip: Int!, $take: Int!) {
  TeamCustomers(teamID: $teamID, searchTxt: $searchTxt, dateRange: $dateRange, sortArg: $sortArg, teamChannelIds: $teamChannelIds, skip: $skip, take: $take) {
    status
    total
    teamChannels {
      id
      name
    }
    teamCustomers {
      ...SharedTeamCustomerFragment
    }
  }
}
    ${SharedTeamCustomerFragmentFragmentDoc}`;

/**
 * __useTeamCustomersQuery__
 *
 * To run a query within a React component, call `useTeamCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCustomersQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      searchTxt: // value for 'searchTxt'
 *      dateRange: // value for 'dateRange'
 *      sortArg: // value for 'sortArg'
 *      teamChannelIds: // value for 'teamChannelIds'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useTeamCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamCustomersQuery, TeamCustomersQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamCustomersQuery, TeamCustomersQueryVariables>(TeamCustomersDocument, baseOptions);
      }
export function useTeamCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamCustomersQuery, TeamCustomersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamCustomersQuery, TeamCustomersQueryVariables>(TeamCustomersDocument, baseOptions);
        }
export type TeamCustomersQueryHookResult = ReturnType<typeof useTeamCustomersQuery>;
export type TeamCustomersLazyQueryHookResult = ReturnType<typeof useTeamCustomersLazyQuery>;
export type TeamCustomersQueryResult = ApolloReactCommon.QueryResult<TeamCustomersQuery, TeamCustomersQueryVariables>;
export const QueryEbayProductsDocument = gql`
    query QueryEbayProducts($q: String!, $categoryId: String) {
  QueryEbay(resource: "catalog.search", params: [{value: {q: $q, category_ids: $categoryId}}]) {
    response
  }
}
    `;

/**
 * __useQueryEbayProductsQuery__
 *
 * To run a query within a React component, call `useQueryEbayProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayProductsQuery({
 *   variables: {
 *      q: // value for 'q'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQueryEbayProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayProductsQuery, QueryEbayProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayProductsQuery, QueryEbayProductsQueryVariables>(QueryEbayProductsDocument, baseOptions);
      }
export function useQueryEbayProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayProductsQuery, QueryEbayProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayProductsQuery, QueryEbayProductsQueryVariables>(QueryEbayProductsDocument, baseOptions);
        }
export type QueryEbayProductsQueryHookResult = ReturnType<typeof useQueryEbayProductsQuery>;
export type QueryEbayProductsLazyQueryHookResult = ReturnType<typeof useQueryEbayProductsLazyQuery>;
export type QueryEbayProductsQueryResult = ApolloReactCommon.QueryResult<QueryEbayProductsQuery, QueryEbayProductsQueryVariables>;
export const QueryEbayProductDocument = gql`
    query QueryEbayProduct($epid: String) {
  QueryEbay(resource: "catalog.getProduct", params: [{value: $epid}]) {
    response
  }
}
    `;

/**
 * __useQueryEbayProductQuery__
 *
 * To run a query within a React component, call `useQueryEbayProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayProductQuery({
 *   variables: {
 *      epid: // value for 'epid'
 *   },
 * });
 */
export function useQueryEbayProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayProductQuery, QueryEbayProductQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayProductQuery, QueryEbayProductQueryVariables>(QueryEbayProductDocument, baseOptions);
      }
export function useQueryEbayProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayProductQuery, QueryEbayProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayProductQuery, QueryEbayProductQueryVariables>(QueryEbayProductDocument, baseOptions);
        }
export type QueryEbayProductQueryHookResult = ReturnType<typeof useQueryEbayProductQuery>;
export type QueryEbayProductLazyQueryHookResult = ReturnType<typeof useQueryEbayProductLazyQuery>;
export type QueryEbayProductQueryResult = ApolloReactCommon.QueryResult<QueryEbayProductQuery, QueryEbayProductQueryVariables>;
export const QueryEbayCategoryFeaturesDocument = gql`
    query QueryEbayCategoryFeatures($categoryId: String) {
  QueryEbay(resource: "trading.getCategoryFeatures", params: [{value: {category_ids: $categoryId}}]) {
    response
  }
}
    `;

/**
 * __useQueryEbayCategoryFeaturesQuery__
 *
 * To run a query within a React component, call `useQueryEbayCategoryFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayCategoryFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayCategoryFeaturesQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQueryEbayCategoryFeaturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayCategoryFeaturesQuery, QueryEbayCategoryFeaturesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayCategoryFeaturesQuery, QueryEbayCategoryFeaturesQueryVariables>(QueryEbayCategoryFeaturesDocument, baseOptions);
      }
export function useQueryEbayCategoryFeaturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayCategoryFeaturesQuery, QueryEbayCategoryFeaturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayCategoryFeaturesQuery, QueryEbayCategoryFeaturesQueryVariables>(QueryEbayCategoryFeaturesDocument, baseOptions);
        }
export type QueryEbayCategoryFeaturesQueryHookResult = ReturnType<typeof useQueryEbayCategoryFeaturesQuery>;
export type QueryEbayCategoryFeaturesLazyQueryHookResult = ReturnType<typeof useQueryEbayCategoryFeaturesLazyQuery>;
export type QueryEbayCategoryFeaturesQueryResult = ApolloReactCommon.QueryResult<QueryEbayCategoryFeaturesQuery, QueryEbayCategoryFeaturesQueryVariables>;
export const QueryFulfilmentPoliciesDocument = gql`
    query QueryFulfilmentPolicies($teamChannelId: String!) {
  QueryEbay(resource: "fulfillmentPolicy.getFulfillmentPolicies", params: [{value: {marketplace_id: "EBAY_AU"}}], teamChannelId: $teamChannelId) {
    response
  }
}
    `;

/**
 * __useQueryFulfilmentPoliciesQuery__
 *
 * To run a query within a React component, call `useQueryFulfilmentPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFulfilmentPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFulfilmentPoliciesQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useQueryFulfilmentPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryFulfilmentPoliciesQuery, QueryFulfilmentPoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryFulfilmentPoliciesQuery, QueryFulfilmentPoliciesQueryVariables>(QueryFulfilmentPoliciesDocument, baseOptions);
      }
export function useQueryFulfilmentPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryFulfilmentPoliciesQuery, QueryFulfilmentPoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryFulfilmentPoliciesQuery, QueryFulfilmentPoliciesQueryVariables>(QueryFulfilmentPoliciesDocument, baseOptions);
        }
export type QueryFulfilmentPoliciesQueryHookResult = ReturnType<typeof useQueryFulfilmentPoliciesQuery>;
export type QueryFulfilmentPoliciesLazyQueryHookResult = ReturnType<typeof useQueryFulfilmentPoliciesLazyQuery>;
export type QueryFulfilmentPoliciesQueryResult = ApolloReactCommon.QueryResult<QueryFulfilmentPoliciesQuery, QueryFulfilmentPoliciesQueryVariables>;
export const FindOrCreateLocationDocument = gql`
    query findOrCreateLocation($teamChannelId: String!) {
  FindOrCreateMerchantLocation(teamChannelId: $teamChannelId) {
    response
  }
}
    `;

/**
 * __useFindOrCreateLocationQuery__
 *
 * To run a query within a React component, call `useFindOrCreateLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrCreateLocationQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useFindOrCreateLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindOrCreateLocationQuery, FindOrCreateLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<FindOrCreateLocationQuery, FindOrCreateLocationQueryVariables>(FindOrCreateLocationDocument, baseOptions);
      }
export function useFindOrCreateLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindOrCreateLocationQuery, FindOrCreateLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindOrCreateLocationQuery, FindOrCreateLocationQueryVariables>(FindOrCreateLocationDocument, baseOptions);
        }
export type FindOrCreateLocationQueryHookResult = ReturnType<typeof useFindOrCreateLocationQuery>;
export type FindOrCreateLocationLazyQueryHookResult = ReturnType<typeof useFindOrCreateLocationLazyQuery>;
export type FindOrCreateLocationQueryResult = ApolloReactCommon.QueryResult<FindOrCreateLocationQuery, FindOrCreateLocationQueryVariables>;
export const QueryMyEbaySellingDocument = gql`
    query QueryMyEbaySelling($teamChannelId: String!) {
  QueryMyEbaySelling(teamChannelId: $teamChannelId) {
    response
  }
}
    `;

/**
 * __useQueryMyEbaySellingQuery__
 *
 * To run a query within a React component, call `useQueryMyEbaySellingQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMyEbaySellingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMyEbaySellingQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useQueryMyEbaySellingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryMyEbaySellingQuery, QueryMyEbaySellingQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryMyEbaySellingQuery, QueryMyEbaySellingQueryVariables>(QueryMyEbaySellingDocument, baseOptions);
      }
export function useQueryMyEbaySellingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryMyEbaySellingQuery, QueryMyEbaySellingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryMyEbaySellingQuery, QueryMyEbaySellingQueryVariables>(QueryMyEbaySellingDocument, baseOptions);
        }
export type QueryMyEbaySellingQueryHookResult = ReturnType<typeof useQueryMyEbaySellingQuery>;
export type QueryMyEbaySellingLazyQueryHookResult = ReturnType<typeof useQueryMyEbaySellingLazyQuery>;
export type QueryMyEbaySellingQueryResult = ApolloReactCommon.QueryResult<QueryMyEbaySellingQuery, QueryMyEbaySellingQueryVariables>;
export const QueryPaymentPoliciesDocument = gql`
    query QueryPaymentPolicies($teamChannelId: String!) {
  QueryEbay(resource: "paymentPolicy.getPaymentPolicies", params: [{value: {marketplace_id: "EBAY_AU"}}], teamChannelId: $teamChannelId) {
    response
  }
}
    `;

/**
 * __useQueryPaymentPoliciesQuery__
 *
 * To run a query within a React component, call `useQueryPaymentPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPaymentPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPaymentPoliciesQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useQueryPaymentPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryPaymentPoliciesQuery, QueryPaymentPoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryPaymentPoliciesQuery, QueryPaymentPoliciesQueryVariables>(QueryPaymentPoliciesDocument, baseOptions);
      }
export function useQueryPaymentPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryPaymentPoliciesQuery, QueryPaymentPoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryPaymentPoliciesQuery, QueryPaymentPoliciesQueryVariables>(QueryPaymentPoliciesDocument, baseOptions);
        }
export type QueryPaymentPoliciesQueryHookResult = ReturnType<typeof useQueryPaymentPoliciesQuery>;
export type QueryPaymentPoliciesLazyQueryHookResult = ReturnType<typeof useQueryPaymentPoliciesLazyQuery>;
export type QueryPaymentPoliciesQueryResult = ApolloReactCommon.QueryResult<QueryPaymentPoliciesQuery, QueryPaymentPoliciesQueryVariables>;
export const QueryReturnPoliciesDocument = gql`
    query QueryReturnPolicies($teamChannelId: String!) {
  QueryEbay(resource: "returnPolicy.getReturnPolicies", params: [{value: {marketplace_id: "EBAY_AU"}}], teamChannelId: $teamChannelId) {
    response
  }
}
    `;

/**
 * __useQueryReturnPoliciesQuery__
 *
 * To run a query within a React component, call `useQueryReturnPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryReturnPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryReturnPoliciesQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useQueryReturnPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryReturnPoliciesQuery, QueryReturnPoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryReturnPoliciesQuery, QueryReturnPoliciesQueryVariables>(QueryReturnPoliciesDocument, baseOptions);
      }
export function useQueryReturnPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryReturnPoliciesQuery, QueryReturnPoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryReturnPoliciesQuery, QueryReturnPoliciesQueryVariables>(QueryReturnPoliciesDocument, baseOptions);
        }
export type QueryReturnPoliciesQueryHookResult = ReturnType<typeof useQueryReturnPoliciesQuery>;
export type QueryReturnPoliciesLazyQueryHookResult = ReturnType<typeof useQueryReturnPoliciesLazyQuery>;
export type QueryReturnPoliciesQueryResult = ApolloReactCommon.QueryResult<QueryReturnPoliciesQuery, QueryReturnPoliciesQueryVariables>;
export const QueryCategorySubtreeDocument = gql`
    query QueryCategorySubtree($categoryId: String!) {
  QueryEbay(resource: "taxonomy.getCategorySubtree", params: [{value: "0"}, {value: {category_id: $categoryId}}]) {
    response
  }
}
    `;

/**
 * __useQueryCategorySubtreeQuery__
 *
 * To run a query within a React component, call `useQueryCategorySubtreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCategorySubtreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCategorySubtreeQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQueryCategorySubtreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryCategorySubtreeQuery, QueryCategorySubtreeQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryCategorySubtreeQuery, QueryCategorySubtreeQueryVariables>(QueryCategorySubtreeDocument, baseOptions);
      }
export function useQueryCategorySubtreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryCategorySubtreeQuery, QueryCategorySubtreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryCategorySubtreeQuery, QueryCategorySubtreeQueryVariables>(QueryCategorySubtreeDocument, baseOptions);
        }
export type QueryCategorySubtreeQueryHookResult = ReturnType<typeof useQueryCategorySubtreeQuery>;
export type QueryCategorySubtreeLazyQueryHookResult = ReturnType<typeof useQueryCategorySubtreeLazyQuery>;
export type QueryCategorySubtreeQueryResult = ApolloReactCommon.QueryResult<QueryCategorySubtreeQuery, QueryCategorySubtreeQueryVariables>;
export const QueryEbayCategorySuggestionsDocument = gql`
    query QueryEbayCategorySuggestions($q: String!) {
  QueryEbay(resource: "taxonomy.getCategorySuggestions", params: [{value: "15"}, {value: {q: $q}}]) {
    response
  }
}
    `;

/**
 * __useQueryEbayCategorySuggestionsQuery__
 *
 * To run a query within a React component, call `useQueryEbayCategorySuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayCategorySuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayCategorySuggestionsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useQueryEbayCategorySuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayCategorySuggestionsQuery, QueryEbayCategorySuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayCategorySuggestionsQuery, QueryEbayCategorySuggestionsQueryVariables>(QueryEbayCategorySuggestionsDocument, baseOptions);
      }
export function useQueryEbayCategorySuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayCategorySuggestionsQuery, QueryEbayCategorySuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayCategorySuggestionsQuery, QueryEbayCategorySuggestionsQueryVariables>(QueryEbayCategorySuggestionsDocument, baseOptions);
        }
export type QueryEbayCategorySuggestionsQueryHookResult = ReturnType<typeof useQueryEbayCategorySuggestionsQuery>;
export type QueryEbayCategorySuggestionsLazyQueryHookResult = ReturnType<typeof useQueryEbayCategorySuggestionsLazyQuery>;
export type QueryEbayCategorySuggestionsQueryResult = ApolloReactCommon.QueryResult<QueryEbayCategorySuggestionsQuery, QueryEbayCategorySuggestionsQueryVariables>;
export const QueryEbayCategoryAspectsDocument = gql`
    query QueryEbayCategoryAspects($categoryId: String!) {
  QueryEbay(resource: "taxonomy.getItemAspectsForCategory", params: [{value: "15"}, {value: {category_id: $categoryId}}]) {
    response
  }
}
    `;

/**
 * __useQueryEbayCategoryAspectsQuery__
 *
 * To run a query within a React component, call `useQueryEbayCategoryAspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayCategoryAspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayCategoryAspectsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQueryEbayCategoryAspectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayCategoryAspectsQuery, QueryEbayCategoryAspectsQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayCategoryAspectsQuery, QueryEbayCategoryAspectsQueryVariables>(QueryEbayCategoryAspectsDocument, baseOptions);
      }
export function useQueryEbayCategoryAspectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayCategoryAspectsQuery, QueryEbayCategoryAspectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayCategoryAspectsQuery, QueryEbayCategoryAspectsQueryVariables>(QueryEbayCategoryAspectsDocument, baseOptions);
        }
export type QueryEbayCategoryAspectsQueryHookResult = ReturnType<typeof useQueryEbayCategoryAspectsQuery>;
export type QueryEbayCategoryAspectsLazyQueryHookResult = ReturnType<typeof useQueryEbayCategoryAspectsLazyQuery>;
export type QueryEbayCategoryAspectsQueryResult = ApolloReactCommon.QueryResult<QueryEbayCategoryAspectsQuery, QueryEbayCategoryAspectsQueryVariables>;
export const FastwayCreateLabelDocument = gql`
    mutation FastwayCreateLabel($teamId: String!, $conId: String!) {
  FastwayCreateLabel(teamId: $teamId, conId: $conId) {
    status
    url
  }
}
    `;
export type FastwayCreateLabelMutationFn = ApolloReactCommon.MutationFunction<FastwayCreateLabelMutation, FastwayCreateLabelMutationVariables>;

/**
 * __useFastwayCreateLabelMutation__
 *
 * To run a mutation, you first call `useFastwayCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastwayCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastwayCreateLabelMutation, { data, loading, error }] = useFastwayCreateLabelMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      conId: // value for 'conId'
 *   },
 * });
 */
export function useFastwayCreateLabelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FastwayCreateLabelMutation, FastwayCreateLabelMutationVariables>) {
        return ApolloReactHooks.useMutation<FastwayCreateLabelMutation, FastwayCreateLabelMutationVariables>(FastwayCreateLabelDocument, baseOptions);
      }
export type FastwayCreateLabelMutationHookResult = ReturnType<typeof useFastwayCreateLabelMutation>;
export type FastwayCreateLabelMutationResult = ApolloReactCommon.MutationResult<FastwayCreateLabelMutation>;
export type FastwayCreateLabelMutationOptions = ApolloReactCommon.BaseMutationOptions<FastwayCreateLabelMutation, FastwayCreateLabelMutationVariables>;
export const FastwayCreateShipmentDocument = gql`
    mutation FastwayCreateShipment($teamId: String!, $consignment: FastwayConsignmentInput!) {
  FastwayCreateShipment(teamId: $teamId, consignment: $consignment) {
    status
    conId
    externalResponse
    trackingNumber
  }
}
    `;
export type FastwayCreateShipmentMutationFn = ApolloReactCommon.MutationFunction<FastwayCreateShipmentMutation, FastwayCreateShipmentMutationVariables>;

/**
 * __useFastwayCreateShipmentMutation__
 *
 * To run a mutation, you first call `useFastwayCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastwayCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastwayCreateShipmentMutation, { data, loading, error }] = useFastwayCreateShipmentMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      consignment: // value for 'consignment'
 *   },
 * });
 */
export function useFastwayCreateShipmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FastwayCreateShipmentMutation, FastwayCreateShipmentMutationVariables>) {
        return ApolloReactHooks.useMutation<FastwayCreateShipmentMutation, FastwayCreateShipmentMutationVariables>(FastwayCreateShipmentDocument, baseOptions);
      }
export type FastwayCreateShipmentMutationHookResult = ReturnType<typeof useFastwayCreateShipmentMutation>;
export type FastwayCreateShipmentMutationResult = ApolloReactCommon.MutationResult<FastwayCreateShipmentMutation>;
export type FastwayCreateShipmentMutationOptions = ApolloReactCommon.BaseMutationOptions<FastwayCreateShipmentMutation, FastwayCreateShipmentMutationVariables>;
export const FastwayGetQuoteDocument = gql`
    query FastwayGetQuote($teamId: String!, $consignment: FastwayConsignmentInput!) {
  FastwayGetQuote(teamId: $teamId, consignment: $consignment) {
    status
    quote {
      priceInc
      description
      packageType
      satchelSize
      courierId
    }
  }
}
    `;

/**
 * __useFastwayGetQuoteQuery__
 *
 * To run a query within a React component, call `useFastwayGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastwayGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastwayGetQuoteQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      consignment: // value for 'consignment'
 *   },
 * });
 */
export function useFastwayGetQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FastwayGetQuoteQuery, FastwayGetQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<FastwayGetQuoteQuery, FastwayGetQuoteQueryVariables>(FastwayGetQuoteDocument, baseOptions);
      }
export function useFastwayGetQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FastwayGetQuoteQuery, FastwayGetQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FastwayGetQuoteQuery, FastwayGetQuoteQueryVariables>(FastwayGetQuoteDocument, baseOptions);
        }
export type FastwayGetQuoteQueryHookResult = ReturnType<typeof useFastwayGetQuoteQuery>;
export type FastwayGetQuoteLazyQueryHookResult = ReturnType<typeof useFastwayGetQuoteLazyQuery>;
export type FastwayGetQuoteQueryResult = ApolloReactCommon.QueryResult<FastwayGetQuoteQuery, FastwayGetQuoteQueryVariables>;
export const AddLogDocument = gql`
    mutation AddLog($teamId: String!, $tableId: String!, $tableName: LogTableName!, $content: String!) {
  AddLog(teamId: $teamId, tableId: $tableId, tableName: $tableName, content: $content) {
    status
    log {
      id
      teamId
      tableId
      tableName
      content
      type
      active
      createdAt
      updatedAt
      createdBy {
        id
        lastName
        firstName
      }
      updatedBy {
        id
        lastName
        firstName
      }
      comments
    }
  }
}
    `;
export type AddLogMutationFn = ApolloReactCommon.MutationFunction<AddLogMutation, AddLogMutationVariables>;

/**
 * __useAddLogMutation__
 *
 * To run a mutation, you first call `useAddLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLogMutation, { data, loading, error }] = useAddLogMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tableId: // value for 'tableId'
 *      tableName: // value for 'tableName'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAddLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLogMutation, AddLogMutationVariables>) {
        return ApolloReactHooks.useMutation<AddLogMutation, AddLogMutationVariables>(AddLogDocument, baseOptions);
      }
export type AddLogMutationHookResult = ReturnType<typeof useAddLogMutation>;
export type AddLogMutationResult = ApolloReactCommon.MutationResult<AddLogMutation>;
export type AddLogMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLogMutation, AddLogMutationVariables>;
export const DeleteLogDocument = gql`
    mutation DeleteLog($logId: String!) {
  DeleteLog(logId: $logId) {
    status
    log {
      id
      active
    }
  }
}
    `;
export type DeleteLogMutationFn = ApolloReactCommon.MutationFunction<DeleteLogMutation, DeleteLogMutationVariables>;

/**
 * __useDeleteLogMutation__
 *
 * To run a mutation, you first call `useDeleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogMutation, { data, loading, error }] = useDeleteLogMutation({
 *   variables: {
 *      logId: // value for 'logId'
 *   },
 * });
 */
export function useDeleteLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLogMutation, DeleteLogMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLogMutation, DeleteLogMutationVariables>(DeleteLogDocument, baseOptions);
      }
export type DeleteLogMutationHookResult = ReturnType<typeof useDeleteLogMutation>;
export type DeleteLogMutationResult = ApolloReactCommon.MutationResult<DeleteLogMutation>;
export type DeleteLogMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLogMutation, DeleteLogMutationVariables>;
export const EditLogDocument = gql`
    mutation EditLog($logId: String!, $content: String!) {
  EditLog(logId: $logId, content: $content) {
    status
    log {
      id
      content
      updatedAt
    }
  }
}
    `;
export type EditLogMutationFn = ApolloReactCommon.MutationFunction<EditLogMutation, EditLogMutationVariables>;

/**
 * __useEditLogMutation__
 *
 * To run a mutation, you first call `useEditLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLogMutation, { data, loading, error }] = useEditLogMutation({
 *   variables: {
 *      logId: // value for 'logId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useEditLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditLogMutation, EditLogMutationVariables>) {
        return ApolloReactHooks.useMutation<EditLogMutation, EditLogMutationVariables>(EditLogDocument, baseOptions);
      }
export type EditLogMutationHookResult = ReturnType<typeof useEditLogMutation>;
export type EditLogMutationResult = ApolloReactCommon.MutationResult<EditLogMutation>;
export type EditLogMutationOptions = ApolloReactCommon.BaseMutationOptions<EditLogMutation, EditLogMutationVariables>;
export const LogsDocument = gql`
    query Logs($teamId: String!, $skip: Int!, $take: Int!, $tableName: String, $tableId: String, $type: String) {
  Logs(teamId: $teamId, skip: $skip, take: $take, tableName: $tableName, tableId: $tableId, type: $type) {
    status
    logs {
      id
      active
      content
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
      }
      comments
    }
    total
    skip
    take
  }
}
    `;

/**
 * __useLogsQuery__
 *
 * To run a query within a React component, call `useLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      tableName: // value for 'tableName'
 *      tableId: // value for 'tableId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogsQuery, LogsQueryVariables>) {
        return ApolloReactHooks.useQuery<LogsQuery, LogsQueryVariables>(LogsDocument, baseOptions);
      }
export function useLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogsQuery, LogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogsQuery, LogsQueryVariables>(LogsDocument, baseOptions);
        }
export type LogsQueryHookResult = ReturnType<typeof useLogsQuery>;
export type LogsLazyQueryHookResult = ReturnType<typeof useLogsLazyQuery>;
export type LogsQueryResult = ApolloReactCommon.QueryResult<LogsQuery, LogsQueryVariables>;
export const CancelOrderDocument = gql`
    mutation CancelOrder($cancelOrder: CancelOrderInput!) {
  CancelOrder(cancelOrder: $cancelOrder) {
    status
  }
}
    `;
export type CancelOrderMutationFn = ApolloReactCommon.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      cancelOrder: // value for 'cancelOrder'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, baseOptions);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = ApolloReactCommon.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($teamChannelId: String!, $data: CreateOrderInput!) {
  CreateOrder(teamChannelId: $teamChannelId, data: $data) {
    status
    order {
      ...sharedOrderFragment
    }
  }
}
    ${SharedOrderFragmentFragmentDoc}`;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateXeroInvoicesDocument = gql`
    mutation CreateXeroInvoices($teamId: String!) {
  CreateXeroInvoices(teamId: $teamId) {
    status
  }
}
    `;
export type CreateXeroInvoicesMutationFn = ApolloReactCommon.MutationFunction<CreateXeroInvoicesMutation, CreateXeroInvoicesMutationVariables>;

/**
 * __useCreateXeroInvoicesMutation__
 *
 * To run a mutation, you first call `useCreateXeroInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateXeroInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createXeroInvoicesMutation, { data, loading, error }] = useCreateXeroInvoicesMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateXeroInvoicesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateXeroInvoicesMutation, CreateXeroInvoicesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateXeroInvoicesMutation, CreateXeroInvoicesMutationVariables>(CreateXeroInvoicesDocument, baseOptions);
      }
export type CreateXeroInvoicesMutationHookResult = ReturnType<typeof useCreateXeroInvoicesMutation>;
export type CreateXeroInvoicesMutationResult = ApolloReactCommon.MutationResult<CreateXeroInvoicesMutation>;
export type CreateXeroInvoicesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateXeroInvoicesMutation, CreateXeroInvoicesMutationVariables>;
export const CreateXeroInvoiceDocument = gql`
    mutation CreateXeroInvoice($orderId: String!, $teamID: String!) {
  CreateXeroInvoice(orderId: $orderId, teamID: $teamID) {
    status
  }
}
    `;
export type CreateXeroInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateXeroInvoiceMutation, CreateXeroInvoiceMutationVariables>;

/**
 * __useCreateXeroInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateXeroInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateXeroInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createXeroInvoiceMutation, { data, loading, error }] = useCreateXeroInvoiceMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useCreateXeroInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateXeroInvoiceMutation, CreateXeroInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateXeroInvoiceMutation, CreateXeroInvoiceMutationVariables>(CreateXeroInvoiceDocument, baseOptions);
      }
export type CreateXeroInvoiceMutationHookResult = ReturnType<typeof useCreateXeroInvoiceMutation>;
export type CreateXeroInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateXeroInvoiceMutation>;
export type CreateXeroInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateXeroInvoiceMutation, CreateXeroInvoiceMutationVariables>;
export const DeleteShipmentDocument = gql`
    mutation DeleteShipment($shipmentId: String!) {
  DeleteShipment(shipmentId: $shipmentId) {
    status
    shipment {
      id
      active
      orderId
      trackNumber
      courierId
      courier {
        id
        name
      }
      noOfBoxes
      totalCost
      totalWeight
      status
      externalId
      labelUrl
      externalRes
      createdAt
      updatedAt
    }
  }
}
    `;
export type DeleteShipmentMutationFn = ApolloReactCommon.MutationFunction<DeleteShipmentMutation, DeleteShipmentMutationVariables>;

/**
 * __useDeleteShipmentMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShipmentMutation, { data, loading, error }] = useDeleteShipmentMutation({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *   },
 * });
 */
export function useDeleteShipmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShipmentMutation, DeleteShipmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShipmentMutation, DeleteShipmentMutationVariables>(DeleteShipmentDocument, baseOptions);
      }
export type DeleteShipmentMutationHookResult = ReturnType<typeof useDeleteShipmentMutation>;
export type DeleteShipmentMutationResult = ApolloReactCommon.MutationResult<DeleteShipmentMutation>;
export type DeleteShipmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShipmentMutation, DeleteShipmentMutationVariables>;
export const ExportOrdersDocument = gql`
    mutation exportOrders($teamId: String!, $date: String, $teamChannelIDs: [String!], $customerName: String, $customerEmail: String, $username: String, $orderNumber: String, $productName: String, $sku: String) {
  ExportOrders(teamId: $teamId, date: $date, teamChannelIDs: $teamChannelIDs, customerName: $customerName, customerEmail: $customerEmail, username: $username, orderNumber: $orderNumber, productName: $productName, sku: $sku) {
    downloadUrl
    status
  }
}
    `;
export type ExportOrdersMutationFn = ApolloReactCommon.MutationFunction<ExportOrdersMutation, ExportOrdersMutationVariables>;

/**
 * __useExportOrdersMutation__
 *
 * To run a mutation, you first call `useExportOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportOrdersMutation, { data, loading, error }] = useExportOrdersMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      date: // value for 'date'
 *      teamChannelIDs: // value for 'teamChannelIDs'
 *      customerName: // value for 'customerName'
 *      customerEmail: // value for 'customerEmail'
 *      username: // value for 'username'
 *      orderNumber: // value for 'orderNumber'
 *      productName: // value for 'productName'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useExportOrdersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportOrdersMutation, ExportOrdersMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportOrdersMutation, ExportOrdersMutationVariables>(ExportOrdersDocument, baseOptions);
      }
export type ExportOrdersMutationHookResult = ReturnType<typeof useExportOrdersMutation>;
export type ExportOrdersMutationResult = ApolloReactCommon.MutationResult<ExportOrdersMutation>;
export type ExportOrdersMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportOrdersMutation, ExportOrdersMutationVariables>;
export const InvoicePdfCreationDocument = gql`
    mutation InvoicePdfCreation($orderId: String!) {
  InvoicePdfCreation(orderId: $orderId) {
    status
    url
  }
}
    `;
export type InvoicePdfCreationMutationFn = ApolloReactCommon.MutationFunction<InvoicePdfCreationMutation, InvoicePdfCreationMutationVariables>;

/**
 * __useInvoicePdfCreationMutation__
 *
 * To run a mutation, you first call `useInvoicePdfCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoicePdfCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoicePdfCreationMutation, { data, loading, error }] = useInvoicePdfCreationMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useInvoicePdfCreationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoicePdfCreationMutation, InvoicePdfCreationMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoicePdfCreationMutation, InvoicePdfCreationMutationVariables>(InvoicePdfCreationDocument, baseOptions);
      }
export type InvoicePdfCreationMutationHookResult = ReturnType<typeof useInvoicePdfCreationMutation>;
export type InvoicePdfCreationMutationResult = ApolloReactCommon.MutationResult<InvoicePdfCreationMutation>;
export type InvoicePdfCreationMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoicePdfCreationMutation, InvoicePdfCreationMutationVariables>;
export const MagentoOrderMarkAsPaidDocument = gql`
    mutation magentoOrderMarkAsPaid($markAsPaid: MagentoOrderMarkAsPaidInput!) {
  magentoOrderMarkAsPaid(markAsPaid: $markAsPaid) {
    status
  }
}
    `;
export type MagentoOrderMarkAsPaidMutationFn = ApolloReactCommon.MutationFunction<MagentoOrderMarkAsPaidMutation, MagentoOrderMarkAsPaidMutationVariables>;

/**
 * __useMagentoOrderMarkAsPaidMutation__
 *
 * To run a mutation, you first call `useMagentoOrderMarkAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagentoOrderMarkAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magentoOrderMarkAsPaidMutation, { data, loading, error }] = useMagentoOrderMarkAsPaidMutation({
 *   variables: {
 *      markAsPaid: // value for 'markAsPaid'
 *   },
 * });
 */
export function useMagentoOrderMarkAsPaidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MagentoOrderMarkAsPaidMutation, MagentoOrderMarkAsPaidMutationVariables>) {
        return ApolloReactHooks.useMutation<MagentoOrderMarkAsPaidMutation, MagentoOrderMarkAsPaidMutationVariables>(MagentoOrderMarkAsPaidDocument, baseOptions);
      }
export type MagentoOrderMarkAsPaidMutationHookResult = ReturnType<typeof useMagentoOrderMarkAsPaidMutation>;
export type MagentoOrderMarkAsPaidMutationResult = ApolloReactCommon.MutationResult<MagentoOrderMarkAsPaidMutation>;
export type MagentoOrderMarkAsPaidMutationOptions = ApolloReactCommon.BaseMutationOptions<MagentoOrderMarkAsPaidMutation, MagentoOrderMarkAsPaidMutationVariables>;
export const PullEbayOpenOrdersDocument = gql`
    mutation PullEbayOpenOrders($teamChannelID: String!) {
  PullEbayOpenOrders(teamChannelID: $teamChannelID) {
    status
    numberOfOrdersCreated
    numberOfCustomersCreated
  }
}
    `;
export type PullEbayOpenOrdersMutationFn = ApolloReactCommon.MutationFunction<PullEbayOpenOrdersMutation, PullEbayOpenOrdersMutationVariables>;

/**
 * __usePullEbayOpenOrdersMutation__
 *
 * To run a mutation, you first call `usePullEbayOpenOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePullEbayOpenOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pullEbayOpenOrdersMutation, { data, loading, error }] = usePullEbayOpenOrdersMutation({
 *   variables: {
 *      teamChannelID: // value for 'teamChannelID'
 *   },
 * });
 */
export function usePullEbayOpenOrdersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PullEbayOpenOrdersMutation, PullEbayOpenOrdersMutationVariables>) {
        return ApolloReactHooks.useMutation<PullEbayOpenOrdersMutation, PullEbayOpenOrdersMutationVariables>(PullEbayOpenOrdersDocument, baseOptions);
      }
export type PullEbayOpenOrdersMutationHookResult = ReturnType<typeof usePullEbayOpenOrdersMutation>;
export type PullEbayOpenOrdersMutationResult = ApolloReactCommon.MutationResult<PullEbayOpenOrdersMutation>;
export type PullEbayOpenOrdersMutationOptions = ApolloReactCommon.BaseMutationOptions<PullEbayOpenOrdersMutation, PullEbayOpenOrdersMutationVariables>;
export const SendInvoiceEmailMutationDocument = gql`
    mutation SendInvoiceEmailMutation($args: SendInvoiceEmailInput!) {
  SendInvoiceEmailMutation(sendInvoiceEmailArg: $args) {
    status
  }
}
    `;
export type SendInvoiceEmailMutationMutationFn = ApolloReactCommon.MutationFunction<SendInvoiceEmailMutationMutation, SendInvoiceEmailMutationMutationVariables>;

/**
 * __useSendInvoiceEmailMutationMutation__
 *
 * To run a mutation, you first call `useSendInvoiceEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceEmailMutationMutation, { data, loading, error }] = useSendInvoiceEmailMutationMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSendInvoiceEmailMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendInvoiceEmailMutationMutation, SendInvoiceEmailMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendInvoiceEmailMutationMutation, SendInvoiceEmailMutationMutationVariables>(SendInvoiceEmailMutationDocument, baseOptions);
      }
export type SendInvoiceEmailMutationMutationHookResult = ReturnType<typeof useSendInvoiceEmailMutationMutation>;
export type SendInvoiceEmailMutationMutationResult = ApolloReactCommon.MutationResult<SendInvoiceEmailMutationMutation>;
export type SendInvoiceEmailMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendInvoiceEmailMutationMutation, SendInvoiceEmailMutationMutationVariables>;
export const UpdateManualOrderDocument = gql`
    mutation UpdateManualOrder($teamChannelId: String!, $data: UpdateManualOrderInput!) {
  UpdateManualOrder(teamChannelId: $teamChannelId, data: $data) {
    status
  }
}
    `;
export type UpdateManualOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateManualOrderMutation, UpdateManualOrderMutationVariables>;

/**
 * __useUpdateManualOrderMutation__
 *
 * To run a mutation, you first call `useUpdateManualOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualOrderMutation, { data, loading, error }] = useUpdateManualOrderMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateManualOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateManualOrderMutation, UpdateManualOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateManualOrderMutation, UpdateManualOrderMutationVariables>(UpdateManualOrderDocument, baseOptions);
      }
export type UpdateManualOrderMutationHookResult = ReturnType<typeof useUpdateManualOrderMutation>;
export type UpdateManualOrderMutationResult = ApolloReactCommon.MutationResult<UpdateManualOrderMutation>;
export type UpdateManualOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateManualOrderMutation, UpdateManualOrderMutationVariables>;
export const UpdateOrderMutationDocument = gql`
    mutation UpdateOrderMutation($order: UpdateOrderInput!) {
  UpdateOrderMutation(updateOrderArg: $order) {
    status
    order {
      id
      totalAmount
      totalAmtInclTax
      totalTaxAmt
      amountBase
      orderItems {
        id
        unitPriceExclGST
        totalPrice
        taxAmt
        taxRate
        productTaxClassId
        totalAmtInclTax
      }
    }
  }
}
    `;
export type UpdateOrderMutationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>;

/**
 * __useUpdateOrderMutationMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutationMutation, { data, loading, error }] = useUpdateOrderMutationMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>(UpdateOrderMutationDocument, baseOptions);
      }
export type UpdateOrderMutationMutationHookResult = ReturnType<typeof useUpdateOrderMutationMutation>;
export type UpdateOrderMutationMutationResult = ApolloReactCommon.MutationResult<UpdateOrderMutationMutation>;
export type UpdateOrderMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>;
export const UpdateOrderDiscountDocument = gql`
    mutation UpdateOrderDiscount($discount: OrderDiscountInput!) {
  UpdateOrderDiscount(discount: $discount) {
    status
  }
}
    `;
export type UpdateOrderDiscountMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderDiscountMutation, UpdateOrderDiscountMutationVariables>;

/**
 * __useUpdateOrderDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateOrderDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderDiscountMutation, { data, loading, error }] = useUpdateOrderDiscountMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useUpdateOrderDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderDiscountMutation, UpdateOrderDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderDiscountMutation, UpdateOrderDiscountMutationVariables>(UpdateOrderDiscountDocument, baseOptions);
      }
export type UpdateOrderDiscountMutationHookResult = ReturnType<typeof useUpdateOrderDiscountMutation>;
export type UpdateOrderDiscountMutationResult = ApolloReactCommon.MutationResult<UpdateOrderDiscountMutation>;
export type UpdateOrderDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderDiscountMutation, UpdateOrderDiscountMutationVariables>;
export const UpdateShippingCostDocument = gql`
    mutation UpdateShippingCost($orderId: String!, $shippingServiceCode: String, $shippingCarrierCode: String, $totalShippingAmtInclTax: Float!) {
  UpdateShippingCost(orderId: $orderId, shippingServiceCode: $shippingServiceCode, shippingCarrierCode: $shippingCarrierCode, totalShippingAmtInclTax: $totalShippingAmtInclTax) {
    status
    order {
      id
      shippingServiceCode
      shippingCarrierCode
      shippingTaxAmt
      shippingTaxRate
      totalShippingAmt
      totalShippingAmtInclTax
      totalAmount
      totalAmtInclTax
      totalTaxAmt
    }
  }
}
    `;
export type UpdateShippingCostMutationFn = ApolloReactCommon.MutationFunction<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>;

/**
 * __useUpdateShippingCostMutation__
 *
 * To run a mutation, you first call `useUpdateShippingCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingCostMutation, { data, loading, error }] = useUpdateShippingCostMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      shippingServiceCode: // value for 'shippingServiceCode'
 *      shippingCarrierCode: // value for 'shippingCarrierCode'
 *      totalShippingAmtInclTax: // value for 'totalShippingAmtInclTax'
 *   },
 * });
 */
export function useUpdateShippingCostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>(UpdateShippingCostDocument, baseOptions);
      }
export type UpdateShippingCostMutationHookResult = ReturnType<typeof useUpdateShippingCostMutation>;
export type UpdateShippingCostMutationResult = ApolloReactCommon.MutationResult<UpdateShippingCostMutation>;
export type UpdateShippingCostMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>;
export const QueryEbayOpenOrdersQtyDocument = gql`
    query queryEbayOpenOrdersQty($teamChannelID: String!) {
  queryEbayOpenOrdersQty(teamChannelID: $teamChannelID) {
    status
    numberOfOrders
  }
}
    `;

/**
 * __useQueryEbayOpenOrdersQtyQuery__
 *
 * To run a query within a React component, call `useQueryEbayOpenOrdersQtyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayOpenOrdersQtyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayOpenOrdersQtyQuery({
 *   variables: {
 *      teamChannelID: // value for 'teamChannelID'
 *   },
 * });
 */
export function useQueryEbayOpenOrdersQtyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayOpenOrdersQtyQuery, QueryEbayOpenOrdersQtyQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayOpenOrdersQtyQuery, QueryEbayOpenOrdersQtyQueryVariables>(QueryEbayOpenOrdersQtyDocument, baseOptions);
      }
export function useQueryEbayOpenOrdersQtyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayOpenOrdersQtyQuery, QueryEbayOpenOrdersQtyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayOpenOrdersQtyQuery, QueryEbayOpenOrdersQtyQueryVariables>(QueryEbayOpenOrdersQtyDocument, baseOptions);
        }
export type QueryEbayOpenOrdersQtyQueryHookResult = ReturnType<typeof useQueryEbayOpenOrdersQtyQuery>;
export type QueryEbayOpenOrdersQtyLazyQueryHookResult = ReturnType<typeof useQueryEbayOpenOrdersQtyLazyQuery>;
export type QueryEbayOpenOrdersQtyQueryResult = ApolloReactCommon.QueryResult<QueryEbayOpenOrdersQtyQuery, QueryEbayOpenOrdersQtyQueryVariables>;
export const GetOrderByIdDocument = gql`
    query GetOrderById($orderId: String!) {
  GetOrderById(orderId: $orderId) {
    status
    order {
      ...sharedOrderFragment
    }
  }
}
    ${SharedOrderFragmentFragmentDoc}`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, baseOptions);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, baseOptions);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = ApolloReactCommon.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export const GetOrderStatusDocument = gql`
    query GetOrderStatus($teamId: String!) {
  GetOrderStatus(teamId: $teamId) {
    status
    orderStatus {
      id
      name
      code
      order
    }
  }
}
    `;

/**
 * __useGetOrderStatusQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetOrderStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderStatusQuery, GetOrderStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(GetOrderStatusDocument, baseOptions);
      }
export function useGetOrderStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderStatusQuery, GetOrderStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(GetOrderStatusDocument, baseOptions);
        }
export type GetOrderStatusQueryHookResult = ReturnType<typeof useGetOrderStatusQuery>;
export type GetOrderStatusLazyQueryHookResult = ReturnType<typeof useGetOrderStatusLazyQuery>;
export type GetOrderStatusQueryResult = ApolloReactCommon.QueryResult<GetOrderStatusQuery, GetOrderStatusQueryVariables>;
export const OrderAutoCompleteDocument = gql`
    query OrderAutoComplete($teamID: String!, $orderNumber: String) {
  Orders(teamID: $teamID, skip: 0, take: 5, orderNumber: $orderNumber) {
    status
    total
    take
    skip
    orders {
      id
      orderNumber
    }
  }
}
    `;

/**
 * __useOrderAutoCompleteQuery__
 *
 * To run a query within a React component, call `useOrderAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAutoCompleteQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useOrderAutoCompleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderAutoCompleteQuery, OrderAutoCompleteQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderAutoCompleteQuery, OrderAutoCompleteQueryVariables>(OrderAutoCompleteDocument, baseOptions);
      }
export function useOrderAutoCompleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderAutoCompleteQuery, OrderAutoCompleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderAutoCompleteQuery, OrderAutoCompleteQueryVariables>(OrderAutoCompleteDocument, baseOptions);
        }
export type OrderAutoCompleteQueryHookResult = ReturnType<typeof useOrderAutoCompleteQuery>;
export type OrderAutoCompleteLazyQueryHookResult = ReturnType<typeof useOrderAutoCompleteLazyQuery>;
export type OrderAutoCompleteQueryResult = ApolloReactCommon.QueryResult<OrderAutoCompleteQuery, OrderAutoCompleteQueryVariables>;
export const OrdersDocument = gql`
    query Orders($teamID: String!, $skip: Int!, $take: Int!, $status: [String!], $keyword: String, $date: String, $teamChannelIDs: [String!], $sortKey: String, $sortOrder: String, $customerName: String, $customerEmail: String, $username: String, $orderNumber: String, $productName: String, $sku: String, $isXeroSynced: String, $pinShipByOrder: Boolean) {
  Orders(teamID: $teamID, skip: $skip, take: $take, status: $status, keyword: $keyword, date: $date, teamChannelIDs: $teamChannelIDs, sortKey: $sortKey, sortOrder: $sortOrder, customerName: $customerName, customerEmail: $customerEmail, productName: $productName, username: $username, orderNumber: $orderNumber, sku: $sku, isXeroSynced: $isXeroSynced, pinShipByOrder: $pinShipByOrder) {
    status
    total
    take
    skip
    orders {
      ...sharedOrderFragment
    }
    totalAmtInclTax
    totalOutstanding
  }
}
    ${SharedOrderFragmentFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      status: // value for 'status'
 *      keyword: // value for 'keyword'
 *      date: // value for 'date'
 *      teamChannelIDs: // value for 'teamChannelIDs'
 *      sortKey: // value for 'sortKey'
 *      sortOrder: // value for 'sortOrder'
 *      customerName: // value for 'customerName'
 *      customerEmail: // value for 'customerEmail'
 *      username: // value for 'username'
 *      orderNumber: // value for 'orderNumber'
 *      productName: // value for 'productName'
 *      sku: // value for 'sku'
 *      isXeroSynced: // value for 'isXeroSynced'
 *      pinShipByOrder: // value for 'pinShipByOrder'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrdersByCustomerDocument = gql`
    query OrdersByCustomer($teamID: String!, $customerId: String!, $skip: Int!, $take: Int!) {
  OrdersByCustomer(teamID: $teamID, customerId: $customerId, skip: $skip, take: $take) {
    status
    total
    take
    skip
    orders {
      id
      status {
        id
        name
        code
        order
      }
      currency
      orderNumber
      totalAmtInclTax
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useOrdersByCustomerQuery__
 *
 * To run a query within a React component, call `useOrdersByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersByCustomerQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      customerId: // value for 'customerId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useOrdersByCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersByCustomerQuery, OrdersByCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersByCustomerQuery, OrdersByCustomerQueryVariables>(OrdersByCustomerDocument, baseOptions);
      }
export function useOrdersByCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersByCustomerQuery, OrdersByCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersByCustomerQuery, OrdersByCustomerQueryVariables>(OrdersByCustomerDocument, baseOptions);
        }
export type OrdersByCustomerQueryHookResult = ReturnType<typeof useOrdersByCustomerQuery>;
export type OrdersByCustomerLazyQueryHookResult = ReturnType<typeof useOrdersByCustomerLazyQuery>;
export type OrdersByCustomerQueryResult = ApolloReactCommon.QueryResult<OrdersByCustomerQuery, OrdersByCustomerQueryVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($payment: PaymentInput!) {
  createPayment(payment: $payment) {
    status
  }
}
    `;
export type CreatePaymentMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      payment: // value for 'payment'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const DeletePaymentDocument = gql`
    mutation deletePayment($deletePayment: DeletePaymentInput!) {
  deletePayment(deletePayment: $deletePayment) {
    status
  }
}
    `;
export type DeletePaymentMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentMutation, DeletePaymentMutationVariables>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      deletePayment: // value for 'deletePayment'
 *   },
 * });
 */
export function useDeletePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, baseOptions);
      }
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = ApolloReactCommon.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentMutation, DeletePaymentMutationVariables>;
export const GetPaymentMethodsDocument = gql`
    query GetPaymentMethods {
  GetPaymentMethods {
    status
    paymentMethods {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export const AdjustInventoryDocument = gql`
    mutation AdjustInventory($productId: String!, $changeInQty: Int!, $costPerUnit: Float, $isTaxIncluded: Boolean, $comment: String) {
  AdjustInventory(productId: $productId, changeInQty: $changeInQty, costPerUnit: $costPerUnit, isTaxIncluded: $isTaxIncluded, comment: $comment) {
    status
    product {
      id
      stockOnHand
      totalInventoryCost
    }
    sohBeforeMutation
    totalInventoryValueBeforeMutation
  }
}
    `;
export type AdjustInventoryMutationFn = ApolloReactCommon.MutationFunction<AdjustInventoryMutation, AdjustInventoryMutationVariables>;

/**
 * __useAdjustInventoryMutation__
 *
 * To run a mutation, you first call `useAdjustInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjustInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustInventoryMutation, { data, loading, error }] = useAdjustInventoryMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      changeInQty: // value for 'changeInQty'
 *      costPerUnit: // value for 'costPerUnit'
 *      isTaxIncluded: // value for 'isTaxIncluded'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAdjustInventoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdjustInventoryMutation, AdjustInventoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AdjustInventoryMutation, AdjustInventoryMutationVariables>(AdjustInventoryDocument, baseOptions);
      }
export type AdjustInventoryMutationHookResult = ReturnType<typeof useAdjustInventoryMutation>;
export type AdjustInventoryMutationResult = ApolloReactCommon.MutationResult<AdjustInventoryMutation>;
export type AdjustInventoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AdjustInventoryMutation, AdjustInventoryMutationVariables>;
export const CreateBulkProductTemplateDocument = gql`
    mutation CreateBulkProductTemplate($teamID: String!) {
  CreateBulkProductTemplate(teamID: $teamID) {
    status
    url
  }
}
    `;
export type CreateBulkProductTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateBulkProductTemplateMutation, CreateBulkProductTemplateMutationVariables>;

/**
 * __useCreateBulkProductTemplateMutation__
 *
 * To run a mutation, you first call `useCreateBulkProductTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkProductTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkProductTemplateMutation, { data, loading, error }] = useCreateBulkProductTemplateMutation({
 *   variables: {
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useCreateBulkProductTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkProductTemplateMutation, CreateBulkProductTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkProductTemplateMutation, CreateBulkProductTemplateMutationVariables>(CreateBulkProductTemplateDocument, baseOptions);
      }
export type CreateBulkProductTemplateMutationHookResult = ReturnType<typeof useCreateBulkProductTemplateMutation>;
export type CreateBulkProductTemplateMutationResult = ApolloReactCommon.MutationResult<CreateBulkProductTemplateMutation>;
export type CreateBulkProductTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkProductTemplateMutation, CreateBulkProductTemplateMutationVariables>;
export const CreateBulkProductUpdateReportDocument = gql`
    mutation CreateBulkProductUpdateReport($jobId: String!) {
  CreateBulkProductUpdateReport(jobId: $jobId) {
    status
    url
  }
}
    `;
export type CreateBulkProductUpdateReportMutationFn = ApolloReactCommon.MutationFunction<CreateBulkProductUpdateReportMutation, CreateBulkProductUpdateReportMutationVariables>;

/**
 * __useCreateBulkProductUpdateReportMutation__
 *
 * To run a mutation, you first call `useCreateBulkProductUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkProductUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkProductUpdateReportMutation, { data, loading, error }] = useCreateBulkProductUpdateReportMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useCreateBulkProductUpdateReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkProductUpdateReportMutation, CreateBulkProductUpdateReportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkProductUpdateReportMutation, CreateBulkProductUpdateReportMutationVariables>(CreateBulkProductUpdateReportDocument, baseOptions);
      }
export type CreateBulkProductUpdateReportMutationHookResult = ReturnType<typeof useCreateBulkProductUpdateReportMutation>;
export type CreateBulkProductUpdateReportMutationResult = ApolloReactCommon.MutationResult<CreateBulkProductUpdateReportMutation>;
export type CreateBulkProductUpdateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkProductUpdateReportMutation, CreateBulkProductUpdateReportMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($product: ProductInput!) {
  CreateProduct(product: $product) {
    status
    product
  }
}
    `;
export type CreateProductMutationFn = ApolloReactCommon.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = ApolloReactCommon.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: String!) {
  DeleteProduct(id: $id) {
    status
    deletedId
  }
}
    `;
export type DeleteProductMutationFn = ApolloReactCommon.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, baseOptions);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = ApolloReactCommon.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const ExportProductsDocument = gql`
    mutation ExportProducts($teamId: String!) {
  ExportProducts(teamId: $teamId) {
    status
    downloadUrl
  }
}
    `;
export type ExportProductsMutationFn = ApolloReactCommon.MutationFunction<ExportProductsMutation, ExportProductsMutationVariables>;

/**
 * __useExportProductsMutation__
 *
 * To run a mutation, you first call `useExportProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportProductsMutation, { data, loading, error }] = useExportProductsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useExportProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportProductsMutation, ExportProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportProductsMutation, ExportProductsMutationVariables>(ExportProductsDocument, baseOptions);
      }
export type ExportProductsMutationHookResult = ReturnType<typeof useExportProductsMutation>;
export type ExportProductsMutationResult = ApolloReactCommon.MutationResult<ExportProductsMutation>;
export type ExportProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportProductsMutation, ExportProductsMutationVariables>;
export const ImportEbayListingsDocument = gql`
    mutation ImportEbayListings($teamChannelID: String!) {
  ImportEbayListings(teamChannelID: $teamChannelID) {
    status
    numberOfProductsCreated
  }
}
    `;
export type ImportEbayListingsMutationFn = ApolloReactCommon.MutationFunction<ImportEbayListingsMutation, ImportEbayListingsMutationVariables>;

/**
 * __useImportEbayListingsMutation__
 *
 * To run a mutation, you first call `useImportEbayListingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEbayListingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEbayListingsMutation, { data, loading, error }] = useImportEbayListingsMutation({
 *   variables: {
 *      teamChannelID: // value for 'teamChannelID'
 *   },
 * });
 */
export function useImportEbayListingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportEbayListingsMutation, ImportEbayListingsMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportEbayListingsMutation, ImportEbayListingsMutationVariables>(ImportEbayListingsDocument, baseOptions);
      }
export type ImportEbayListingsMutationHookResult = ReturnType<typeof useImportEbayListingsMutation>;
export type ImportEbayListingsMutationResult = ApolloReactCommon.MutationResult<ImportEbayListingsMutation>;
export type ImportEbayListingsMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportEbayListingsMutation, ImportEbayListingsMutationVariables>;
export const PushProductsToTeamChannelDocument = gql`
    mutation PushProductsToTeamChannel($teamChannelId: String!) {
  PushProductsToTeamChannel(teamChannelId: $teamChannelId) {
    status
  }
}
    `;
export type PushProductsToTeamChannelMutationFn = ApolloReactCommon.MutationFunction<PushProductsToTeamChannelMutation, PushProductsToTeamChannelMutationVariables>;

/**
 * __usePushProductsToTeamChannelMutation__
 *
 * To run a mutation, you first call `usePushProductsToTeamChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushProductsToTeamChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushProductsToTeamChannelMutation, { data, loading, error }] = usePushProductsToTeamChannelMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function usePushProductsToTeamChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PushProductsToTeamChannelMutation, PushProductsToTeamChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<PushProductsToTeamChannelMutation, PushProductsToTeamChannelMutationVariables>(PushProductsToTeamChannelDocument, baseOptions);
      }
export type PushProductsToTeamChannelMutationHookResult = ReturnType<typeof usePushProductsToTeamChannelMutation>;
export type PushProductsToTeamChannelMutationResult = ApolloReactCommon.MutationResult<PushProductsToTeamChannelMutation>;
export type PushProductsToTeamChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<PushProductsToTeamChannelMutation, PushProductsToTeamChannelMutationVariables>;
export const SwapEBayProductDocument = gql`
    mutation SwapEBayProduct($sourceProductId: String!, $targetProductId: String!, $mode: String!) {
  SwapEBayProduct(sourceProductId: $sourceProductId, targetProductId: $targetProductId, mode: $mode) {
    status
    errors {
      message
    }
  }
}
    `;
export type SwapEBayProductMutationFn = ApolloReactCommon.MutationFunction<SwapEBayProductMutation, SwapEBayProductMutationVariables>;

/**
 * __useSwapEBayProductMutation__
 *
 * To run a mutation, you first call `useSwapEBayProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapEBayProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapEBayProductMutation, { data, loading, error }] = useSwapEBayProductMutation({
 *   variables: {
 *      sourceProductId: // value for 'sourceProductId'
 *      targetProductId: // value for 'targetProductId'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useSwapEBayProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SwapEBayProductMutation, SwapEBayProductMutationVariables>) {
        return ApolloReactHooks.useMutation<SwapEBayProductMutation, SwapEBayProductMutationVariables>(SwapEBayProductDocument, baseOptions);
      }
export type SwapEBayProductMutationHookResult = ReturnType<typeof useSwapEBayProductMutation>;
export type SwapEBayProductMutationResult = ApolloReactCommon.MutationResult<SwapEBayProductMutation>;
export type SwapEBayProductMutationOptions = ApolloReactCommon.BaseMutationOptions<SwapEBayProductMutation, SwapEBayProductMutationVariables>;
export const SyncEbayProductsViaQueueDocument = gql`
    mutation syncEbayProductsViaQueue($teamChannelId: String!) {
  SyncEbayProductsViaQueue(teamChannelId: $teamChannelId) {
    status
  }
}
    `;
export type SyncEbayProductsViaQueueMutationFn = ApolloReactCommon.MutationFunction<SyncEbayProductsViaQueueMutation, SyncEbayProductsViaQueueMutationVariables>;

/**
 * __useSyncEbayProductsViaQueueMutation__
 *
 * To run a mutation, you first call `useSyncEbayProductsViaQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncEbayProductsViaQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncEbayProductsViaQueueMutation, { data, loading, error }] = useSyncEbayProductsViaQueueMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useSyncEbayProductsViaQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncEbayProductsViaQueueMutation, SyncEbayProductsViaQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncEbayProductsViaQueueMutation, SyncEbayProductsViaQueueMutationVariables>(SyncEbayProductsViaQueueDocument, baseOptions);
      }
export type SyncEbayProductsViaQueueMutationHookResult = ReturnType<typeof useSyncEbayProductsViaQueueMutation>;
export type SyncEbayProductsViaQueueMutationResult = ApolloReactCommon.MutationResult<SyncEbayProductsViaQueueMutation>;
export type SyncEbayProductsViaQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncEbayProductsViaQueueMutation, SyncEbayProductsViaQueueMutationVariables>;
export const SyncProductsViaQueueDocument = gql`
    mutation syncProductsViaQueue($teamChannelId: String!) {
  SyncProductsViaQueue(teamChannelId: $teamChannelId) {
    status
  }
}
    `;
export type SyncProductsViaQueueMutationFn = ApolloReactCommon.MutationFunction<SyncProductsViaQueueMutation, SyncProductsViaQueueMutationVariables>;

/**
 * __useSyncProductsViaQueueMutation__
 *
 * To run a mutation, you first call `useSyncProductsViaQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncProductsViaQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncProductsViaQueueMutation, { data, loading, error }] = useSyncProductsViaQueueMutation({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useSyncProductsViaQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncProductsViaQueueMutation, SyncProductsViaQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncProductsViaQueueMutation, SyncProductsViaQueueMutationVariables>(SyncProductsViaQueueDocument, baseOptions);
      }
export type SyncProductsViaQueueMutationHookResult = ReturnType<typeof useSyncProductsViaQueueMutation>;
export type SyncProductsViaQueueMutationResult = ApolloReactCommon.MutationResult<SyncProductsViaQueueMutation>;
export type SyncProductsViaQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncProductsViaQueueMutation, SyncProductsViaQueueMutationVariables>;
export const UpdateBulkProductsDocument = gql`
    mutation UpdateBulkProducts($fileName: String!, $teamID: String!) {
  UpdateBulkProducts(fileName: $fileName, teamID: $teamID) {
    status
    jobId
    numberOfBulkProducts
  }
}
    `;
export type UpdateBulkProductsMutationFn = ApolloReactCommon.MutationFunction<UpdateBulkProductsMutation, UpdateBulkProductsMutationVariables>;

/**
 * __useUpdateBulkProductsMutation__
 *
 * To run a mutation, you first call `useUpdateBulkProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkProductsMutation, { data, loading, error }] = useUpdateBulkProductsMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useUpdateBulkProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBulkProductsMutation, UpdateBulkProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBulkProductsMutation, UpdateBulkProductsMutationVariables>(UpdateBulkProductsDocument, baseOptions);
      }
export type UpdateBulkProductsMutationHookResult = ReturnType<typeof useUpdateBulkProductsMutation>;
export type UpdateBulkProductsMutationResult = ApolloReactCommon.MutationResult<UpdateBulkProductsMutation>;
export type UpdateBulkProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBulkProductsMutation, UpdateBulkProductsMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: String!, $product: ProductInput!) {
  UpdateProduct(id: $id, product: $product) {
    status
    product
  }
}
    `;
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateProductStockOnHandDocument = gql`
    mutation UpdateProductStockOnHand($id: String!, $stockOnHand: Int!) {
  UpdateProductStockOnHand(id: $id, stockOnHand: $stockOnHand) {
    status
    product
  }
}
    `;
export type UpdateProductStockOnHandMutationFn = ApolloReactCommon.MutationFunction<UpdateProductStockOnHandMutation, UpdateProductStockOnHandMutationVariables>;

/**
 * __useUpdateProductStockOnHandMutation__
 *
 * To run a mutation, you first call `useUpdateProductStockOnHandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductStockOnHandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductStockOnHandMutation, { data, loading, error }] = useUpdateProductStockOnHandMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stockOnHand: // value for 'stockOnHand'
 *   },
 * });
 */
export function useUpdateProductStockOnHandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductStockOnHandMutation, UpdateProductStockOnHandMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductStockOnHandMutation, UpdateProductStockOnHandMutationVariables>(UpdateProductStockOnHandDocument, baseOptions);
      }
export type UpdateProductStockOnHandMutationHookResult = ReturnType<typeof useUpdateProductStockOnHandMutation>;
export type UpdateProductStockOnHandMutationResult = ApolloReactCommon.MutationResult<UpdateProductStockOnHandMutation>;
export type UpdateProductStockOnHandMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductStockOnHandMutation, UpdateProductStockOnHandMutationVariables>;
export const TeamChannelProductUpdateDocument = gql`
    mutation TeamChannelProductUpdate($id: String!, $unitPrice: Float!, $stockOnHand: Int!) {
  TeamChannelProductUpdate(id: $id, unitPrice: $unitPrice, stockOnHand: $stockOnHand) {
    status
    teamChannelProduct
  }
}
    `;
export type TeamChannelProductUpdateMutationFn = ApolloReactCommon.MutationFunction<TeamChannelProductUpdateMutation, TeamChannelProductUpdateMutationVariables>;

/**
 * __useTeamChannelProductUpdateMutation__
 *
 * To run a mutation, you first call `useTeamChannelProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamChannelProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamChannelProductUpdateMutation, { data, loading, error }] = useTeamChannelProductUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unitPrice: // value for 'unitPrice'
 *      stockOnHand: // value for 'stockOnHand'
 *   },
 * });
 */
export function useTeamChannelProductUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamChannelProductUpdateMutation, TeamChannelProductUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamChannelProductUpdateMutation, TeamChannelProductUpdateMutationVariables>(TeamChannelProductUpdateDocument, baseOptions);
      }
export type TeamChannelProductUpdateMutationHookResult = ReturnType<typeof useTeamChannelProductUpdateMutation>;
export type TeamChannelProductUpdateMutationResult = ApolloReactCommon.MutationResult<TeamChannelProductUpdateMutation>;
export type TeamChannelProductUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamChannelProductUpdateMutation, TeamChannelProductUpdateMutationVariables>;
export const QueryEbayListingsQtyDocument = gql`
    query queryEbayListingsQty($teamChannelID: String!) {
  QueryEbayListingsQty(teamChannelID: $teamChannelID) {
    status
    numberOfListings
  }
}
    `;

/**
 * __useQueryEbayListingsQtyQuery__
 *
 * To run a query within a React component, call `useQueryEbayListingsQtyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEbayListingsQtyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEbayListingsQtyQuery({
 *   variables: {
 *      teamChannelID: // value for 'teamChannelID'
 *   },
 * });
 */
export function useQueryEbayListingsQtyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryEbayListingsQtyQuery, QueryEbayListingsQtyQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryEbayListingsQtyQuery, QueryEbayListingsQtyQueryVariables>(QueryEbayListingsQtyDocument, baseOptions);
      }
export function useQueryEbayListingsQtyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryEbayListingsQtyQuery, QueryEbayListingsQtyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryEbayListingsQtyQuery, QueryEbayListingsQtyQueryVariables>(QueryEbayListingsQtyDocument, baseOptions);
        }
export type QueryEbayListingsQtyQueryHookResult = ReturnType<typeof useQueryEbayListingsQtyQuery>;
export type QueryEbayListingsQtyLazyQueryHookResult = ReturnType<typeof useQueryEbayListingsQtyLazyQuery>;
export type QueryEbayListingsQtyQueryResult = ApolloReactCommon.QueryResult<QueryEbayListingsQtyQuery, QueryEbayListingsQtyQueryVariables>;
export const QueryProductTypeDefineDocument = gql`
    query QueryProductTypeDefine($productType: String!, $teamId: String!) {
  QueryProductTypeDefine(productType: $productType, teamId: $teamId) {
    status
    response
  }
}
    `;

/**
 * __useQueryProductTypeDefineQuery__
 *
 * To run a query within a React component, call `useQueryProductTypeDefineQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProductTypeDefineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProductTypeDefineQuery({
 *   variables: {
 *      productType: // value for 'productType'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useQueryProductTypeDefineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryProductTypeDefineQuery, QueryProductTypeDefineQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryProductTypeDefineQuery, QueryProductTypeDefineQueryVariables>(QueryProductTypeDefineDocument, baseOptions);
      }
export function useQueryProductTypeDefineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryProductTypeDefineQuery, QueryProductTypeDefineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryProductTypeDefineQuery, QueryProductTypeDefineQueryVariables>(QueryProductTypeDefineDocument, baseOptions);
        }
export type QueryProductTypeDefineQueryHookResult = ReturnType<typeof useQueryProductTypeDefineQuery>;
export type QueryProductTypeDefineLazyQueryHookResult = ReturnType<typeof useQueryProductTypeDefineLazyQuery>;
export type QueryProductTypeDefineQueryResult = ApolloReactCommon.QueryResult<QueryProductTypeDefineQuery, QueryProductTypeDefineQueryVariables>;
export const GetSeedBySkuDocument = gql`
    query GetSeedBySku($sku: String!) {
  GetSeedBySku(sku: $sku) {
    status
    productSeed {
      id
      name
      stocks
      pricingLevels
    }
  }
}
    `;

/**
 * __useGetSeedBySkuQuery__
 *
 * To run a query within a React component, call `useGetSeedBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeedBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeedBySkuQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetSeedBySkuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSeedBySkuQuery, GetSeedBySkuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSeedBySkuQuery, GetSeedBySkuQueryVariables>(GetSeedBySkuDocument, baseOptions);
      }
export function useGetSeedBySkuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSeedBySkuQuery, GetSeedBySkuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSeedBySkuQuery, GetSeedBySkuQueryVariables>(GetSeedBySkuDocument, baseOptions);
        }
export type GetSeedBySkuQueryHookResult = ReturnType<typeof useGetSeedBySkuQuery>;
export type GetSeedBySkuLazyQueryHookResult = ReturnType<typeof useGetSeedBySkuLazyQuery>;
export type GetSeedBySkuQueryResult = ApolloReactCommon.QueryResult<GetSeedBySkuQuery, GetSeedBySkuQueryVariables>;
export const ProductDocument = gql`
    query Product($teamID: String!, $productId: String!) {
  Product(teamID: $teamID, productId: $productId) {
    status
    product {
      id
      name
      sku
      unitPrice
      isForSale
      stockOnHand
      totalInventoryCost
      fullDescription
      shortDescription
      fromChannelId
      images(orderBy: {orderNum: asc}) {
        url
      }
      productAttributeValues {
        id
        value
        productAttribute {
          id
          name
        }
      }
      teamChannelProducts(where: {active: {equals: true}}) {
        id
        unitPrice
        active
        stockOnHand
        teamChannel {
          id
          active
          name
          channelID
        }
        externalListingId
        settings
      }
      attributeSet {
        id
        channelAttributeSetId
      }
      productTaxClass {
        id
        name
      }
      teamID
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
      }
export function useProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = ApolloReactCommon.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductImageDocument = gql`
    query ProductImage($teamID: String!, $productId: String!) {
  Product(teamID: $teamID, productId: $productId) {
    status
    product {
      id
      name
      images(orderBy: {orderNum: asc}) {
        url
      }
    }
  }
}
    `;

/**
 * __useProductImageQuery__
 *
 * To run a query within a React component, call `useProductImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductImageQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductImageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductImageQuery, ProductImageQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductImageQuery, ProductImageQueryVariables>(ProductImageDocument, baseOptions);
      }
export function useProductImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductImageQuery, ProductImageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductImageQuery, ProductImageQueryVariables>(ProductImageDocument, baseOptions);
        }
export type ProductImageQueryHookResult = ReturnType<typeof useProductImageQuery>;
export type ProductImageLazyQueryHookResult = ReturnType<typeof useProductImageLazyQuery>;
export type ProductImageQueryResult = ApolloReactCommon.QueryResult<ProductImageQuery, ProductImageQueryVariables>;
export const ProductSeedsDocument = gql`
    query ProductSeeds($keyword: String!) {
  ProductSeeds(keyword: $keyword) {
    status
    productSeeds {
      id
      name
      stocks
      pricingLevels
      categoryName
      apn
      ean
      brand
      isESDProduct
      rrp
      shortDescription
      upc
      length
      moq
      sku
      volume
      weight
      width
    }
  }
}
    `;

/**
 * __useProductSeedsQuery__
 *
 * To run a query within a React component, call `useProductSeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSeedsQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useProductSeedsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductSeedsQuery, ProductSeedsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductSeedsQuery, ProductSeedsQueryVariables>(ProductSeedsDocument, baseOptions);
      }
export function useProductSeedsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductSeedsQuery, ProductSeedsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductSeedsQuery, ProductSeedsQueryVariables>(ProductSeedsDocument, baseOptions);
        }
export type ProductSeedsQueryHookResult = ReturnType<typeof useProductSeedsQuery>;
export type ProductSeedsLazyQueryHookResult = ReturnType<typeof useProductSeedsLazyQuery>;
export type ProductSeedsQueryResult = ApolloReactCommon.QueryResult<ProductSeedsQuery, ProductSeedsQueryVariables>;
export const ProductsDocument = gql`
    query Products($teamID: String!, $skip: Int!, $take: Int!, $keyword: String, $sortKey: String, $sortOrder: String, $teamChannelIDs: [String!], $productIDs: [String!], $filters: [ProductsAdvancedFilterInput!]) {
  Products(teamID: $teamID, skip: $skip, take: $take, keyword: $keyword, sortKey: $sortKey, sortOrder: $sortOrder, teamChannelIDs: $teamChannelIDs, productIDs: $productIDs, filters: $filters) {
    status
    total
    take
    skip
    teamTaxClasses {
      id
      name
      isForShipping
      taxRules {
        id
        name
        priority
        taxRates {
          id
          identifier
          ratePercent
        }
      }
    }
    products {
      id
      name
      sku
      unitPrice
      unitPriceExclGST
      stockOnHand
      totalInventoryCost
      createdAt
      updatedAt
      productProp
      fromChannelId
      productTaxClass {
        id
        name
      }
      teamChannelProducts(where: {active: {equals: true}}) {
        id
        status
        unitPrice
        stockOnHand
        teamChannel {
          id
          name
          channel {
            id
            name
          }
        }
        externalListingId
      }
      images(orderBy: {orderNum: asc}) {
        id
        url
      }
      attributeSet {
        id
        name
        channelAttributeSetId
      }
      productAttributeValues {
        id
        value
        productAttribute {
          id
          name
        }
      }
    }
  }
  GetTeamChannelsByTeamId(teamId: $teamID) {
    status
    teamChannels {
      id
      name
      channel {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortKey: // value for 'sortKey'
 *      sortOrder: // value for 'sortOrder'
 *      teamChannelIDs: // value for 'teamChannelIDs'
 *      productIDs: // value for 'productIDs'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductsAutoCompleteDocument = gql`
    query ProductsAutoComplete($teamID: String!, $filters: [ProductsAdvancedFilterInput!]) {
  Products(teamID: $teamID, skip: 0, take: 5, filters: $filters) {
    status
    total
    products {
      id
      name
      sku
    }
  }
}
    `;

/**
 * __useProductsAutoCompleteQuery__
 *
 * To run a query within a React component, call `useProductsAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsAutoCompleteQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProductsAutoCompleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsAutoCompleteQuery, ProductsAutoCompleteQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsAutoCompleteQuery, ProductsAutoCompleteQueryVariables>(ProductsAutoCompleteDocument, baseOptions);
      }
export function useProductsAutoCompleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsAutoCompleteQuery, ProductsAutoCompleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsAutoCompleteQuery, ProductsAutoCompleteQueryVariables>(ProductsAutoCompleteDocument, baseOptions);
        }
export type ProductsAutoCompleteQueryHookResult = ReturnType<typeof useProductsAutoCompleteQuery>;
export type ProductsAutoCompleteLazyQueryHookResult = ReturnType<typeof useProductsAutoCompleteLazyQuery>;
export type ProductsAutoCompleteQueryResult = ApolloReactCommon.QueryResult<ProductsAutoCompleteQuery, ProductsAutoCompleteQueryVariables>;
export const QueryProductTypesDocument = gql`
    query QueryProductTypes($keyword: String!, $teamId: String!) {
  QueryProductTypes(keyword: $keyword, teamId: $teamId) {
    status
    productTypes
  }
}
    `;

/**
 * __useQueryProductTypesQuery__
 *
 * To run a query within a React component, call `useQueryProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProductTypesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useQueryProductTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryProductTypesQuery, QueryProductTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryProductTypesQuery, QueryProductTypesQueryVariables>(QueryProductTypesDocument, baseOptions);
      }
export function useQueryProductTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryProductTypesQuery, QueryProductTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryProductTypesQuery, QueryProductTypesQueryVariables>(QueryProductTypesDocument, baseOptions);
        }
export type QueryProductTypesQueryHookResult = ReturnType<typeof useQueryProductTypesQuery>;
export type QueryProductTypesLazyQueryHookResult = ReturnType<typeof useQueryProductTypesLazyQuery>;
export type QueryProductTypesQueryResult = ApolloReactCommon.QueryResult<QueryProductTypesQuery, QueryProductTypesQueryVariables>;
export const QuerySummaryReportDocument = gql`
    query QuerySummaryReport($teamId: String!) {
  QuerySummaryReport(teamId: $teamId) {
    status
    errors {
      message
    }
    response
  }
}
    `;

/**
 * __useQuerySummaryReportQuery__
 *
 * To run a query within a React component, call `useQuerySummaryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySummaryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySummaryReportQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useQuerySummaryReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuerySummaryReportQuery, QuerySummaryReportQueryVariables>) {
        return ApolloReactHooks.useQuery<QuerySummaryReportQuery, QuerySummaryReportQueryVariables>(QuerySummaryReportDocument, baseOptions);
      }
export function useQuerySummaryReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuerySummaryReportQuery, QuerySummaryReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuerySummaryReportQuery, QuerySummaryReportQueryVariables>(QuerySummaryReportDocument, baseOptions);
        }
export type QuerySummaryReportQueryHookResult = ReturnType<typeof useQuerySummaryReportQuery>;
export type QuerySummaryReportLazyQueryHookResult = ReturnType<typeof useQuerySummaryReportLazyQuery>;
export type QuerySummaryReportQueryResult = ApolloReactCommon.QueryResult<QuerySummaryReportQuery, QuerySummaryReportQueryVariables>;
export const TeamChannelSummaryDocument = gql`
    query teamChannelSummary($teamChannelId: String!) {
  TeamChannelSummary(teamChannelId: $teamChannelId) {
    status
    numberOfOrders
    numberOfProducts
    totalRevenue
  }
}
    `;

/**
 * __useTeamChannelSummaryQuery__
 *
 * To run a query within a React component, call `useTeamChannelSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamChannelSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamChannelSummaryQuery({
 *   variables: {
 *      teamChannelId: // value for 'teamChannelId'
 *   },
 * });
 */
export function useTeamChannelSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamChannelSummaryQuery, TeamChannelSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamChannelSummaryQuery, TeamChannelSummaryQueryVariables>(TeamChannelSummaryDocument, baseOptions);
      }
export function useTeamChannelSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamChannelSummaryQuery, TeamChannelSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamChannelSummaryQuery, TeamChannelSummaryQueryVariables>(TeamChannelSummaryDocument, baseOptions);
        }
export type TeamChannelSummaryQueryHookResult = ReturnType<typeof useTeamChannelSummaryQuery>;
export type TeamChannelSummaryLazyQueryHookResult = ReturnType<typeof useTeamChannelSummaryLazyQuery>;
export type TeamChannelSummaryQueryResult = ApolloReactCommon.QueryResult<TeamChannelSummaryQuery, TeamChannelSummaryQueryVariables>;
export const QueryTopCustomerSalesDocument = gql`
    query QueryTopCustomerSales($teamId: String!, $dateStart: String!, $dateEnd: String!) {
  QueryTopCustomerSalesReport(teamId: $teamId, dateStart: $dateStart, dateEnd: $dateEnd) {
    status
    errors {
      message
    }
    response
  }
}
    `;

/**
 * __useQueryTopCustomerSalesQuery__
 *
 * To run a query within a React component, call `useQueryTopCustomerSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTopCustomerSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTopCustomerSalesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useQueryTopCustomerSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryTopCustomerSalesQuery, QueryTopCustomerSalesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryTopCustomerSalesQuery, QueryTopCustomerSalesQueryVariables>(QueryTopCustomerSalesDocument, baseOptions);
      }
export function useQueryTopCustomerSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryTopCustomerSalesQuery, QueryTopCustomerSalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryTopCustomerSalesQuery, QueryTopCustomerSalesQueryVariables>(QueryTopCustomerSalesDocument, baseOptions);
        }
export type QueryTopCustomerSalesQueryHookResult = ReturnType<typeof useQueryTopCustomerSalesQuery>;
export type QueryTopCustomerSalesLazyQueryHookResult = ReturnType<typeof useQueryTopCustomerSalesLazyQuery>;
export type QueryTopCustomerSalesQueryResult = ApolloReactCommon.QueryResult<QueryTopCustomerSalesQuery, QueryTopCustomerSalesQueryVariables>;
export const QueryTopSkuSalesDocument = gql`
    query QueryTopSkuSales($teamId: String!, $dateStart: String!, $dateEnd: String!) {
  QueryTopSKUSalesReport(teamId: $teamId, dateStart: $dateStart, dateEnd: $dateEnd) {
    status
    errors {
      message
    }
    response
  }
}
    `;

/**
 * __useQueryTopSkuSalesQuery__
 *
 * To run a query within a React component, call `useQueryTopSkuSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTopSkuSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTopSkuSalesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useQueryTopSkuSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryTopSkuSalesQuery, QueryTopSkuSalesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryTopSkuSalesQuery, QueryTopSkuSalesQueryVariables>(QueryTopSkuSalesDocument, baseOptions);
      }
export function useQueryTopSkuSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryTopSkuSalesQuery, QueryTopSkuSalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryTopSkuSalesQuery, QueryTopSkuSalesQueryVariables>(QueryTopSkuSalesDocument, baseOptions);
        }
export type QueryTopSkuSalesQueryHookResult = ReturnType<typeof useQueryTopSkuSalesQuery>;
export type QueryTopSkuSalesLazyQueryHookResult = ReturnType<typeof useQueryTopSkuSalesLazyQuery>;
export type QueryTopSkuSalesQueryResult = ApolloReactCommon.QueryResult<QueryTopSkuSalesQuery, QueryTopSkuSalesQueryVariables>;
export const CreateShipmentDocument = gql`
    mutation CreateShipment($shipment: ShipmentInput!) {
  CreateShipment(shipment: $shipment) {
    status
    shipment {
      id
      orderId
      trackNumber
      courierId
      courier {
        id
        name
        code
      }
      noOfBoxes
      totalCost
      totalCostExTax
      totalWeight
      status
      externalId
      active
      createdAt
      updatedAt
      createdById
      updatedById
      externalRes
      labelUrl
    }
  }
}
    `;
export type CreateShipmentMutationFn = ApolloReactCommon.MutationFunction<CreateShipmentMutation, CreateShipmentMutationVariables>;

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *      shipment: // value for 'shipment'
 *   },
 * });
 */
export function useCreateShipmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShipmentMutation, CreateShipmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateShipmentMutation, CreateShipmentMutationVariables>(CreateShipmentDocument, baseOptions);
      }
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>;
export type CreateShipmentMutationResult = ApolloReactCommon.MutationResult<CreateShipmentMutation>;
export type CreateShipmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateShipmentMutation, CreateShipmentMutationVariables>;
export const GetCouriersDocument = gql`
    query GetCouriers {
  GetCouriers {
    status
    couriers {
      id
      name
      code
      active
    }
  }
}
    `;

/**
 * __useGetCouriersQuery__
 *
 * To run a query within a React component, call `useGetCouriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCouriersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCouriersQuery, GetCouriersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCouriersQuery, GetCouriersQueryVariables>(GetCouriersDocument, baseOptions);
      }
export function useGetCouriersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCouriersQuery, GetCouriersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCouriersQuery, GetCouriersQueryVariables>(GetCouriersDocument, baseOptions);
        }
export type GetCouriersQueryHookResult = ReturnType<typeof useGetCouriersQuery>;
export type GetCouriersLazyQueryHookResult = ReturnType<typeof useGetCouriersLazyQuery>;
export type GetCouriersQueryResult = ApolloReactCommon.QueryResult<GetCouriersQuery, GetCouriersQueryVariables>;
export const DisconnectXeroDocument = gql`
    mutation DisconnectXero($teamId: String!) {
  DisconnectXero(teamId: $teamId) {
    status
  }
}
    `;
export type DisconnectXeroMutationFn = ApolloReactCommon.MutationFunction<DisconnectXeroMutation, DisconnectXeroMutationVariables>;

/**
 * __useDisconnectXeroMutation__
 *
 * To run a mutation, you first call `useDisconnectXeroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectXeroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectXeroMutation, { data, loading, error }] = useDisconnectXeroMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDisconnectXeroMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisconnectXeroMutation, DisconnectXeroMutationVariables>) {
        return ApolloReactHooks.useMutation<DisconnectXeroMutation, DisconnectXeroMutationVariables>(DisconnectXeroDocument, baseOptions);
      }
export type DisconnectXeroMutationHookResult = ReturnType<typeof useDisconnectXeroMutation>;
export type DisconnectXeroMutationResult = ApolloReactCommon.MutationResult<DisconnectXeroMutation>;
export type DisconnectXeroMutationOptions = ApolloReactCommon.BaseMutationOptions<DisconnectXeroMutation, DisconnectXeroMutationVariables>;
export const SetTeamSmtpDocument = gql`
    mutation SetTeamSMTP($teamId: String!, $teamSMTP: TeamSMTPInput!) {
  SetTeamSMTP(teamId: $teamId, teamSMTP: $teamSMTP) {
    status
  }
}
    `;
export type SetTeamSmtpMutationFn = ApolloReactCommon.MutationFunction<SetTeamSmtpMutation, SetTeamSmtpMutationVariables>;

/**
 * __useSetTeamSmtpMutation__
 *
 * To run a mutation, you first call `useSetTeamSmtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTeamSmtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTeamSmtpMutation, { data, loading, error }] = useSetTeamSmtpMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      teamSMTP: // value for 'teamSMTP'
 *   },
 * });
 */
export function useSetTeamSmtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTeamSmtpMutation, SetTeamSmtpMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTeamSmtpMutation, SetTeamSmtpMutationVariables>(SetTeamSmtpDocument, baseOptions);
      }
export type SetTeamSmtpMutationHookResult = ReturnType<typeof useSetTeamSmtpMutation>;
export type SetTeamSmtpMutationResult = ApolloReactCommon.MutationResult<SetTeamSmtpMutation>;
export type SetTeamSmtpMutationOptions = ApolloReactCommon.BaseMutationOptions<SetTeamSmtpMutation, SetTeamSmtpMutationVariables>;
export const SetTeamXeroAccountCodeDocument = gql`
    mutation SetTeamXeroAccountCode($teamId: String!, $xeroAccountCode: String!) {
  SetTeamXeroAccountCode(teamId: $teamId, xeroAccountCode: $xeroAccountCode) {
    status
  }
}
    `;
export type SetTeamXeroAccountCodeMutationFn = ApolloReactCommon.MutationFunction<SetTeamXeroAccountCodeMutation, SetTeamXeroAccountCodeMutationVariables>;

/**
 * __useSetTeamXeroAccountCodeMutation__
 *
 * To run a mutation, you first call `useSetTeamXeroAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTeamXeroAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTeamXeroAccountCodeMutation, { data, loading, error }] = useSetTeamXeroAccountCodeMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      xeroAccountCode: // value for 'xeroAccountCode'
 *   },
 * });
 */
export function useSetTeamXeroAccountCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTeamXeroAccountCodeMutation, SetTeamXeroAccountCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTeamXeroAccountCodeMutation, SetTeamXeroAccountCodeMutationVariables>(SetTeamXeroAccountCodeDocument, baseOptions);
      }
export type SetTeamXeroAccountCodeMutationHookResult = ReturnType<typeof useSetTeamXeroAccountCodeMutation>;
export type SetTeamXeroAccountCodeMutationResult = ApolloReactCommon.MutationResult<SetTeamXeroAccountCodeMutation>;
export type SetTeamXeroAccountCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<SetTeamXeroAccountCodeMutation, SetTeamXeroAccountCodeMutationVariables>;
export const SmtpSendCheckDocument = gql`
    mutation SmtpSendCheck($teamId: String!, $sendTo: String!) {
  SmtpSendCheck(teamId: $teamId, sendTo: $sendTo) {
    status
    checkSuccess
    errorMsg
  }
}
    `;
export type SmtpSendCheckMutationFn = ApolloReactCommon.MutationFunction<SmtpSendCheckMutation, SmtpSendCheckMutationVariables>;

/**
 * __useSmtpSendCheckMutation__
 *
 * To run a mutation, you first call `useSmtpSendCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmtpSendCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smtpSendCheckMutation, { data, loading, error }] = useSmtpSendCheckMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      sendTo: // value for 'sendTo'
 *   },
 * });
 */
export function useSmtpSendCheckMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SmtpSendCheckMutation, SmtpSendCheckMutationVariables>) {
        return ApolloReactHooks.useMutation<SmtpSendCheckMutation, SmtpSendCheckMutationVariables>(SmtpSendCheckDocument, baseOptions);
      }
export type SmtpSendCheckMutationHookResult = ReturnType<typeof useSmtpSendCheckMutation>;
export type SmtpSendCheckMutationResult = ApolloReactCommon.MutationResult<SmtpSendCheckMutation>;
export type SmtpSendCheckMutationOptions = ApolloReactCommon.BaseMutationOptions<SmtpSendCheckMutation, SmtpSendCheckMutationVariables>;
export const UnlinkTeamDocument = gql`
    mutation UnlinkTeam($teamId: String!, $userId: String!) {
  UnlinkTeam(teamId: $teamId, userId: $userId) {
    status
  }
}
    `;
export type UnlinkTeamMutationFn = ApolloReactCommon.MutationFunction<UnlinkTeamMutation, UnlinkTeamMutationVariables>;

/**
 * __useUnlinkTeamMutation__
 *
 * To run a mutation, you first call `useUnlinkTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkTeamMutation, { data, loading, error }] = useUnlinkTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnlinkTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnlinkTeamMutation, UnlinkTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<UnlinkTeamMutation, UnlinkTeamMutationVariables>(UnlinkTeamDocument, baseOptions);
      }
export type UnlinkTeamMutationHookResult = ReturnType<typeof useUnlinkTeamMutation>;
export type UnlinkTeamMutationResult = ApolloReactCommon.MutationResult<UnlinkTeamMutation>;
export type UnlinkTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<UnlinkTeamMutation, UnlinkTeamMutationVariables>;
export const UpdateTeamByIdDocument = gql`
    mutation UpdateTeamById($teamId: String!, $team: TeamInput!, $contact: AddressInput!, $defaultProductTaxClassId: String, $defaultShippingTaxClassId: String) {
  UpdateTeamById(teamId: $teamId, team: $team, contact: $contact, defaultProductTaxClassId: $defaultProductTaxClassId, defaultShippingTaxClassId: $defaultShippingTaxClassId) {
    status
    team {
      id
      name
    }
  }
}
    `;
export type UpdateTeamByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamByIdMutation, UpdateTeamByIdMutationVariables>;

/**
 * __useUpdateTeamByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTeamByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamByIdMutation, { data, loading, error }] = useUpdateTeamByIdMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      team: // value for 'team'
 *      contact: // value for 'contact'
 *      defaultProductTaxClassId: // value for 'defaultProductTaxClassId'
 *      defaultShippingTaxClassId: // value for 'defaultShippingTaxClassId'
 *   },
 * });
 */
export function useUpdateTeamByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamByIdMutation, UpdateTeamByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeamByIdMutation, UpdateTeamByIdMutationVariables>(UpdateTeamByIdDocument, baseOptions);
      }
export type UpdateTeamByIdMutationHookResult = ReturnType<typeof useUpdateTeamByIdMutation>;
export type UpdateTeamByIdMutationResult = ApolloReactCommon.MutationResult<UpdateTeamByIdMutation>;
export type UpdateTeamByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamByIdMutation, UpdateTeamByIdMutationVariables>;
export const GetTeamByIdDocument = gql`
    query GetTeamById($teamId: String!) {
  GetTeamById(teamId: $teamId) {
    status
    team {
      id
      name
      logo
      abn
      description
      baseUrl
      addresses {
        address {
          id
          company
          email
          phone
          fax
          mobile
          street
          suburb
          state
          country
          postcode
        }
      }
      settings
      xeroConnected
      tenantName
      xeroAccountCode
      teamChargePlanId
      createdAt
    }
  }
}
    `;

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, baseOptions);
      }
export function useGetTeamByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, baseOptions);
        }
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>;
export type GetTeamByIdLazyQueryHookResult = ReturnType<typeof useGetTeamByIdLazyQuery>;
export type GetTeamByIdQueryResult = ApolloReactCommon.QueryResult<GetTeamByIdQuery, GetTeamByIdQueryVariables>;
export const GetTeamSmtpDocument = gql`
    query GetTeamSMTP($teamId: String!) {
  GetTeamSMTP(teamId: $teamId) {
    status
    teamSMTP {
      id
      host
      username
      password
      port
    }
  }
}
    `;

/**
 * __useGetTeamSmtpQuery__
 *
 * To run a query within a React component, call `useGetTeamSmtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamSmtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamSmtpQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamSmtpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamSmtpQuery, GetTeamSmtpQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamSmtpQuery, GetTeamSmtpQueryVariables>(GetTeamSmtpDocument, baseOptions);
      }
export function useGetTeamSmtpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamSmtpQuery, GetTeamSmtpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamSmtpQuery, GetTeamSmtpQueryVariables>(GetTeamSmtpDocument, baseOptions);
        }
export type GetTeamSmtpQueryHookResult = ReturnType<typeof useGetTeamSmtpQuery>;
export type GetTeamSmtpLazyQueryHookResult = ReturnType<typeof useGetTeamSmtpLazyQuery>;
export type GetTeamSmtpQueryResult = ApolloReactCommon.QueryResult<GetTeamSmtpQuery, GetTeamSmtpQueryVariables>;
export const GetTeamTaxClassesDocument = gql`
    query GetTeamTaxClasses($teamId: String!) {
  GetTeamTaxClasses(teamId: $teamId) {
    taxClasses {
      id
      name
      isForShipping
    }
  }
}
    `;

/**
 * __useGetTeamTaxClassesQuery__
 *
 * To run a query within a React component, call `useGetTeamTaxClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamTaxClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamTaxClassesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamTaxClassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamTaxClassesQuery, GetTeamTaxClassesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamTaxClassesQuery, GetTeamTaxClassesQueryVariables>(GetTeamTaxClassesDocument, baseOptions);
      }
export function useGetTeamTaxClassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamTaxClassesQuery, GetTeamTaxClassesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamTaxClassesQuery, GetTeamTaxClassesQueryVariables>(GetTeamTaxClassesDocument, baseOptions);
        }
export type GetTeamTaxClassesQueryHookResult = ReturnType<typeof useGetTeamTaxClassesQuery>;
export type GetTeamTaxClassesLazyQueryHookResult = ReturnType<typeof useGetTeamTaxClassesLazyQuery>;
export type GetTeamTaxClassesQueryResult = ApolloReactCommon.QueryResult<GetTeamTaxClassesQuery, GetTeamTaxClassesQueryVariables>;
export const GetTeamXeroAccountCodeDocument = gql`
    query GetTeamXeroAccountCode($teamId: String!) {
  GetTeamXeroAccountCode(teamId: $teamId) {
    status
    response
  }
}
    `;

/**
 * __useGetTeamXeroAccountCodeQuery__
 *
 * To run a query within a React component, call `useGetTeamXeroAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamXeroAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamXeroAccountCodeQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamXeroAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamXeroAccountCodeQuery, GetTeamXeroAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamXeroAccountCodeQuery, GetTeamXeroAccountCodeQueryVariables>(GetTeamXeroAccountCodeDocument, baseOptions);
      }
export function useGetTeamXeroAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamXeroAccountCodeQuery, GetTeamXeroAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamXeroAccountCodeQuery, GetTeamXeroAccountCodeQueryVariables>(GetTeamXeroAccountCodeDocument, baseOptions);
        }
export type GetTeamXeroAccountCodeQueryHookResult = ReturnType<typeof useGetTeamXeroAccountCodeQuery>;
export type GetTeamXeroAccountCodeLazyQueryHookResult = ReturnType<typeof useGetTeamXeroAccountCodeLazyQuery>;
export type GetTeamXeroAccountCodeQueryResult = ApolloReactCommon.QueryResult<GetTeamXeroAccountCodeQuery, GetTeamXeroAccountCodeQueryVariables>;
export const GetUsersByTeamDocument = gql`
    query GetUsersByTeam($teamId: String!) {
  GetUsersByTeam(teamId: $teamId) {
    status
    users {
      id
      firstName
      lastName
      activated
      email
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetUsersByTeamQuery__
 *
 * To run a query within a React component, call `useGetUsersByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetUsersByTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>(GetUsersByTeamDocument, baseOptions);
      }
export function useGetUsersByTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>(GetUsersByTeamDocument, baseOptions);
        }
export type GetUsersByTeamQueryHookResult = ReturnType<typeof useGetUsersByTeamQuery>;
export type GetUsersByTeamLazyQueryHookResult = ReturnType<typeof useGetUsersByTeamLazyQuery>;
export type GetUsersByTeamQueryResult = ApolloReactCommon.QueryResult<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>;
export const CreateCourierInquiryDocument = gql`
    mutation CreateCourierInquiry($courierName: String!, $teamID: String!) {
  CreateCourierInquiry(courierName: $courierName, teamID: $teamID) {
    status
    errors {
      message
    }
  }
}
    `;
export type CreateCourierInquiryMutationFn = ApolloReactCommon.MutationFunction<CreateCourierInquiryMutation, CreateCourierInquiryMutationVariables>;

/**
 * __useCreateCourierInquiryMutation__
 *
 * To run a mutation, you first call `useCreateCourierInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourierInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourierInquiryMutation, { data, loading, error }] = useCreateCourierInquiryMutation({
 *   variables: {
 *      courierName: // value for 'courierName'
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useCreateCourierInquiryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourierInquiryMutation, CreateCourierInquiryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCourierInquiryMutation, CreateCourierInquiryMutationVariables>(CreateCourierInquiryDocument, baseOptions);
      }
export type CreateCourierInquiryMutationHookResult = ReturnType<typeof useCreateCourierInquiryMutation>;
export type CreateCourierInquiryMutationResult = ApolloReactCommon.MutationResult<CreateCourierInquiryMutation>;
export type CreateCourierInquiryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourierInquiryMutation, CreateCourierInquiryMutationVariables>;
export const SetTeamCourierDocument = gql`
    mutation SetTeamCourier($teamId: String!, $courierId: String!, $configuration: String!, $active: Boolean!) {
  SetTeamCourier(teamId: $teamId, courierId: $courierId, configuration: $configuration, active: $active) {
    status
    teamCourier {
      id
      teamId
      courierId
      configuration
      active
    }
  }
}
    `;
export type SetTeamCourierMutationFn = ApolloReactCommon.MutationFunction<SetTeamCourierMutation, SetTeamCourierMutationVariables>;

/**
 * __useSetTeamCourierMutation__
 *
 * To run a mutation, you first call `useSetTeamCourierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTeamCourierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTeamCourierMutation, { data, loading, error }] = useSetTeamCourierMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      courierId: // value for 'courierId'
 *      configuration: // value for 'configuration'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useSetTeamCourierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTeamCourierMutation, SetTeamCourierMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTeamCourierMutation, SetTeamCourierMutationVariables>(SetTeamCourierDocument, baseOptions);
      }
export type SetTeamCourierMutationHookResult = ReturnType<typeof useSetTeamCourierMutation>;
export type SetTeamCourierMutationResult = ApolloReactCommon.MutationResult<SetTeamCourierMutation>;
export type SetTeamCourierMutationOptions = ApolloReactCommon.BaseMutationOptions<SetTeamCourierMutation, SetTeamCourierMutationVariables>;
export const GetTeamCourierDocument = gql`
    query GetTeamCourier($teamId: String!, $courierId: String!) {
  GetTeamCourier(teamId: $teamId, courierId: $courierId) {
    status
    teamCourier {
      id
      teamId
      courierId
      configuration
      active
      createdById
      updatedById
      createdAt
      updatedAt
      info
    }
  }
}
    `;

/**
 * __useGetTeamCourierQuery__
 *
 * To run a query within a React component, call `useGetTeamCourierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamCourierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamCourierQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      courierId: // value for 'courierId'
 *   },
 * });
 */
export function useGetTeamCourierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamCourierQuery, GetTeamCourierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamCourierQuery, GetTeamCourierQueryVariables>(GetTeamCourierDocument, baseOptions);
      }
export function useGetTeamCourierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamCourierQuery, GetTeamCourierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamCourierQuery, GetTeamCourierQueryVariables>(GetTeamCourierDocument, baseOptions);
        }
export type GetTeamCourierQueryHookResult = ReturnType<typeof useGetTeamCourierQuery>;
export type GetTeamCourierLazyQueryHookResult = ReturnType<typeof useGetTeamCourierLazyQuery>;
export type GetTeamCourierQueryResult = ApolloReactCommon.QueryResult<GetTeamCourierQuery, GetTeamCourierQueryVariables>;
export const GetTeamCouriersDocument = gql`
    query GetTeamCouriers($teamId: String!) {
  GetTeamCouriers(teamId: $teamId) {
    status
    teamCouriers {
      id
      teamId
      courierId
      configuration
      active
      createdAt
      updatedAt
      info
    }
  }
}
    `;

/**
 * __useGetTeamCouriersQuery__
 *
 * To run a query within a React component, call `useGetTeamCouriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamCouriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamCouriersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamCouriersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamCouriersQuery, GetTeamCouriersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamCouriersQuery, GetTeamCouriersQueryVariables>(GetTeamCouriersDocument, baseOptions);
      }
export function useGetTeamCouriersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamCouriersQuery, GetTeamCouriersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamCouriersQuery, GetTeamCouriersQueryVariables>(GetTeamCouriersDocument, baseOptions);
        }
export type GetTeamCouriersQueryHookResult = ReturnType<typeof useGetTeamCouriersQuery>;
export type GetTeamCouriersLazyQueryHookResult = ReturnType<typeof useGetTeamCouriersLazyQuery>;
export type GetTeamCouriersQueryResult = ApolloReactCommon.QueryResult<GetTeamCouriersQuery, GetTeamCouriersQueryVariables>;
export const GetTeamCouriersAndSystemsCouriersDocument = gql`
    query getTeamCouriersAndSystemsCouriers($teamId: String!) {
  GetCouriers {
    status
    couriers {
      id
      name
      code
      active
    }
  }
  GetTeamCouriers(teamId: $teamId) {
    status
    teamCouriers {
      id
      teamId
      courierId
      configuration
      active
      createdAt
      updatedAt
      info
    }
  }
}
    `;

/**
 * __useGetTeamCouriersAndSystemsCouriersQuery__
 *
 * To run a query within a React component, call `useGetTeamCouriersAndSystemsCouriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamCouriersAndSystemsCouriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamCouriersAndSystemsCouriersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamCouriersAndSystemsCouriersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamCouriersAndSystemsCouriersQuery, GetTeamCouriersAndSystemsCouriersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamCouriersAndSystemsCouriersQuery, GetTeamCouriersAndSystemsCouriersQueryVariables>(GetTeamCouriersAndSystemsCouriersDocument, baseOptions);
      }
export function useGetTeamCouriersAndSystemsCouriersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamCouriersAndSystemsCouriersQuery, GetTeamCouriersAndSystemsCouriersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamCouriersAndSystemsCouriersQuery, GetTeamCouriersAndSystemsCouriersQueryVariables>(GetTeamCouriersAndSystemsCouriersDocument, baseOptions);
        }
export type GetTeamCouriersAndSystemsCouriersQueryHookResult = ReturnType<typeof useGetTeamCouriersAndSystemsCouriersQuery>;
export type GetTeamCouriersAndSystemsCouriersLazyQueryHookResult = ReturnType<typeof useGetTeamCouriersAndSystemsCouriersLazyQuery>;
export type GetTeamCouriersAndSystemsCouriersQueryResult = ApolloReactCommon.QueryResult<GetTeamCouriersAndSystemsCouriersQuery, GetTeamCouriersAndSystemsCouriersQueryVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($teamID: String!, $action: String!, $ticketId: String!, $body: String!, $authorId: String!) {
  createComment(teamID: $teamID, action: $action, ticketId: $ticketId, body: $body, authorId: $authorId) {
    status
  }
}
    `;
export type CreateCommentMutationFn = ApolloReactCommon.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      action: // value for 'action'
 *      ticketId: // value for 'ticketId'
 *      body: // value for 'body'
 *      authorId: // value for 'authorId'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, baseOptions);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = ApolloReactCommon.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateTicketDocument = gql`
    mutation updateTicket($teamID: String!, $ticketId: String!, $priority: String, $channel: String) {
  updateTicket(teamID: $teamID, ticketId: $ticketId, priority: $priority, channel: $channel) {
    status
  }
}
    `;
export type UpdateTicketMutationFn = ApolloReactCommon.MutationFunction<UpdateTicketMutation, UpdateTicketMutationVariables>;

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketMutation, { data, loading, error }] = useUpdateTicketMutation({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      ticketId: // value for 'ticketId'
 *      priority: // value for 'priority'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useUpdateTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTicketMutation, UpdateTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTicketMutation, UpdateTicketMutationVariables>(UpdateTicketDocument, baseOptions);
      }
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>;
export type UpdateTicketMutationResult = ApolloReactCommon.MutationResult<UpdateTicketMutation>;
export type UpdateTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTicketMutation, UpdateTicketMutationVariables>;
export const GetTicketCommentsDocument = gql`
    query getTicketComments($teamID: String!, $ticketId: String!) {
  getTicketComments(teamID: $teamID, ticketId: $ticketId) {
    status
    requester
    assignee
    assigneeId
    comments {
      id
      author
      htmlBody
      created_at
      attachments {
        id
        file_name
        content_url
      }
    }
  }
}
    `;

/**
 * __useGetTicketCommentsQuery__
 *
 * To run a query within a React component, call `useGetTicketCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketCommentsQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTicketCommentsQuery, GetTicketCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTicketCommentsQuery, GetTicketCommentsQueryVariables>(GetTicketCommentsDocument, baseOptions);
      }
export function useGetTicketCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTicketCommentsQuery, GetTicketCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTicketCommentsQuery, GetTicketCommentsQueryVariables>(GetTicketCommentsDocument, baseOptions);
        }
export type GetTicketCommentsQueryHookResult = ReturnType<typeof useGetTicketCommentsQuery>;
export type GetTicketCommentsLazyQueryHookResult = ReturnType<typeof useGetTicketCommentsLazyQuery>;
export type GetTicketCommentsQueryResult = ApolloReactCommon.QueryResult<GetTicketCommentsQuery, GetTicketCommentsQueryVariables>;
export const TicketsByTeamDocument = gql`
    query TicketsByTeam($teamID: String!, $searchTxt: String, $sortKey: String!, $sortOrder: String!, $pageSize: Int!, $pageNum: Int!, $status: String) {
  TicketsByTeam(teamID: $teamID, searchTxt: $searchTxt, sortKey: $sortKey, sortOrder: $sortOrder, pageSize: $pageSize, pageNum: $pageNum, status: $status) {
    status
    tickets {
      id
      subject
      status
      itemId
      orderId
      channel
      description
      requester
      created_at
      updated_at
      priority
      image
    }
    total
  }
}
    `;

/**
 * __useTicketsByTeamQuery__
 *
 * To run a query within a React component, call `useTicketsByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsByTeamQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      searchTxt: // value for 'searchTxt'
 *      sortKey: // value for 'sortKey'
 *      sortOrder: // value for 'sortOrder'
 *      pageSize: // value for 'pageSize'
 *      pageNum: // value for 'pageNum'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTicketsByTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TicketsByTeamQuery, TicketsByTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<TicketsByTeamQuery, TicketsByTeamQueryVariables>(TicketsByTeamDocument, baseOptions);
      }
export function useTicketsByTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketsByTeamQuery, TicketsByTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TicketsByTeamQuery, TicketsByTeamQueryVariables>(TicketsByTeamDocument, baseOptions);
        }
export type TicketsByTeamQueryHookResult = ReturnType<typeof useTicketsByTeamQuery>;
export type TicketsByTeamLazyQueryHookResult = ReturnType<typeof useTicketsByTeamLazyQuery>;
export type TicketsByTeamQueryResult = ApolloReactCommon.QueryResult<TicketsByTeamQuery, TicketsByTeamQueryVariables>;
export const ActivateInvitedUserDocument = gql`
    mutation ActivateInvitedUser($userId: String!, $token: String!, $firstName: String!, $lastName: String!, $password: String!) {
  ActivateInvitedUser(userId: $userId, token: $token, firstName: $firstName, lastName: $lastName, password: $password) {
    user {
      id
      firstName
      lastName
      activated
      email
      teams {
        id
        team {
          id
          name
          teamChannels(where: {active: {equals: true}}) {
            id
            name
            active
            externalId
            onBoarded
            settings
            channel {
              id
              name
            }
            createdAt
            updatedAt
            createdBy {
              firstName
              lastName
            }
            updatedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
    status
    errors {
      message
    }
  }
}
    `;
export type ActivateInvitedUserMutationFn = ApolloReactCommon.MutationFunction<ActivateInvitedUserMutation, ActivateInvitedUserMutationVariables>;

/**
 * __useActivateInvitedUserMutation__
 *
 * To run a mutation, you first call `useActivateInvitedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateInvitedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateInvitedUserMutation, { data, loading, error }] = useActivateInvitedUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useActivateInvitedUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateInvitedUserMutation, ActivateInvitedUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateInvitedUserMutation, ActivateInvitedUserMutationVariables>(ActivateInvitedUserDocument, baseOptions);
      }
export type ActivateInvitedUserMutationHookResult = ReturnType<typeof useActivateInvitedUserMutation>;
export type ActivateInvitedUserMutationResult = ApolloReactCommon.MutationResult<ActivateInvitedUserMutation>;
export type ActivateInvitedUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateInvitedUserMutation, ActivateInvitedUserMutationVariables>;
export const ActivateUserDocument = gql`
    mutation ActivateUser($userId: String!, $token: String!, $password: String!) {
  ActivateUser(userId: $userId, token: $token, password: $password) {
    status
    user {
      id
      firstName
      lastName
      activated
      email
      teams {
        id
        team {
          id
          name
          teamChannels(where: {active: {equals: true}}) {
            id
            active
            name
            externalId
            onBoarded
            settings
            channel {
              id
              name
            }
            createdAt
            updatedAt
            createdBy {
              firstName
              lastName
            }
            updatedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
  ChangePassword(newPassword: $newPassword, currentPassword: $currentPassword) {
    status
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($email: String!, $teamID: String!) {
  InviteUser(email: $email, teamID: $teamID) {
    user {
      id
      firstName
      lastName
      email
      activated
    }
    status
    errors {
      message
    }
  }
}
    `;
export type InviteUserMutationFn = ApolloReactCommon.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = ApolloReactCommon.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  Login(email: $email, password: $password) {
    user {
      id
      firstName
      lastName
      activated
      email
      language
      teams {
        id
        team {
          id
          name
          settings
          teamChannels(where: {active: {equals: true}}) {
            id
            active
            name
            externalId
            onBoarded
            settings
            channel {
              id
              name
            }
            createdAt
            updatedAt
            createdBy {
              firstName
              lastName
            }
            updatedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
    status
    errors {
      message
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  RequestPasswordReset(email: $email) {
    status
    email
  }
}
    `;
export type RequestPasswordResetMutationFn = ApolloReactCommon.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, baseOptions);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResendSignupActivationDocument = gql`
    mutation ResendSignupActivation($userEmail: String!, $resendEmail: String!) {
  ResendSignupActivation(userEmail: $userEmail, resendEmail: $resendEmail) {
    user {
      id
      firstName
      lastName
      email
      activated
    }
    token
    status
    errors {
      message
    }
  }
}
    `;
export type ResendSignupActivationMutationFn = ApolloReactCommon.MutationFunction<ResendSignupActivationMutation, ResendSignupActivationMutationVariables>;

/**
 * __useResendSignupActivationMutation__
 *
 * To run a mutation, you first call `useResendSignupActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSignupActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSignupActivationMutation, { data, loading, error }] = useResendSignupActivationMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      resendEmail: // value for 'resendEmail'
 *   },
 * });
 */
export function useResendSignupActivationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendSignupActivationMutation, ResendSignupActivationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendSignupActivationMutation, ResendSignupActivationMutationVariables>(ResendSignupActivationDocument, baseOptions);
      }
export type ResendSignupActivationMutationHookResult = ReturnType<typeof useResendSignupActivationMutation>;
export type ResendSignupActivationMutationResult = ApolloReactCommon.MutationResult<ResendSignupActivationMutation>;
export type ResendSignupActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendSignupActivationMutation, ResendSignupActivationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($userId: String!, $token: String!, $password: String!) {
  ResetPassword(userId: $userId, token: $token, password: $password) {
    status
    user {
      id
      firstName
      lastName
      activated
      email
      teams {
        id
        team {
          id
          name
          teamChannels(where: {active: {equals: true}}) {
            id
            name
            active
            externalId
            onBoarded
            settings
            channel {
              id
              name
            }
            createdAt
            updatedAt
            createdBy {
              firstName
              lastName
            }
            updatedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
  Logout {
    status
    errors {
      message
    }
  }
}
    `;
export type SignOutMutationFn = ApolloReactCommon.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        return ApolloReactHooks.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, baseOptions);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = ApolloReactCommon.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = ApolloReactCommon.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($firstName: String!, $lastName: String!, $email: String!, $teamName: String!) {
  SignUp(firstName: $firstName, lastName: $lastName, email: $email, teamName: $teamName) {
    user {
      id
      firstName
      lastName
      email
      activated
      teams {
        team {
          id
        }
      }
    }
    status
    errors {
      message
    }
  }
}
    `;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($firstName: String, $lastName: String, $language: String) {
  UpdateUser(firstName: $firstName, lastName: $lastName, language: $language) {
    status
    user {
      id
      firstName
      lastName
      language
    }
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const XeroLoginDocument = gql`
    mutation XeroLogin($accessToken: String!) {
  XeroLogin(accessToken: $accessToken) {
    user {
      id
      firstName
      lastName
      activated
      email
      teams {
        id
        team {
          id
          name
          teamChannels(where: {active: {equals: true}}) {
            id
            active
            name
            externalId
            onBoarded
            settings
            watermarkUrl
            channel {
              id
              name
            }
            createdAt
            updatedAt
            createdBy {
              firstName
              lastName
            }
            updatedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
    status
    errors {
      message
    }
  }
}
    `;
export type XeroLoginMutationFn = ApolloReactCommon.MutationFunction<XeroLoginMutation, XeroLoginMutationVariables>;

/**
 * __useXeroLoginMutation__
 *
 * To run a mutation, you first call `useXeroLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useXeroLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [xeroLoginMutation, { data, loading, error }] = useXeroLoginMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useXeroLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<XeroLoginMutation, XeroLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<XeroLoginMutation, XeroLoginMutationVariables>(XeroLoginDocument, baseOptions);
      }
export type XeroLoginMutationHookResult = ReturnType<typeof useXeroLoginMutation>;
export type XeroLoginMutationResult = ApolloReactCommon.MutationResult<XeroLoginMutation>;
export type XeroLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<XeroLoginMutation, XeroLoginMutationVariables>;
export const GetUserByTokenDocument = gql`
    query GetUserByToken($userId: String!, $token: String!) {
  GetUserByToken(userId: $userId, token: $token) {
    status
    user {
      id
      email
      firstName
      lastName
      activated
      teams {
        id
        team {
          id
          name
          teamChannels {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserByTokenQuery__
 *
 * To run a query within a React component, call `useGetUserByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByTokenQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetUserByTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByTokenQuery, GetUserByTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserByTokenQuery, GetUserByTokenQueryVariables>(GetUserByTokenDocument, baseOptions);
      }
export function useGetUserByTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserByTokenQuery, GetUserByTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserByTokenQuery, GetUserByTokenQueryVariables>(GetUserByTokenDocument, baseOptions);
        }
export type GetUserByTokenQueryHookResult = ReturnType<typeof useGetUserByTokenQuery>;
export type GetUserByTokenLazyQueryHookResult = ReturnType<typeof useGetUserByTokenLazyQuery>;
export type GetUserByTokenQueryResult = ApolloReactCommon.QueryResult<GetUserByTokenQuery, GetUserByTokenQueryVariables>;
export const MeDocument = gql`
    query Me {
  Me {
    firstName
    lastName
    email
    teams {
      team {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const ValidatePasswordResetLinkDocument = gql`
    query ValidatePasswordResetLink($userId: String!, $token: String!) {
  ValidatePasswordResetLink(userId: $userId, token: $token) {
    status
  }
}
    `;

/**
 * __useValidatePasswordResetLinkQuery__
 *
 * To run a query within a React component, call `useValidatePasswordResetLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePasswordResetLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePasswordResetLinkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidatePasswordResetLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidatePasswordResetLinkQuery, ValidatePasswordResetLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidatePasswordResetLinkQuery, ValidatePasswordResetLinkQueryVariables>(ValidatePasswordResetLinkDocument, baseOptions);
      }
export function useValidatePasswordResetLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidatePasswordResetLinkQuery, ValidatePasswordResetLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidatePasswordResetLinkQuery, ValidatePasswordResetLinkQueryVariables>(ValidatePasswordResetLinkDocument, baseOptions);
        }
export type ValidatePasswordResetLinkQueryHookResult = ReturnType<typeof useValidatePasswordResetLinkQuery>;
export type ValidatePasswordResetLinkLazyQueryHookResult = ReturnType<typeof useValidatePasswordResetLinkLazyQuery>;
export type ValidatePasswordResetLinkQueryResult = ApolloReactCommon.QueryResult<ValidatePasswordResetLinkQuery, ValidatePasswordResetLinkQueryVariables>;